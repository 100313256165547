<app-progress-bar [value]="working" [splashScreen]="true"></app-progress-bar>

<div class="analytics-wrapper" *ngIf="section">
    <form [formGroup]="sectionForm">

        <div class="form edit-section"
        data-intro="Add your involvement in clubs and organizations to highlight your extracurricular activities."
        data-title="Clubs and Organizations"
        data-step="1">
            <div class="six-col row">
                <label for="org" class="form-label text text-black">
                    Name of Club or Organization
                </label>
                <input name="org" type="text" formControlName="organizationName"
                    class="form-input border border-teal text form-input-100" />
            </div>

            <div class="three-col row">
                <label for="org" class="form-label text text-black">
                    Role
                </label>
                <input name="org" type="text" formControlName="role"
                    class="form-input border border-teal text form-input-100" />
            </div>

            <div class="three-col row">
                <label for="entity" class="form-label text text-black">
                    University/College/Entity
                </label>
                <input name="entity" type="text" formControlName="schoolName"
                    class="form-input border border-teal text form-input-100" />
            </div>

            <div class="two-col row">
                <label for="startDate" class="form-label text text-black">
                    Start Date
                </label>
                <input name="startDate" type="date" formControlName="startDate"
                    (change)="endDate.updateValueAndValidity()"
                    class="form-input border border-teal text form-input-100" />
            </div>

            <div class="two-col row">
                <div class="flex-columns">
                    <label for="endDate" class="form-label text text-black">
                        End Date
                    </label>
                </div>
                <input name="endDate" type="date" formControlName="endDate"
                    class="form-input border border-teal text form-input-100 hide-disabled" />
                <label class="form-error"
                    *ngIf="sectionForm.touched && sectionForm.controls['endDate'].errors?.required">
                    End Date is required
                </label>
                <label class="form-error"
                    *ngIf="sectionForm.touched && sectionForm.controls['endDate'].errors?.invalidDate">
                    End Date is invalid
                </label>
                <label class="form-error"
                    *ngIf="sectionForm.touched && sectionForm.controls['endDate'].errors?.afterDate">
                    Start Date must be before End Date
                </label>
            </div>

            <div class="two-col row">
                <label for="current" class="form-label text text-black">
                    Currently Here
                </label>
                <input id="current" (click)="toggleCurrent()" type="checkbox" name="current"
                style="height: auto;" formControlName="current"
                class="form-input border border-teal text" />
            </div>
        </div>

        <div class="form edit-section">

            <div class="six-col">
                <label class="form-label text text-black">
                    Description
                </label>
                <div style="height: 350px;">
                    <app-custom-quill [initialValue]="currentResumeSection?.description ?? section?.defaultDescription ?? ''"
                        placeholder="Enter description here..." (changed)="descriptionChange($event)"
                        (filled)="markDescriptionPristine()"
                        [checkWeakWords]="smartwords.checkAnalytic(section.analyze, SmartWordAnalyzer.weakWords)"
                        [checkStrongWords]="smartwords.checkAnalytic(section.analyze, SmartWordAnalyzer.strongWords)"
                        [checkActionWords]="smartwords.checkAnalytic(section.analyze, SmartWordAnalyzer.actionWords)"
                        [checkMetrics]="smartwords.checkAnalytic(section.analyze, SmartWordAnalyzer.metrics)"
                        [checkTense]="smartwords.checkAnalytic(section.analyze, SmartWordAnalyzer.tense)"
                        [presentTense]="current" (wordListEmitter)="updateWordList($event)" [words]="words">
                    </app-custom-quill>
                </div>
            </div>
        </div>

        <div class="form edit-section mt-large">
            <div class="three-col">
                <div class="mr-auto mb-small" *ngIf="id?.value">
                    <button type="button" (click)="delete()">
                        Delete
                    </button>
                </div>
                <label class="form-warning" *ngIf="sectionForm.touched && sectionForm.controls['organizationName'].errors?.required">
                    Club/Org is required
                </label>
                <label class="form-warning" *ngIf="sectionForm.touched && sectionForm.controls['schoolName'].errors?.required">
                    Entity is required
                </label>
                <label class="form-error" *ngIf="sectionForm.touched && sectionForm.get('startDate').hasError('required')">
                    Start Date is required
                </label>
                <label class="form-error" *ngIf="sectionForm.touched && sectionForm.get('startDate').hasError('invalidDate')">
                    Start Date is invalid
                </label>
                <label class="form-warning" *ngIf="sectionForm.touched && description?.errors?.required ?? false">
                    Description is required
                </label>
                <label class="form-error" *ngIf="sectionForm.touched && description?.errors?.minlength ?? false">
                    Description is not long enough ({{ description?.errors?.minlength?.actualLength }} of {{
                    description?.errors?.minlength?.requiredLength }})
                </label>
                <label class="form-error" *ngIf="sectionForm.touched && description?.errors?.maxlength ?? false">
                    Description is too long ({{ description?.errors?.maxlength?.actualLength }} of {{description?.errors?.maxlength?.requiredLength }})
                </label>

            </div>
            <div class="three-col row">
                <div class="ml-auto">
                    <button type="submit" (click)="cancel()" [disabled]="!hasUnsavedChanges">
                        Cancel
                    </button>    
                </div>
                <div>
                    <button type="submit" class="button lime-green" (click)="save()" [disabled]="!sectionForm?.valid"
                    data-intro="Remember to save the section you completed. If you forget, our system will remind you."
                    data-title="Save Section"
                    data-step="10">
                        Save Section
                    </button>    
                </div>
            </div>
        </div>

    </form>
    <app-analytics [section]="section" [analytics]="analytics"></app-analytics>
</div>