export const QuillConfiguration = {
    toolbar: [
      // ['bold', 'italic', 'underline', 'strike'],
      // ['bold', 'italic'],
      ['bold'],
      // ['blockquote'],
      // [{ list: 'ordered' }, { list: 'bullet' }],
      [{ list: 'bullet' }],
      // [{ header: [1, 2, 3, 4, 5, 6, false] }],
      // [{ color: [] }, { background: [] }],
      ['clean'],
    ],
  };
  export const ReadonlyQuillConfiguration = {
    toolbar: [
    ],
  };
