<app-progress-bar [splashScreen]="true" [value]="working"></app-progress-bar>
<div class="bg-cream stepper-wrapper">
    <div class="bg-teal flex-rows pl-medium pr-medium py-large">
        <div class="text text-title text-lime mb-large">
            Edit Opportunity
        </div>
        <app-stepper #stepper [steps]="steps" [(activeStep)]="activeStep"></app-stepper>
    </div>


    <div class="my-school flex-rows main-pane">
        <form *ngIf="formGroup" class="form pt-large px-medium flex-rows" [ngClass]="{ 'mb-medium': working }"
            (ngSubmit)="onSave()" [formGroup]="formGroup">
            <div class="flex-columns justify-between">
                <div *ngIf="opportunity.id < 1" class="text text-title mb-large mr-auto">
                    Create a new Opportunity
                </div>
                <div *ngIf="opportunity.id > 0" class="text text-title mb-large mr-auto">
                    Edit {{opportunity?.title}}
                </div>
                <a *ngIf="opportunity && steps[activeStep]?.id !== 'overview'"
                    [routerLink]="['/employers', employer?.urlName ]"
                    class="btn border border-medium border-teal lime-green mb-small">View Company Profile
                </a>
            </div>
            <div data-title="Main Information" data-step="1" class="stepper-content mb-auto"
                [ngClass]="{ 'stepper-content-split': steps[activeStep].split }">
                <ng-container *ngIf="steps[activeStep].id === 'info'">
                    <div class="flex-rows mb-small">
                        <label for="SchoolName" class="form-label text text-black text-rounded-mplus text-800">
                            Audience/Opportunity Name
                        </label>
                        <input placeholder="e.g. Full-Time Technician" name="Title" type="text" formControlName="Title"
                            required class="form-input border border-teal text" />
                    </div>
                    <div class="flex-rows mb-small">
                        <label for="Type" class="form-label">
                            Opportunity Type
                        </label>
                        <ng-select formControlName="OpportunityType" class="drop-down drop-down-button-blue"
                            placeholder="Select Opportunity Type" [items]="opportunityTypeList">
                        </ng-select>
                    </div>
                    <div class="flex-rows mb-small">
                        <label for="Contact" class="form-label">
                            Contact Email
                        </label>
                        <input placeholder="e.g. Contact Name/Email/Phone" type="text" formControlName="Contact"
                            class="form-input border border-teal text" />
                    </div>
                    <div class="flex-rows mb-small">
                        <label for="ManagerId" class="form-label">
                            Opportunity Supervisor
                        </label>
                        <ng-select formControlName="ManagerId" class="drop-down drop-down-button-blue"
                            placeholder="Select a supervisor" [items]="employees | sort:'label':'asc'">
                        </ng-select>
                    </div>
                    <div *ngIf="availableLocations.length" class="flex-rows mb-small">
                        <label for="StreetAddress" class="form-label">
                            Street Address
                        </label>
                        <input placeholder="e.g. 100 Main St" type="text" formControlName="StreetAddress"
                            class="form-input border border-teal text" />
                    </div>
                    <div *ngIf="availableLocations.length" class="flex-rows mb-small">
                        <label for="EmployerLocationId" class="form-label">
                            City
                        </label>
                        <ng-select formControlName="EmployerLocationId" class="drop-down drop-down-button-blue"
                            placeholder="Select a city" [items]="availableLocations | sort:'label':'asc'">
                        </ng-select>
                    </div>
                    <div class="flex-rows">
                        <label for="Description" class="form-label">
                            Description
                        </label>
                        <quill-editor name="Description" formControlName="Description" [modules]="quillConfiguration"
                            [sanitize]="true">
                        </quill-editor>
                    </div>
                    <div class="flex-rows mb-small">
                        <label for="Domain" class="form-label">
                            External Application Link
                        </label>
                        <input placeholder="e.g. https://www.unext.online" type="text" formControlName="ApplicationUrl"
                            class="form-input border border-teal text" />
                    </div>
                </ng-container>
                <ng-container *ngIf="steps[activeStep].id === 'demography'">
                    <div>
                        <div class="flex-rows">
                            <label for="School" class="form-label">
                                Schools
                            </label>
                            <div>
                                <app-opportunity-add-attribute useSelect="true" [opportunity]="opportunity"
                                    [availableValues]="availableSchools" attribute="school" [showOnFocus]="true"
                                    placeholder="Search for Schools" buttonColor="green" (added)="selectSchool($event)">
                                </app-opportunity-add-attribute>
                            </div>
                            <div class="flex-columns flex-wrap" *ngIf="schools?.value?.length">
                                <span class="chip chip-remove chip-green-border chip-sm mb-small mr-small"
                                    *ngFor="let s of schools?.value" (click)="removeSelection(s, schools, 'school')">
                                    {{ s }}
                                </span>
                            </div>
                        </div>

                        <div class="flex-columns grid-halves">
                            <div class="flex-rows">
                                <label for="Major" class="form-label">
                                    Major
                                </label>
                                <!-- <ng-select formControlName="Major" [items]="opportunityTypeList"
                            class="drop-down drop-down-button-blue"></ng-select> -->
                                <div>
                                    <app-opportunity-add-attribute useSelect="true" [opportunity]="opportunity"
                                        [availableValues]="availableMajors" attribute="major"
                                        placeholder="Search for majors" buttonColor="green"
                                        (added)="selectMajor($event)">
                                    </app-opportunity-add-attribute>
                                </div>
                                <div class="flex-columns flex-wrap" *ngIf="majors?.value?.length">
                                    <span class="chip chip-remove chip-green-border chip-sm mb-small mr-small"
                                        *ngFor="let m of majors?.value" (click)="removeSelection(m, majors, 'major')">
                                        {{ m }}
                                    </span>
                                </div>
                            </div>
                            <div class="flex-rows">
                                <label for="Minor" class="form-label">
                                    Minor
                                </label>
                                <!-- <ng-select formControlName="Minor" [items]="opportunityTypeList"
                            class="drop-down drop-down-button-blue"></ng-select> -->
                                <div>
                                    <app-opportunity-add-attribute useSelect="true" [opportunity]="opportunity"
                                        [availableValues]="availableMinors" attribute="minor"
                                        placeholder="Search for minors" buttonColor="green"
                                        (added)="selectMinor($event)">
                                    </app-opportunity-add-attribute>
                                </div>
                                <div class="flex-columns flex-wrap" *ngIf="minors?.value?.length">
                                    <span class="chip chip-remove chip-green-border chip-sm mb-small mr-small"
                                        *ngFor="let m of minors?.value" (click)="removeSelection(m, minors, 'minor')">
                                        {{ m }}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="flex-columns grid-halves  ">

                            <div class="flex-rows">
                                <label for="FieldOfInterest" class="form-label">
                                    Field Of Interest
                                </label>
                                <div>
                                    <app-opportunity-add-attribute useSelect="true" [opportunity]="opportunity"
                                        [availableValues]="availableInterests" attribute="fieldofinterest"
                                        placeholder="Search for Interests" buttonColor="green"
                                        (added)="selectInterest($event)">
                                    </app-opportunity-add-attribute>
                                </div>

                                <div class="flex-columns flex-wrap" *ngIf="interests?.value?.length">
                                    <span class="chip chip-remove chip-green-border chip-sm mb-small mr-small"
                                        *ngFor="let i of interests?.value"
                                        (click)="removeSelection(i?.name ?? i, interests, 'fieldofinterest')">
                                        {{ i?.name ?? i }}
                                    </span>
                                </div>
                            </div>
                            <div class="flex-rows">
                                <label for="Skills" class="form-label">
                                    Skills
                                </label>

                                <div>
                                    <app-opportunity-add-attribute useSelect="true" [opportunity]="opportunity"
                                        [availableValues]="availableSkills" attribute="skill"
                                        placeholder="Search for skills" buttonColor="green"
                                        (added)="selectSkill($event)">
                                    </app-opportunity-add-attribute>
                                </div>

                                <div class="flex-columns flex-wrap" *ngIf="skills.value?.length">
                                    <span class="chip chip-remove chip-green-border chip-sm mb-small mr-small"
                                        *ngFor="let s of skills.value" (click)="removeSelection(s, skills, 'skill')">
                                        {{ s }}
                                    </span>
                                </div>
                            </div>
                        </div>

                    </div>


                </ng-container>
                <ng-container *ngIf="steps[activeStep].id === 'schedule'">
                    <div class="mb-medium flex-rows">
                        <label for="PublishStartDate" class="form-label">
                            Publish Start Date
                        </label>
                        <input id="PublishStartDate" type="date"
                            class="form-input teal border border-teal text search text box"
                            formControlName="PublishStartDate" />
                    </div>
                    <div class="mb-medium flex-rows">
                        <label for="ScheduleTime" class="form-label">
                            Weekly Schedule
                        </label>
                        <input id="ScheduleTime" type="text"
                            [placeholder]="'Suggested Times: ' + (opportunity?.bestStudentAvailability ?? 'Mondays 8am - 3pm')"
                            class="form-input teal border border-teal text search text box"
                            formControlName="ScheduleTime" />
                    </div>
                    <div class="mb-medium flex-rows">
                        <label for="PublishEndDate" class="form-label">
                            Publish End Date
                        </label>
                        <input id="PublishEndDate" type="date"
                            class="form-input teal border border-teal text search text box"
                            formControlName="PublishEndDate" />
                    </div>
                    <div class="mb-medium flex-rows">
                        <label for="StartDate" class="form-label">
                            Start Date
                        </label>
                        <input id="StartDate" type="date"
                            class="form-input teal border border-teal text search text box"
                            formControlName="StartDate" />
                    </div>

                    <!-- <div class="flex-rows mb-medium">
                        <label for="Active" class="form-label">
                            Archive this opportunity?
                        </label>
                        <label class="form-label">
                            (Will be hidden from students and employer)
                        </label>
                        <app-toggle-bar [isOn]="!active.value" (toggled)="toggleActive()"
                            size="medium"></app-toggle-bar>
                    </div> -->
                    <div></div>
                    <div class="flex-rows mb-medium">
                        <label for="IsPrivate" class="form-label">
                            Schedule?
                        </label>
                        <label class="form-label">
                            ("On" will send invites to students when they apply.)
                        </label>
                        <app-toggle-bar [isOn]="scheduled.value" (toggled)="toggleScheduled()"
                            size="medium"></app-toggle-bar>
                        <label *ngIf="!approvedList.length">Warning: There haven't been any students accepted for this
                            opportunity yet. You should wait for applicants before scheduling.</label>
                    </div>


                    <div *ngIf="approvedList.length">
                        <label>Invites will get sent to the following students that haver already been accepted for this
                            opportunity.</label>
                        <app-list-scroll>
                            <ng-template ngFor let-o [ngForOf]="approvedList">
                                <app-employer-prospect-card class="mr-small mb-small" [prospect]="o">
                                </app-employer-prospect-card>

                            </ng-template>
                        </app-list-scroll>
                    </div>

                </ng-container>
            </div>
        </form>



        <ng-container *ngIf="steps[activeStep].id === 'overview'">
            <app-employer-opportunity [opportunity]="opportunity"></app-employer-opportunity>
        </ng-container>

        <!-- <div class="pt-large pb-medium px-medium stepper-actions-split"> -->
        <div class="bottom-navbar bg-lime row p-1 gap-1 flex-columns justify-between">
            <div class="ml-large">
                <div class="flex-columns">

                    <button type="button" class="text text-label btn white border border-black text"
                        [disabled]="activeStep === 0" (click)="stepper.previous()">
                        Back
                    </button>

                    <button type="button" class="text text-label btn white border border-black text"
                        *ngIf="opportunity.id > 0" (click)="archive()">
                        Archive
                    </button>
                </div>

            </div>
            <div class="mr-large">
                <button type="button" *ngIf="activeStep < steps.length - 1"
                    class="text text-label btn white border border-teal" [disabled]="!currentStepValid()"
                    (click)="steps[activeStep].next()">
                    Next
                </button>

                <button type="button" *ngIf="activeStep === steps.length - 1"
                    class="text text-label btn grey border border-teal " [disabled]="!currentStepValid()"
                    (click)="steps[activeStep].next()">
                    Publish
                </button>
            </div>
        </div>
        <div class="clear"></div>
    </div>
</div>

<app-modal id="uploadImage">
    <app-upload acceptType="image" [entityType]="entityType.EmployerMiniImage" [entityId]="opportunity?.id"
        (filesUploaded)="imageUploaded($event)" (filesSelected)="imageSelected($event)">
    </app-upload>
</app-modal>
<app-modal id="uploadBanner">
    <app-upload acceptType="image" [entityType]="entityType.SchoolBanner" [entityId]="opportunity?.id"
        (filesUploaded)="bannerUploaded($event)" (filesSelected)="bannerSelected($event)">>
    </app-upload>
</app-modal>