import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GenericComponent } from 'src/app/shared/components/generic-component/generic.component';
import { Employer, School, Student, StudentOpportunity } from 'src/app/shared/models';
import { EmployerService, OpportunityService, SchoolService, StudentService } from 'src/app/shared/services';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-employer-opportunity-student',
  templateUrl: './employer-opportunity-student.component.html',
  styleUrl: './employer-opportunity-student.component.scss'
})
export class EmployerOpportunityStudentComponent extends GenericComponent implements OnInit {

  employer: Employer | undefined;
  student: Student | undefined;
  studentOpportunity: StudentOpportunity | undefined;
  school: School | undefined;

  constructor(
    private employees: EmployerService,
    private students: StudentService,
    private schools: SchoolService,
    private opportunities: OpportunityService,
    snackbar: ToastService,
    private route: ActivatedRoute
  ) {
    super(snackbar);

  }

  async ngOnInit(): Promise<void> {
    await this.executeAsync(async () => {
      const eurl = this.route.snapshot.params.employerUrlName;
      const surl = this.route.snapshot.params.studentUrlName
      const scurl = this.route.snapshot.params.schoolUrl;
      const url = this.route.snapshot.params.urlName;
      const urlParts = url.split('-');
      const oid = urlParts[urlParts.length - 1];
      
      this.employer = await this.employees.getEmployer(eurl);
      this.setWorking(10);
      this.student = await this.students.getStudent(surl);
      this.setWorking(20);
      this.school = await this.schools.get(scurl);
      this.setWorking(30);
      this.studentOpportunity = await this.opportunities.getOpportunityForStudent(oid, this.student?.urlName);
      // await this.students.getOpportunity(ourl.join('-'), oid);
      this.setWorking(40);
    }, 'Error loading student opportunity');
  }

}
