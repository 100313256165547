<app-progress-bar class="mb-small" [value]="working" color="green"></app-progress-bar>

<div *ngIf="opportunity">
    <!-- <div>
        <div class="text text-title mb-small mr-small">
            Best Times
        </div>
        <div class="flex-columns grid-thirds">
            <div class="bg-white py-medium px-medium text-label large text-700 upper" *ngFor="let time of bestTimes">{{time.day}} {{time.times}}</div>
        </div>
    </div> -->
    <form [formGroup]="form" class="form">
        <div class="mb-medium flex-rows">
            <label for="StartDate" class="form-label">
                Start Date
            </label>
            <input id="StartDate" type="date" 
            class="form-input teal border border-teal text search text box" formControlName="StartDate" />
        </div>
        
        <div class="mb-medium flex-rows">
            <label for="ScheduleTime" class="form-label">
                Weekly Schedule 
            </label>
            <label *ngIf="opportunity?.bestStudentAvailability" class="">Suggested Times: {{opportunity?.bestStudentAvailability}}</label>
            
            <input id="ScheduleTime" type="text" 
            [placeholder]=" opportunity?.bestStudentAvailability ? 'Suggested Times: ' + opportunity?.bestStudentAvailability : 'Example: Mondays 8AM'"
            class="form-input teal border border-teal text search text box" formControlName="ScheduleTime" />
        </div>

        <div *ngIf="approvedList.length">
            <label>Invites will get sent to the following students that haver already been accepted for this opportunity.</label>
            <app-list-scroll>
                <ng-template ngFor let-o [ngForOf]="approvedList">
                    <app-employer-prospect-card class="mr-small mb-small" [prospect]="o">
                    </app-employer-prospect-card>

                </ng-template>
            </app-list-scroll>
        </div>

        <div *ngIf="!approvedList?.length">
            <label>Warning: There haven't been any students accepted for this opportunity yet.</label>
        </div>
        <button class="mt-medium btn btn-green" (click)="submit();">Schedule and Send Invites</button>
        
    </form>
</div>