import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UserService } from 'src/app/shared/services/user.service';
import { Router } from '@angular/router';
import { User } from 'src/app/shared/models/user.model';
import { Subscription } from 'rxjs';
import { DropDownOptions } from 'src/app/shared/components/dropdown/dropdown.component';
import { AuthService } from '@auth0/auth0-angular';
import { GTMComponentComponent } from 'src/app/shared/components/gtmcomponent/gtmcomponent.component';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { MenuComponent } from 'src/app/shared/components/menu/menu.component';
import { jwtDecode } from 'jwt-decode';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss']
})
export class TopNavComponent extends GTMComponentComponent implements OnInit, OnDestroy {


  @ViewChild('menu')
  public menu: MenuComponent;
  availableNavActions = [];
  defaultActionText: string;
  private subscriptions: Subscription[] = [];
  public isAdministrator = false;
  who: string | null = null;
  collapseNav = true;
  isSearch = false;
  searchValue = '';
  year: number;
  private self: User;

  @Input()
  public isExpanded: boolean;

  @Output()
  public navToggle = new EventEmitter<boolean>();

  @Output()
  public logInStateChange = new EventEmitter<boolean>();


  @Input()
  get user(): User {
    return this.self;
  }
  set user(user: User) {
    this.self = user;
    if (this.self !== undefined && this.self !== null) {
      this.isAdministrator = this.self.isAdmin;
    }
  }

  allNavActions = [{
     label: 'Login',
     value: 'login',
     loggedIn: false,
     role: undefined
    }, {
      label: 'Register',
      value: 'signup',
      loggedIn: false,
      role: undefined,
    }, {
      label: 'Edit Profile',
      value: 'profile',
      loggedIn: true,
      role: undefined,
    }, {
      label: 'Edit Resumes',
      value: 'resume',
      loggedIn: true,
      role: 'student',
    }, {
      label: 'Edit Company',
      value: 'company',
      loggedIn: true,
      role: 'employer',
    },
    {
      label: 'Edit School',
      value: 'school',
      loggedIn: true,
      role: 'staff',
    }, {
      label: 'Admin - Employers',
      value: 'admin-employers',
      loggedIn: true,
      role: 'admin'
    // }, {
    //   label: 'Messages',
    //   value: 'messages',
    //   loggedIn: true,
    //   role: undefined,
    }, {
      label: 'Faqs & Help',
      value: 'faqs',
      loggedIn: true,
      role: undefined,
    }, {
      label: 'Sign Out',
      value: 'signout',
      loggedIn: true,
      role: undefined,
    }];

  constructor(
    private auth0: AuthService,
    public users: UserService,
    private router: Router,
    gtm: GoogleTagManagerService,
    snackbar: ToastService
  ) {
    super(gtm, snackbar);

    const token = localStorage.getItem('a0at');
    if (token?.length) {
      const decodedToken: any = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds

      // Check if the token's `exp` claim is in the past
      if (decodedToken.exp < currentTime) {
          console.log('Token is expired');
          this.users.reloadProfile();
      }
    }

    this.user = this.users.authenticatedUser;
    if (this.user) {
      this.filterNav();
    }
    this.subscriptions.push(this.users.profileLoadedObservable$.subscribe((u: User) => {
      this.user = u;
      this.defaultActionText = this.user ? 'My Account' : 'Login/Register';
      this.filterNav();
    }));
  }

  async ngOnInit() {
    this.subscriptions.push(this.users.profileLoadedObservable$.subscribe((u: User) => {
      this.user = u;
      this.filterNav();
      this.defaultActionText = this.user ? 'My Account' : 'Login/Register';
      this.filterNav();
    }));
    this.defaultActionText = this.user ? 'My Account' : 'Login/Register';
    this.filterNav();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  filterNav(): void {
    let nav = this.allNavActions.filter(a => (this.user && a.loggedIn) || (!this.user && !a.loggedIn));
    nav = nav.filter(n =>  (n.role === undefined ||
                           (this.user.isStudent && n.role === 'student') ||
                           (this.user.isEmployer && n.role === 'employer') ||
                           (this.user.isStaff && n.role === 'staff') ||
                           (this.user.isAdmin && n.role === 'admin')));
    this.availableNavActions = nav;
  }

  signup() {
    this.users.clearProfile();
    this.handle('signup', 'home', 'click', 'Sign Up');

    this.auth0.loginWithRedirect({
      appState: { 
        target: '/my-profile' 
      },
      authorizationParams: {
        screen_hint: 'signUp',
      }
    });
    
  }

  async login() {
    this.users.clearProfile();
    this.handle('login', 'home', 'click', 'Log In');

    await this.auth0.loginWithRedirect({
      appState: { 
        target: '/dashboard' 
      },      
      authorizationParams: {
        screen_hint: 'login'
      }
    });
  }

  logout(): void {
    this.users.clearProfile();

    this.auth0.logout({
      logoutParams: {
        returnTo: document.location.origin
      }
    });
    localStorage.removeItem('a0at');
    for (let i = 0; i < localStorage.length; ++i)
    {
      const k = localStorage.key(i);
      if (k.indexOf('@@auth') >= 0) {
        localStorage.removeItem(k);
        i--;
      }
    }
    this.logInStateChange.emit(false);
  }
  toggleNav() {
    this.collapseNav = !this.collapseNav;
    this.navToggle.emit(this.collapseNav);
  }

  closeNav(): void {
    this.collapseNav = true;
    this.menu.closeMenu();
  }

  showSearch(): void {
    this.isSearch = true;
    this.collapseNav = true;
    this.navToggle.emit(this.collapseNav);
  }

  async navSelection(value: DropDownOptions): Promise<void> {
    switch (value.value) {
      case 'login':
        this.login();
        this.defaultActionText = this.user ? 'My Account' : 'Login/Register';
        break;
      case 'signup':
        this.signup();
        this.defaultActionText = this.user ? 'My Account' : 'Login/Register';
        break;
      case 'signout':
        await this.logout();
        this.defaultActionText = this.user ? 'My Account' : 'Login/Register';
        break;
      case 'profile':
        this.router.navigate(['/my-profile']);
        break;
      case 'resume':
        this.router.navigate(['/my-profile/resume']);
        break;
      case 'messages':
        this.router.navigate(['/my-profile/messages']);
        break;
      case 'faqs':
        this.router.navigate(['/faqs']);
        break;
      case 'company':
        this.router.navigate(['/employers', this.user?.employee?.employer?.urlName ,'edit']);
        break;
      case 'school':
        this.router.navigate([ '/school', (this.user?.student ?? this.user.staff)?.schoolUrlName, 'edit']);
        break;
        case 'admin-employers':
          this.router.navigate(['/administration', 'employer']);
    }
  }
}
