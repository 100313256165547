import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class YoutubeService {
  constructor(private http: HttpClient) {

  }

  async oembed(url: string, height: string, width: string): Promise<{html: string}> {
    return lastValueFrom(this.http.get(`https://youtube.com/oembed`, {
      params: {
        url, height, width
      }
    })) as Promise<any>;
  }

  async getEmbedHtml(url: string, height: string, width: string) {
    const oembed = await this.oembed(url, height, width)
    return oembed.html
  }
}