<div class="list-view">
    <div class="bg-teal pl-medium pr-medium">
        <div class="bg-lime pl-medium pr-medium py-large">
            <app-filter-menu [vertical]="true" (outputListEvent)="updateOpportunityList($event)"
                [inputList]="opportunityList" [filterDropDownOptions]="opportunityFilterDropDownOptions"
                [searchOptions]="opportunitySearchOptions">
            </app-filter-menu>
        </div>
    </div>
    <div class="bg-cream pl-medium pr-medium py-large">
        <div class="flex-columns mb-medium">
            <div class="text text-title mb-small mr-small">
                My Opportunities
            </div>
        </div>

        <div *ngIf="filterActive">
            <div class="flex-columns justify-between grid-thirds">
                <div *ngFor="let o of modifiedOpportunitiesList | sort:'created':false | paginate:{id: 'opportunity-pager', itemsPerPage: 12, currentPage: opportunityPageNumber };">
                    <app-student-opportunity-card [opportunity]="o"></app-student-opportunity-card>
                </div>
            </div>
            <div *ngIf="modifiedOpportunitiesList" class="mt-small flex-columns justify-around">
                <pagination-controls id="opportunity-pager"
                    (pageChange)="opportunityPageNumber = $event"></pagination-controls>
            </div>
        </div>
        <div *ngIf="!filterActive">

            <div *ngIf="opportunities.length">
                <div class="text large text-500 mb-small mr-small">
                    Active Opportunities
                </div>
                <app-list-scroll>
                    <ng-template ngFor let-o [ngForOf]="opportunities">

                        <app-student-opportunity-card [opportunity]="o"></app-student-opportunity-card>

                    </ng-template>
                </app-list-scroll>
            </div>

            <div *ngIf="requestedOpportunitiesList.length">
                <div class="text large text-500 mb-small mr-small">
                    Requested Opportunities
                </div>
                <app-list-scroll>
                    <ng-template ngFor let-o [ngForOf]="requestedOpportunitiesList">

                        <app-opportunity-request-card [opportunityRequest]="o" [employerUrl]="urlName"></app-opportunity-request-card>
                    </ng-template>
                </app-list-scroll>
            </div>

        </div>


    </div>

</div>


