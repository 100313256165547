import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Subscription } from 'rxjs';
import { GTMComponentComponent } from 'src/app/shared/components/gtmcomponent/gtmcomponent.component';
import { Student } from 'src/app/shared/models/student.model';
import { User } from 'src/app/shared/models/user.model';
import { UserService } from 'src/app/shared/services';
import { CDNService } from 'src/app/shared/services/cdn.service';
import { StudentService } from 'src/app/shared/services/student.service';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-student-profile',
  templateUrl: './student-profile.component.html',
  styleUrls: ['./student-profile.component.scss']
})
export class StudentProfileComponent extends GTMComponentComponent implements OnInit, OnDestroy {

  @Input()
  get student(): Student {
    return this._student;
  }
  set student(value: Student) {
    this._student = value;
    this.urlName = this._student.urlName;
  }
  @Input()
  user: User;

  @Input()
  hideSchoolBanner = false;

  _student: Student;
  urlName: string;
  isLoading = false;

  isMe = false;
  editDescription = false;
  editBenefits = false;
  editWorkingHere = false;

  editCulture = false;
  editServe = false;
  editMission = false;
  workingDescription: string;
  workingBenefits: string;
  workingWorkingHere: string;
  workingCulture: string;
  workingServe: string;
  workingMission: string;

  private subscriptions: Subscription[] = [];

  public studentGroup = new FormGroup({});

  constructor(
    private route: ActivatedRoute,
    private users: UserService,
    private students: StudentService,
    snackbar: ToastService,
    public cdn: CDNService,
    gtm: GoogleTagManagerService,
    private titleService: Title,
  ) {
    super(gtm, snackbar);

    if (!this.user) {
      this.user = this.users.authenticatedUser;
    }
    this.subscriptions.push(this.route.params.subscribe(async params => {
      if (params.urlName) {
        this.urlName = params.urlName;
        await this.loadStudent();
      }
    }));

    this.subscriptions.push(this.users.profileLoadedObservable$.subscribe((u: User) => {
      if (u) {
        this.user = u;
        this.isMe = this.user?.isStudent && this.user?.student.urlName === this.urlName;
      }
    }));
  }

  async ngOnInit(): Promise<void> {
    this.isMe = this.user?.isStudent && this.user?.student.urlName === this.urlName;
    await this.loadStudent();

    this.titleService.setTitle(`${this.student.schoolName} - ${this.student.firstName} ${this.student.lastName}`);

  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  async loadStudent(): Promise<void> {
    if (this.urlName) {
      try {
        this.isLoading = true;
        this.isMe = this.user?.isStudent && this.user?.student.urlName === this.urlName;
        this.student = await this.students.getStudent(this.urlName);
        this.isMe = this.user?.isStudent && this.user?.student.urlName === this.urlName;
        await this.students.tag(this.urlName);
      } catch (error) {
        this.snackbar.error({
          message: 'Error loading profile'
        });
      } finally {
        this.isLoading = false;
      }
    }
  }

  shareProfile() {
    this.handle('share', 'user', 'click', 'share profile');
    navigator.clipboard.writeText(`${window.location.origin}/students/${this.user.student.urlName}`);
    this.snackbar.success({
      message: 'Copied to Clipboard!',
      title: 'Copied'
    });
  }

  getBackgroundImageStyle() {
    return { 'background-image': `url("${this.student.logoUrl}")` };
  }

  getYearInSchool(): string {
    if (this.student) {
      const now = new Date().getFullYear();
      switch (this.student.graduationYear - now) {
        case 0:
          return 'Senior';
        case 1:
          return 'Junior';
        case 2:
          return 'Sophmore';
        case 3:
          return 'Freshman';
        default:
          if ((this.student.graduationYear - now) < 0) {
            return `Graduated ${this.student.graduationYear}`;
          } else {
            return `TBD: (${this.student.graduationYear})`;
          }
      }

    } else {
      return 'TBD';
    }
  }
}

