import { HttpClient, HttpHeaderResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ResumeApiConfig } from '../api/resume.api.config';
import { TemplateType, UNextAward, UNextEducation, UNextExperience, UNextOrganization, UNextResume, UNextResumeSection } from '../models';
import { Section, SectionWord } from '../models/section.model';
import { skipAuthHeaders } from '../auth/auth-interceptor';

@Injectable()
export class ResumeService {

    constructor(private http: HttpClient) { }

    public getResumes(): Promise<UNextResume[]> {
        return lastValueFrom(this.http.get<UNextResume[]>(ResumeApiConfig.getFullUrl('')));
    }

    public getResumeByName(resumeName: string): Promise<UNextResume> {
        return lastValueFrom(this.http.get<UNextResume>(ResumeApiConfig.getFullUrl(`${encodeURIComponent(resumeName)}`), { headers: skipAuthHeaders }));
    }

    public getResumeById(id: number) : Promise<UNextResume>{
        return lastValueFrom(this.http.get<UNextResume>(ResumeApiConfig.getFullUrl(`${id}`), { headers: skipAuthHeaders}));
    }
    public downloadResume(id: number, format: 'pdf' | 'docx'): Promise<any> {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/pdf');
        return lastValueFrom(this.http.get(ResumeApiConfig.getFullUrl(`${id}/${format}`), { headers: headers, responseType: 'blob'} ));
    }

    public newResumeAward(resumeId: number, award: UNextAward) : Promise<UNextAward> {
        return lastValueFrom(this.http.put<UNextAward>(ResumeApiConfig.getFullUrl(`${resumeId}/award`), award));
    }

    public updateResumeAward(resumeId: number, awardId: number, award: UNextAward) : Promise<UNextAward> {
        return lastValueFrom(this.http.patch<UNextAward>(ResumeApiConfig.getFullUrl(`${resumeId}/award/${awardId}`), award));
    }

    public newResumeEducation(resumeId: number, education: UNextEducation) : Promise<UNextEducation> {
        return lastValueFrom(this.http.put<UNextEducation>(ResumeApiConfig.getFullUrl(`${resumeId}/education`), education));
    }

    public updateResumeEducation(resumeId: number, educationId: number, education: UNextEducation) : Promise<UNextEducation>{
        return lastValueFrom(this.http.patch<UNextEducation>(ResumeApiConfig.getFullUrl(`${resumeId}/education/${educationId}`), education));
    }

    public newResumeExperience(resumeId: number, experience: UNextExperience) : Promise<UNextExperience> {
        return lastValueFrom(this.http.put<UNextExperience>(ResumeApiConfig.getFullUrl(`${resumeId}/experience`), experience));
    }

    public updateResumeExperience(resumeId: number, experienceId: number, experience: UNextExperience) : Promise<UNextExperience> {
        return lastValueFrom(this.http.patch<UNextExperience>(ResumeApiConfig.getFullUrl(`${resumeId}/experience/${experienceId}`), experience));
    }

    public newResumeOrganization(resumeId: number, organization: UNextOrganization) : Promise<UNextOrganization> {
        return lastValueFrom(this.http.put<UNextOrganization>(ResumeApiConfig.getFullUrl(`${resumeId}/organization`), organization));
    }

    public updateResumeOrganization(resumeId: number, organizationId: number, organization: UNextOrganization) : Promise<UNextOrganization> {
        return lastValueFrom(this.http.patch<UNextOrganization>(ResumeApiConfig.getFullUrl(`${resumeId}/organization/${organizationId}`), organization));
    }

    public newResumeSection(resumeId: number, section: UNextResumeSection) : Promise<UNextResumeSection> {
        return lastValueFrom(this.http.put<UNextResumeSection>(ResumeApiConfig.getFullUrl(`${resumeId}/general`), section));
    }

    public updateResumeSection(resumeId: number, resumeSectionId: number, section:UNextResumeSection) : Promise<UNextResumeSection> {
        return lastValueFrom(this.http.patch<UNextResumeSection>(ResumeApiConfig.getFullUrl(`${resumeId}/general/${resumeSectionId}`), section));
    }

    public addResumeSkill(resumeId: number, sectionId: number, resumeSectionId: number, skill: string) : Promise<UNextResumeSection> {
        return lastValueFrom(this.http.post<UNextResumeSection>(ResumeApiConfig.getFullUrl(`${resumeId}/${sectionId}/${resumeSectionId}/skill/${encodeURIComponent(skill)}`), {}));
    }
    public removeResumeSkill(resumeId: number, path: string, resumeSectionId: number, skill: string) : Promise<UNextResumeSection> {
        return lastValueFrom(this.http.delete<UNextResumeSection>(ResumeApiConfig.getFullUrl(`${resumeId}/${path}/${resumeSectionId}/skill/${encodeURIComponent(skill)}`)));
    }

    public addResumeLanguage(resumeId: number, sectionId: number, resumeSectionId: number, language: string) : Promise<UNextResumeSection> {
        return lastValueFrom(this.http.post<UNextResumeSection>(ResumeApiConfig.getFullUrl(`${resumeId}/${sectionId}/${resumeSectionId}/language/${encodeURIComponent(language)}`), {}));
    }

    public removeResumeLanguage(resumeId: number, path: string, resumeSectionId: number, language: string) : Promise<UNextResumeSection> {
        return lastValueFrom(this.http.delete<UNextResumeSection>(ResumeApiConfig.getFullUrl(`${resumeId}/${path}/${resumeSectionId}/language/${encodeURIComponent(language)}`)));
    }

    public addResumeStudyMajor(resumeId: number, path: string, resumeSectionId: number, major: string) : Promise<UNextResumeSection> {
        return lastValueFrom(this.http.post<UNextResumeSection>(ResumeApiConfig.getFullUrl(`${resumeId}/${path}/${resumeSectionId}/study/${encodeURIComponent(major)}/major`), {}));
    }

    public removeStudy(resumeId: number, path: string, resumeSectionId: number, study: string): Promise<HttpHeaderResponse> {
        return lastValueFrom(this.http.delete<HttpHeaderResponse>(ResumeApiConfig.getFullUrl(`${resumeId}/${path}/${resumeSectionId}/study/${encodeURIComponent(study)}`)));
    }

    public addResumeStudyMinor(resumeId: number, path: string, resumeSectionId: number, minor: string) : Promise<UNextResumeSection> {
        return lastValueFrom(this.http.post<UNextResumeSection>(ResumeApiConfig.getFullUrl(`${resumeId}/${path}/${resumeSectionId}/study/${encodeURIComponent(minor)}/minor`), {}));
    }

    public deleteResume(resumeId: number): Promise<HttpHeaderResponse> {
        return lastValueFrom(this.http.delete<HttpHeaderResponse>(ResumeApiConfig.getFullUrl(`resume/${resumeId}`)));
    }

    public deleteResumeSection(resumeId: number, path: string, resumeSectionId: number, entityId: number) : Promise<HttpHeaderResponse>{
        return lastValueFrom(this.http.delete<HttpHeaderResponse>(ResumeApiConfig.getFullUrl(`${resumeId}/${path}/${resumeSectionId}/${entityId}`)));
    }

    public updateResume(id: number, resume: UNextResume) : Promise<UNextResume> {
        return lastValueFrom(this.http.patch<UNextResume>(ResumeApiConfig.getFullUrl(`${id}`), resume));
    }

    public createNewResume(resume: UNextResume) : Promise<UNextResume>{
        return lastValueFrom(this.http.post<UNextResume>(ResumeApiConfig.getFullUrl(''), resume));
    }

    public cloneResume(id: number): Promise<UNextResume> {
        return lastValueFrom(this.http.get<UNextResume>(ResumeApiConfig.getFullUrl(`${id}/clone`)));
    }

    public getResumeSections() : Promise<Section[]>{
        return lastValueFrom(this.http.get<Section[]>(ResumeApiConfig.getFullUrl('section')));
    }

    public getResumeSectionHeaders(resumeId: number) : Promise<Section[]>{
        return lastValueFrom(this.http.get<Section[]>(ResumeApiConfig.getFullUrl(`${resumeId}/headers`)));
    }

    public getResumeSectionWords() : Promise<Section>{
        return lastValueFrom(this.http.get<Section>(ResumeApiConfig.getFullUrl('section/words')));
    }

    public getResumeWordReasons() : Promise<string[]>{
        return lastValueFrom(this.http.get<string[]>(ResumeApiConfig.getFullUrl('word/reasons')));
    }

    public getSectionWords(sectionId: number) : Promise<SectionWord[]>{
        return lastValueFrom(this.http.get<SectionWord[]>(ResumeApiConfig.getFullUrl(`section/${sectionId}/words`)));
    }


    public getReplacementWords() : Promise<SectionWord[]>{
        return lastValueFrom(this.http.get<SectionWord[]>(ResumeApiConfig.getFullUrl('section/replacements')));
    }

    public getLanguages(): Promise<string[]> {
        return lastValueFrom(this.http.get<string[]>(ResumeApiConfig.getFullUrl('language')));
    }

    public parse(files: File[]): Promise<string[]> {
        const formData = new FormData();

        if (files && files.length) {
            for (let i = 0; i < files.length; ++i) {
                formData.append(`file-${i}`, files[i], files[i].name);
            }
        }
        return lastValueFrom(this.http.post<string[]>(ResumeApiConfig.getFullUrl('parse'), formData));
    }

    public updateResumeSectionName(resumeId: number, sectionId: number, sectionNameId: number) : Promise<boolean> {
        return lastValueFrom(this.http.post<boolean>(ResumeApiConfig.getFullUrl(`${resumeId}/${sectionId}/${sectionNameId}`), {}));
    }  

    public getCertifications() : Promise<any[]>{
        return lastValueFrom(this.http.get<any[]>(ResumeApiConfig.getFullUrl('certification')));
    }

    updateResumeSectionOrder(resumeId: number, resume: Section): Promise<boolean>{
        return lastValueFrom(this.http.post<boolean>(ResumeApiConfig.getFullUrl(`display/${resumeId}`), resume));
    }

    getTemplateTypes(): Promise<TemplateType[]> {
        return lastValueFrom(this.http.get<TemplateType[]>(ResumeApiConfig.getFullUrl('template/type')));
    }
}
