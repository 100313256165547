import { Component, Input, OnChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Opportunity, StudentOpportunity } from 'src/app/shared/models';
import { OpportunityService } from 'src/app/shared/services';

@Component({
  selector: 'app-schedule-opportunity',
  templateUrl: './schedule-opportunity.component.html',
  styleUrl: './schedule-opportunity.component.scss'
})
export class ScheduleOpportunityComponent implements OnChanges {


  working: boolean = false;
  form: FormGroup;

  @Input()
  opportunity: Opportunity;


  approvedList: StudentOpportunity[] = [];



  constructor(
    private fb: FormBuilder,
    private opportunities: OpportunityService,
  ) {

  }

  async ngOnChanges(){
    if(this.opportunity !== undefined){

      this.createForm();

      const prospects = await this.opportunities.getOpportunityProspectsSearch(
        this.opportunity?.id,
        null, null
      );

      this.approvedList = prospects.filter(p => p.accepted === true);
    }
  }

  createForm() {
    this.form = this.fb.group({
      StartDate: new FormControl(this.opportunity?.startDate),
      ScheduleTime: new FormControl(this.opportunity?.bestStudentAvailability),
    });
  }

  async submit(){

    this.opportunity.startDate = this.form.get('StartDate').value;
    this.opportunity.weeklySchedule = this.form.get('ScheduleTime').value;

    await this.opportunities.scheduleOpportunity(this.opportunity.id, this.opportunity);
  }
}
