<div class="mb-small profile-card bg-white pl-small pr-small py-small mr-medium" style="min-width: 350px;">
  <div class="text card-title">
    {{ opportunityRequest?.opportunityType }} Request
  </div>
  <div class="mt-small flex-columns">
    <div>
      <div class=" flex-columns">

        <div class="tags">
          <!-- <span class="chip mr-small ">
          {{opportunityRequest?.opportunityType }}
        </span> -->

          <!-- <span class="chip mr-small chip-{{ chipColor() }}">
          {{ opportunityRequest?.opportunityType }}
      </span> -->
          <span class="chip mr-small chip-green " (click)="openModal('potential-applications' + opportunityRequest.id)">
            {{ opportunityRequest?.applications?.length }} potential applicants
          </span>


        </div>
      </div>
      <div class="text">
        <span class="text mr-auto">
          <!-- <ng-template ngFor let-l [ngForOf]="studentOpportunity?.opportunity?.locations || opportunity?.locations"
          let-i="index" let-isFirst="first" let-isLast="last">
          {{ i > 0 ? '' : l }}
          {{ i > 0 && isLast ? '& more' : '' }}
        </ng-template> -->
          {{opportunityRequest.employerLocation}}
        </span>
      </div>
      <div class="text" *ngIf="opportunityRequest?.employerIndustry">
        <span class="text mr-auto">
          <!-- <ng-template ngFor let-l [ngForOf]="studentOpportunity?.opportunity?.industries || opportunity?.industries"
          let-i="index" let-isFirst="first" let-isLast="last">
          {{ i > 0 ? '' : l }}
          {{ i > 0 && isLast ? '& more' : '' }}
        </ng-template> -->
          {{opportunityRequest?.employerIndustry}}
        </span>
      </div>

    </div>
    <!-- <img *ngIf="opportunity?.thumbnailUrl ?? opportunity?.employerLogoUrl"
    src="{{ cdn.toCDN(opportunity?.thumbnailUrl ?? opportunity?.employerLogoUrl, true) }}"
    alt="{{opportunity?.title}}" /> -->
  </div>

  <!-- <div class="text" *ngIf="studentOpportunity?.appliedDate">
    Applied on {{ studentOpportunity.appliedDate | date }}
  </div> -->
  <!-- <button class="profile btn btn-white mr-small border-teal"
    [routerLink]="['/employers', (studentOpportunity?.opportunity?.employerUrlName || opportunity?.employerUrlName), 'opportunity', (studentOpportunity?.opportunity?.urlName || opportunity?.urlName)]">
    Learn More
  </button> -->

  <button class="profile btn btn-white mr-small border-teal" (click)="createOpportunity()"> Create Opportunity</button>

</div>

<app-modal [heading]="opportunityRequest.applications.length + ' Potential Applications'"
  [id]="'potential-applications' + opportunityRequest.id" [size]="'medium'">
  <app-list-scroll style="width: 100vw;">

    <ng-template ngFor let-s [ngForOf]="opportunityRequest.applications">
      <div style="min-width:300px" class="mr-medium mb-small mr-small profile-card bg-white pl-small pr-small py-small">
        <div class="text card-title">
          {{ s?.student?.firstName }} {{ s?.student?.lastName }}
        </div>
        <div class="flex-columns">
          <div class="flex-rows">
            <div class="text text-rounded-mplus text-400">
              {{ s?.student?.schoolName }}
            </div>
          </div>
          <div class="avatar ml-auto">
            <img [src]="cdn.toCDN(s?.student?.avatarUrl, true)" alt="profile pic" />
          </div>
        </div>
        <a class="profile btn btn-white mr-small border-teal" [routerLink]="['/students', s?.student?.urlName]">View
          Profile</a>

      </div>

    </ng-template>

  </app-list-scroll>
</app-modal>