<div class="list-view">
    <div class="bg-teal pl-medium pr-medium">

        <app-progress-bar [splashScreen]="true" [value]="working"></app-progress-bar>

        <div class="bg-lime pl-medium pr-medium py-large form">
                <div class="filter-section">
                    <label class="text text-title mb-small">Filter Search</label>
                    <div class="mb-small">
                        <input id="search" type="text" placeholder="Search"
                            class="form-input teal border border-teal text search text box" style="width: 100%"
                            [(ngModel)]="search" />
                    </div>

                    <ng-select class="drop-down drop-down-buttons drop-down-button drop-down-button-lime mb-small"
                        [items]="listOpportunities | sort:'label':true" (change)="selectOpportunity($event)"
                        placeholder="Select an opportunity"></ng-select>
                    <ng-select class="drop-down drop-down-buttons drop-down-button drop-down-button-lime mb-small"
                        [items]="listSchools | sort:'label':true" (change)="selectSchool($event)"
                        placeholder="Select a school"></ng-select>
                    <div class="flex-columns justify-between">
                        <button class="btn btn-green" (click)="loadProspects()">Search</button>
                        <button class="btn btn-green" (click)="resetProspects()">Clear</button>
                    </div>
                </div>
        </div>
    </div>

    <div class="bg-cream pl-medium pr-medium py-large">
        <div class="flex-columns justify-between">
            <div class="flex-columns">
                <div class="text text-title mb-small mr-small">
                    Prospects
                </div>
                <app-info-modal [audience]="'Employers'" [section]="'Prospects'" [item]="'Prospects'"></app-info-modal>
            </div>
            <div>
                <button class="btn btn-green" (click)="openScheduler()" *ngIf="selectedOpportunity && schedule && selectedPropsects?.length !== 0 && !selectedOpportunity?.scheduled">Schedule Opportunity</button>
            </div>
        </div>
        <div class="mb-small recents">
            <div *ngIf="selectedOpportunity && selectedPropsects?.length === 0">
                No one has applied for this position
            </div>
            <div class="flex-columns grid-halves">
                <app-employer-prospect-card class="mr-small mb-small" [prospect]="prospect"
                    *ngFor="let prospect of selectedPropsects | paginate: {id: 'prospect-pager', itemsPerPage: 4, currentPage: prospectPageNumber }">
                </app-employer-prospect-card>
            </div>
            <div class="mt-small flex-columns justify-around">
                <pagination-controls id="prospect-pager" (pageChange)="prospectPageNumber = $event"></pagination-controls>
            </div>
        </div>

        <div>
            <div class="text text-title mb-small mr-small">
                Best Times
            </div>
            <div class="flex-columns grid-thirds">
                <div class="bg-white py-medium px-medium text-label large text-700 upper" *ngFor="let time of bestTimes">{{time.day}} {{time.times}}</div>
            </div>
        </div>
    </div>
</div>

<app-modal [heading]="'Schedule ' + selectedOpportunity?.title" size="medium" id="schedule-opportunity">
    <app-schedule-opportunity  [opportunity]="selectedOpportunity"></app-schedule-opportunity>
</app-modal>
