import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Opportunity } from 'src/app/shared/models';
import { OpportunityService } from 'src/app/shared/services';
import { SortDataService } from 'src/app/shared/services/sort.data.service';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-opportunity-add-attribute',
  templateUrl: './opportunity-add-attribute.component.html',
  styleUrls: ['./opportunity-add-attribute.component.scss']
})
export class OpportunityAddAttributeComponent implements OnInit {

  @Input()
  opportunity: Opportunity;
  @Input()
  get availableValues(): string[] {
    return this.values;
  }
  set availableValues(vals: string[]) {
    this.values = vals;
    this.updateFilter();
  }
  @Input()
  attribute: 'school' | 'education' | 'majors' | 'minors' | 'industry' | 'fieldofinterest' | 'city' | 'skills';
  @Input()
  placeholder: string;
  @Input()
  buttonColor: 'green' | 'orange' | 'blue' | 'purple' | 'teal';
  @Input()
  showOnFocus = false;

  @Input()
  useSelect: boolean = false;

  @Output()
  added: EventEmitter<string> = new EventEmitter<string>();

  working = false;
  isAdded = false;
  public filtered: string[] = [];
  search = '';
  values: any[] = [];
  private minSearchSize = 1;
  private fromFocus = false;

  constructor(
    private opportunities: OpportunityService,
    private snackBar: ToastService,
    private sortDateService: SortDataService
  ) { }

  ngOnInit(): void {
    this.updateFilter();
  }

  updateSearch(event: any) {
    this.search = event.target.value;
    this.updateFilter();
  }

  updateFilter() {
    if (this.search.length > this.minSearchSize || this.fromFocus) {
      this.filtered = this.values
        .filter(foi => foi.toLowerCase().indexOf(this.search.toLowerCase()) >= 0);
    } else if (this.search.length <= this.minSearchSize) {
      this.filtered = [];
    }
    this.fromFocus = false;
  }

  select(value: string): void {
    this.search = value;
    this.filtered = [value];
  }

  isSelected(): boolean {
    return this.filtered.length === 1 && this.filtered[0] === this.search;
  }

  async updateOpportunity(value: string) {
    if (this.opportunity?.id) {
      try {
        this.added.next(value);
        this.search = '';
        this.updateFilter();
        this.working = true;
        await this.opportunities.addProfile(this.opportunity.urlName, this.attribute, value);
        this.snackBar.success({
          message: `${value} has been added.`,
        });
      } catch (error) {
        console.error(error);
        this.snackBar.error({
          title: 'Error',
          message: `There was a problem adding ${value}.`
        });
      } finally {
        this.working = false;
      }
    } else {
      this.added.next(value);
    }
    this.search = '';
    this.updateFilter();
  }

  onFocus(): void {
    if (this.showOnFocus) {
      this.fromFocus = true;
      this.updateFilter();
    }
  }

  searchValues(value: any, array: any): any {
    this.values = this.sortDateService.sortSearch(value, array);
  }

  setSelection(e: string){
 
    this.search = e;
    this.filtered = [e];
    this.updateOpportunity(e);
  }
}
