import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { DiscussionMessage, MessageSummary, InviteMessage } from '../models/discussionMessage.model';
import { MessageApiConfig } from '../api';

@Injectable()
export class MessageService {

    constructor(public http: HttpClient) {

    }

    public get(): Observable<DiscussionMessage[]> {
        return this.http.get<DiscussionMessage[]>(MessageApiConfig.getFullUrl(''));
    }
    public getOpportunityDiscussion(id: number): Promise<DiscussionMessage[]> {
        return lastValueFrom(this.http.get<DiscussionMessage[]>(MessageApiConfig.getFullUrl(`opportunity/${id}`)));
    }

    public updateDiscussion(message: DiscussionMessage): Promise<DiscussionMessage> {
        return lastValueFrom(this.http.post<DiscussionMessage>(MessageApiConfig.getFullUrl(`opportunity/${message.studentOpportunityId}`),
        message));
    }
    public addDiscussion(message: DiscussionMessage): Promise<DiscussionMessage> {
        return lastValueFrom(this.http.put<DiscussionMessage>(MessageApiConfig.getFullUrl(`opportunity/${message.studentOpportunityId}`),
        message));
    }
    public getSummary(): Promise<MessageSummary> {
        return lastValueFrom(this.http.get<MessageSummary>(MessageApiConfig.getFullUrl('summary')));
    }

    public inviteToApply(invite: InviteMessage): Promise<boolean> {
        return lastValueFrom(this.http.post<boolean>(MessageApiConfig.getFullUrl(`invite`), invite));
    }

    public sendOpportunityRequest(employerUrlName: string, opportunityRequestId: number): Promise<boolean> {
        return lastValueFrom(this.http.get<boolean>(MessageApiConfig.getFullUrl(`${employerUrlName}/opportunity/${opportunityRequestId}/request`)));
    }


}
