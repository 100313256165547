<div style="position: relative;">
    <form [formGroup]="form" class="quill-container" style="text-transform: none;">
        <quill-editor #editor class="quill-editor content-editor {{ size }}" (onEditorCreated)="onEditorCreated($event)"
            [(ngModel)]="quillHtml" placeholder="{{placeholder}}" formControlName="editor" format="html"
            (onContentChanged)="quillContentChanged($event)" [modules]="quillConfiguration" [sanitize]="true"
            (onSelectionChanged)="onSelection()">
        </quill-editor>
    </form>

    <div #replacementWordPopup class="popup">
        <div class="flex-columns justify-between">
            <button class="button btn-blue button-slim" (click)="closePopup()">Close</button>
            <button class="button dark-green button-slim" *ngIf="useWizard" (click)="findNextWord(selection.index)">Next</button>
        </div>
        <div class="text mb-small">
            <span class="text text-500">{{selectedTextHeader}}: </span> {{selectedText}}
        </div>        
        <div>
            <p class="text text-wrap">{{ selectedTextReason }}</p>
            <span class="text text-500">Replacement words:</span>

            <ng-template ngFor let-c [ngForOf]="suggestionsList" let-last="last">
                <div class="text popup-item" (click)="selectReplacement(c)" [class.no-border]="last">
                    {{c.label}}
                </div>
            </ng-template>
        </div>
        <div>
            <span class="text small text-wrap">
                Some words may not have direct synonyms as the context should be avoided in the resume. Some synonyms
                will need different verbiage to be coherent.
            </span>
        </div>
    </div>


</div>