import { Component, Input, ViewChild } from '@angular/core';
// import introJs from 'intro.js';
import { CustomQuillComponent } from 'src/app/shared/components/custom-quill/custom-quill.component';
import { GenericComponent } from 'src/app/shared/components/generic-component/generic.component';
import { AnalyticsModel, JobDescription, Section } from 'src/app/shared/models';
import { CustomQuillService } from 'src/app/shared/services/customQuill.service';
import { SmartWordAnalyzer, SmartWordService } from 'src/app/shared/services/smartwords.service';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrl: './analytics.component.scss'
})
export class AnalyticsComponent extends GenericComponent /*implements AfterViewInit*/ {

  @Input()
  section: Section;

  @Input()
  analytics: AnalyticsModel;

  @Input()
  jobDescription: JobDescription;

  actionWords = SmartWordAnalyzer.actionWords;
  metrics = SmartWordAnalyzer.metrics;
  strongWords = SmartWordAnalyzer.strongWords;
  weakWords = SmartWordAnalyzer.weakWords;
  tense = SmartWordAnalyzer.tense;
  skills = SmartWordAnalyzer.skills;

  isCurrent = false;

  suggestions = false;
  actionsExpanded = false;
  metricsExpanded = false;
  strongExpanded = false;
  tenseExpanded = false;
  weakExpanded = false;
  skillsExpanded = false;
  @ViewChild('myCustomQuill', { static: false }) myQuillComponent: CustomQuillComponent;
  active: 'Notable' | 'Revise' | 'Description' = 'Description';

  constructor(
    public smartWords: SmartWordService,
    private customQuillService: CustomQuillService,
    snackbar: ToastService) {
    super(snackbar);
  }

  // ngAfterViewInit() {
  //   //introJs().start();
  // }

  // toggleViewPositiveNegativeSuggestions() {
  //   this.suggestions = !this.suggestions;
  // }
  selectWord(e: string) {
    this.customQuillService.changeMessage(e);
  }

  useAnalyzer(analyzer: number, check: number): boolean {
    return (analyzer & check) === check;
  }

  analyzerCount(analyzers: SmartWordAnalyzer[]): number | undefined {
    return this.execute(() => {
      let count = undefined;
      analyzers?.forEach(analyzer => {
        if (this.useAnalyzer(this.section.analyze, analyzer)) {
          switch (analyzer) {
            case SmartWordAnalyzer.actionWords:
              count = (count ?? 0) + (this.analytics?.counts.actions ?? 0);
              break;
            case SmartWordAnalyzer.metrics:
              count = (count ?? 0) + (this.analytics?.counts?.metrics ?? 0);
              break;
            case SmartWordAnalyzer.required:
              count = (count ?? 0) + (this.analytics?.counts?.required ?? 0);
              break;
            case SmartWordAnalyzer.skills:
              count = (count ?? 0) + (this.analytics?.counts?.skills ?? 0);
              break;
            case SmartWordAnalyzer.strongWords:
              count = (count ?? 0) + (this.analytics?.counts?.strongWords ?? 0);
              break;
            case SmartWordAnalyzer.tense:
              count = (count ?? 0) + (this.analytics?.counts?.mixedTense ?? 0);
              break;
            case SmartWordAnalyzer.weakWords:
              count = (count ?? 0) + (this.analytics?.counts?.weakWords ?? 0);
              break;
            default:
              break;
          }
        }
      });
      return count;  
    }, 'Error analyzing words');
  }

  countNotableWords(): number {
    return this.execute(() => {
      let count = this.analyzerCount([this.actionWords, this.metrics, this.strongWords]) ?? 0;
      if (this.useAnalyzer(this.section.analyze, this.skills) && this.analytics.skills.length > 4 && this.analytics.skills.length < 11) {
        ++count;
      }
  
      return count;  
    }, 'Error counting notable words');
  }
  countRevisions(): number {
    return this.execute(() => {
      let count = this.analyzerCount([this.weakWords, this.tense]) ?? 0;

      if (this.useAnalyzer(this.section.analyze, this.metrics) && this.analytics.metricWords.length === 0) {
        ++count;
      }
      if (this.useAnalyzer(this.section.analyze, this.skills) && (this.analytics.skills.length < 5 || this.analytics.skills.length > 10)) {
        ++count;
      }
  
      if (this.useAnalyzer(this.section.analyze, SmartWordAnalyzer.actionWords) && this.analytics.repitition.length > 0) {
        ++count;
      }
      if (this.useAnalyzer(this.section.analyze, SmartWordAnalyzer.actionWords) && this.analytics.actionFirst.length > 0) {
        ++count;
      }
  
      return count;  
    }, 'Error analyzing revisions');
  }
}
