import { Component, Input, OnInit } from '@angular/core';
import { Section, SectionType, UNextAward, UNextEducation, UNextExperience, UNextOrganization, UNextResume } from 'src/app/shared/models';

@Component({
  selector: 'app-nwtc-resume-template',
  templateUrl: './nwtc-resume-template.component.html',
  styleUrl: './nwtc-resume-template.component.scss'
})
export class NwtcResumeTemplateComponent implements OnInit {
 
  @Input() resume: UNextResume;

  section: Section
  sectionType = SectionType;

  education: UNextEducation[] = [];
  experience: UNextExperience[] = [];
  skills: string[] = [];
  languages: string[] = [];
  subheading: string[] = [];
  awardSections: { name: string, awards: UNextAward[] }[] = [];
  organizationSections: { name: string, organizations: UNextOrganization[] }[] =[];
  blankLineCheck = /<br\s*\/?>|<p>\s*<\/p>|<p>\s*<\/p>/gi;

  summary = '';
  sections: any;
  
  ngOnInit(): void {
    this.resume.sections = this.resume.sections.filter(a => a.name !== 'Contact Info');
    if (this.resume.address?.length) {
      this.subheading.push(this.resume.address);
    }
    if (this.resume.phone?.length) {
      this.subheading.push(this.resume.phone);
    }
    if (this.resume.email?.length) {
      this.subheading.push(this.resume.email);
    }
    
    for (let i = 0; i < this.resume.sections.length; ++i) {
      const section = this.resume.sections[i];

      switch(section.sectionType){
        case SectionType.Education:
          section.resumeSections.forEach(e => {
            e.description = this.scrubDescription(e.description);
            this.education.push(e as UNextEducation);
          });
          break;
        case SectionType.Experience:
          section.resumeSections.forEach(e => {
            e.description = this.scrubDescription(e.description);
            this.experience.push(e as UNextExperience);
          });
          break;
        case SectionType.Organization:
          if (!this.organizationSections.find(o => o.name === section.name)) {
            this.organizationSections.push({ name: section.name, organizations: [] });  
          }
          section.resumeSections.forEach(s => {
            s.description = this.scrubDescription(s.description);
            this.organizationSections.find(o => o.name === section.name).organizations.push(s as UNextOrganization);
          });
          break;
        case SectionType.Award:
          if (!this.awardSections.find(a => a.name === section.name)) {
            this.awardSections.push({ name: section.name, awards: [] });
          }
          section.resumeSections.forEach(s => {
            s.description = this.scrubDescription(s.description);
            this.awardSections.find(a => a.name === section.name).awards.push(s as UNextAward);
          });
          break;
        case SectionType.Skills:
          this.skills = section.resumeSections[0] ? section.resumeSections[0].skills : [];
          break;
        case SectionType.Language:
          this.languages = section.resumeSections[0] ? section.resumeSections[0].languages : [];
          break;
        default:
          if (!this.handleGeneralSection(section)) {
            this.resume.sections.splice(i--, 1);
          }
          break;
      }
    }
  }

  private handleGeneralSection(section: Section): boolean {
    if(section.sectionType === SectionType.General && (section.name === 'Biography' || section.name === 'Summary')) {
      this.summary = this.scrubDescription(section.resumeSections[0] ? section.resumeSections[0].description : '');
      
    } else if (section.sectionType === this.sectionType.General && (section.name === 'Career Objectives') && this.resume.sections.find(s => s.name === 'Biography' || s.name === 'Summary')) {
      return false;
    }
    return true;
  }

  private scrubDescription(description: string): string {
    description = description.replace(this.blankLineCheck, '');

    const lines = description.split('\n');
    for (let i = 0; i < lines.length; ++i) {
      if (lines[i].length === 0) {
        lines.splice(i--, 1);
      }
    }

    return lines.join('\n');
  }

  isFuture(when: Date | undefined): boolean {
    return !!when && when > new Date();
  }

  degreeName(edu: UNextEducation): string {
    switch (edu.degree) {
        case 1:
            return 'Associate\'s Degree';
        case 2:
            return 'Bachelor\'s Degree';
        case 3:
            return 'Master\'s Degree';
        case 4:
            return 'PhD';
    }
}

}
