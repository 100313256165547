<app-progress-bar [value]="working" [splashScreen]="true"></app-progress-bar>
<div #logo *ngIf="employer?.logoUrl" class="banner">
    <div class="banner-inner" [ngStyle]="{ 'background-image': 'url(' + cdn.toCDN( employer?.logoUrl, true) + ')' }">
        <div hidden>
            {{ opportunity.title }}
        </div>
    </div>
</div>
<div class="content" style="min-height: 75vh; height: fit-content; width: -webkit-fill-available;">
    <div class="bg-cream pl-medium pr-medium py-large main-panel">
        <div class="mb-medium info">
            <div class="flex-rows ml-large" style="position: relative;">
                <div class="logo-wrapper">
                    <div class="bg-white border-teal mb-small border border-round logo" *ngIf="employer?.smallLogoUrl">
                        <div class="logo-inner"
                            [ngStyle]="{ 'background-image': 'url(' + cdn.toCDN( employer?.smallLogoUrl, true) + ')' }">
                            <span class="sr-only">{{ opportunity.title + ' logo' }}</span>
                        </div>
                    </div>
                    <div class="actions mb-medium">
                        <button *ngIf="!user" (click)="login()" class="btn lime-green mb-small">Sign up to
                            Apply
                        </button>
                        <a *ngIf="user?.isStudent && opportunity?.applicationUrl?.length"
                            [href]="opportunity.applicationUrl"
                            (click)="handle('opportunity', 'apply external', 'click', opportunity.title)"
                            class="button button-link btn-green" (click)="open('apply-for-opportunity')">
                            Apply Externally
                        </a>
                        <button
                            *ngIf="user?.isStudent && !opportunity?.applicationUrl?.length && !studentOpportunity?.applied"
                            (click)="handle('opportunity', 'apply', 'click', opportunity.title)"
                            class="btn lime-green mb-small" (click)="open('apply-for-opportunity')">
                            Apply for Position
                        </button>
                        <div *ngIf="user?.isStudent && !opportunity?.applicationUrl?.length && !!studentOpportunity?.applied"
                            class="sticker sticker-lime border border-teal">
                            You've Applied for this Position
                        </div>
                    </div>
                </div>

            </div>
            <div class="flex-rows">
                <div class="text text-title text-right mb-medium">
                    {{ opportunity?.title}}
                </div>

                <span *ngIf="opportunity?.opportunityType"
                    class="chip mb-small ml-auto chip-{{ chipColor() }} opportunity-type">
                    {{ opportunity?.opportunityType }}
                </span>
                <span class="chip mb-small ml-auto chip-green opportunity-type">
                    {{ opportunity?.numberApplicants }} Applicants
                </span>
                <span class="chip chip-green-muted mb-small ml-auto" *ngIf="opportunity?.allowRemote">
                    Remote
                </span>
            </div>
        </div>
        <div class="spacer spacer-teal"></div>
        <div>
            <div class="text large mt-medium mb-small">Description</div>
            <div class="description text mb-small" [ngClass]="{ 'fill': !opportunity?.thumbnailUrl }"
                [innerHTML]="opportunity?.description">
            </div>
        </div>
        <div *ngIf="opportunity?.schools?.length || opportunity?.skills?.length">
            <div class="spacer spacer-teal"></div>
                <div class="industries" *ngIf="opportunity?.schools?.length">
                    <div class="text large mb-medium mt-medium">Schools</div>
                    <div class="flex-columns flex-wrap">
                        <ng-template ngFor let-i [ngForOf]="opportunity?.schools | sort:'name':true">
                            <div class="chip chip-green mb-small">
                                {{ i ?? i?.name }}
                            </div>
                        </ng-template>
                    </div>
                </div>
        </div>
        <div *ngIf="opportunity?.majors?.length || opportunity?.minors?.length">
            <div class="spacer spacer-teal"></div>
            <div class="col grid-halves">
                <div *ngIf="opportunity?.majors?.length" class="majors ml-auto mb-small">
                    <div class="text large mt-medium mb-medium">Majors</div>

                    <div class="flex-columns flex-wrap">
                        <ng-template ngFor let-i [ngForOf]="opportunity?.majors | sort:'name':true">
                            <span class="chip chip-green mb-small">
                                {{ i }}
                            </span>
                        </ng-template>
                    </div>
                </div>
                <div *ngIf="opportunity?.minors?.length" class="majors ml-auto mb-small">
                    <div class="text large mt-medium mb-medium">Minors</div>

                    <div class="flex-columns flex-wrap">
                        <ng-template ngFor let-i [ngForOf]="opportunity?.minors | sort:'name':true">
                            <span class="chip chip-green mb-small">
                                {{ i }}
                            </span>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="opportunity?.skills?.length || opportunity?.interests?.length">
            <div class="spacer spacer-teal"></div>
            <div class="col grid-halves">
                <div class="industries" *ngIf="opportunity?.skills?.length">
                    <div class="text large mt-medium mb-medium">Skills</div>
                    <div class="flex-columns flex-wrap">
                        <ng-template ngFor let-i [ngForOf]="opportunity?.skills | sort:'name':true">
                            <div class="chip chip-green mb-small">
                                {{ i ?? i?.name }}
                            </div>
                        </ng-template>
                    </div>
                </div>
                <div class="industries" *ngIf="opportunity?.interests?.length">
                    <div class="text large mt-medium mb-medium">Fields Of Interest</div>
                    <div class="flex-columns flex-wrap">
                        <ng-template ngFor let-i [ngForOf]="opportunity.interests | sort:'name':true">
                            <div class="chip chip-green mb-small">
                                {{i?.name ?? i }}
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>

        <div class="video  mb-small">
            <app-youtube-video [width]="320" *ngIf="opportunity?.youtubeUrl" [url]="opportunity?.youtubeUrl">
            </app-youtube-video>
        </div>
        <div class="rounded-corners" *ngIf="user?.isEmployer && opportunity?.id">

            <div class="flex-columns justify-between">
                <a class="button button-link btn-fill btn-blue text"
                    [routerLink]="[ '/employers', employerUrlName, 'opportunity', urlName, 'edit' ]">
                    Edit Opportunity
                </a>
                <button class="btn btn-green" *ngIf="!opportunity.scheduled" (click)="openScheduler()">Schedule
                    Opportunity</button>
            </div>
            <div class="prospects">
                <div class="text heading-4 mb-medium">
                    Total Prospects
                </div>
                <div class="prospect-wrapper"
                    *ngFor="let prospect of prospects | doublesort:'score':false:'created':false">
                    <app-employer-prospect-card (click)="prospectClicked(prospect)" [prospect]="prospect">
                    </app-employer-prospect-card>
                </div>
            </div>
        </div>

    </div>
    <div class="bg-teal pl-medium pr-medium py-large">
        <div class="large text text-white mt-auto mb-small">
            {{employer?.name}}
        </div>
        <div class="address text text-white mb-auto">
            {{employer?.primaryAddress}}
        </div>

        <button class="button lime-green mb-medium" [routerLink]="['/employers', opportunity?.employerUrlName]">
            Company Profile
        </button>
        <div class="spacer spacer-white"></div>

        <div class="flex-rows mt-medium">
            <div class="start-date text text-white">
                <div class="text large">Start Date:</div>
                {{ formatStartDate(opportunity?.startDate) }}
            </div>

            <div *ngIf="opportunity?.streetAddress" class="start-date text text-white">
                <div class="text large">Location:</div>
                {{ opportunity?.streetAddress }}
                <br>
                {{opportunity?.employerLocation}}
            </div>

            <div *ngIf="opportunity?.weeklySchedule" class="start-date text text-white">
                <div class="text large">Schedule:</div>
                {{ opportunity?.weeklySchedule }}
            </div>
        </div>

    </div>
</div>

<app-modal *ngIf="opportunity && studentOpportunity && user?.student && employer" id="apply-for-opportunity"
    [heading]="'Apply for ' + opportunity?.title" size="medium">
    <app-student-apply-documents [opportunity]="opportunity" [user]="user" [studentOpportunity]="studentOpportunity"
        [student]="user?.student" [employer]="employer" (applied)="apply($event, 'apply-for-opportunity')"
        (cancelled)="cancelApplication('apply-for-opportunity')">
    </app-student-apply-documents>
</app-modal>

<app-modal [heading]="'Schedule ' + opportunity?.title" size="medium" id="schedule-opportunity"
    *ngIf="user?.isEmployer">
    <app-schedule-opportunity [opportunity]="opportunity"></app-schedule-opportunity>
</app-modal>