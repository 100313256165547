import { Component, OnInit } from '@angular/core';
import { FAQGroup } from '../../shared/models/faq.model';
import { FAQService } from 'src/app/shared/services/faq.service';
import { CDNService } from 'src/app/shared/services/cdn.service';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FaqsComponent implements OnInit {

  public selectedGroup = 'Employers';
  public groups: FAQGroup[] = [];

  constructor(
    private faqs: FAQService,
    public cdn: CDNService
    ) {
  }

  async ngOnInit(): Promise<void> {
    this.groups = await this.faqs.get();
    this.groups = this.groups.filter(f => f.Name !== 'Schools');
  }

}
