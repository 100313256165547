import { Component, Input, OnInit } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { GTMComponentComponent } from 'src/app/shared/components/gtmcomponent/gtmcomponent.component';
import { EmployerService } from 'src/app/shared/services';
import { CDNService } from 'src/app/shared/services/cdn.service';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent extends GTMComponentComponent implements OnInit {

  @Input()
  title = 'Our Current Partners';
  @Input()
  showTitle = true;

  @Input()
  showSignup = true;

  @Input()
  onlyFeatured = false;

  public partners: any[] = [];

  constructor(
    private employers: EmployerService,
    public cdn: CDNService,
    gtm: GoogleTagManagerService,
    snackbar: ToastService
  ) {
    super(gtm, snackbar);
  }

  async ngOnInit(): Promise<void> {
    await this.executeAsync(async () => {
        let pull = await this.employers.getPartners();
        if (this.onlyFeatured) {
          pull = pull.filter(e => e.featured);
        }
  
        this.partners = pull.map(e => {
          return {
            name: e.name,
            city: e.primaryAddress,
            url: e.smallLogoUrl || e.logoUrl,
            route: `/employers/${e.urlName}`
          };
        });
  
    }, 'Error initializing partners');
  }

}
