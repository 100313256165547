import { CanComponentDeactivate, CanDeactivateType } from "../../guard/canDeactivate.guard";
import { ToastService } from "../../services/toast.service";

export abstract class GenericComponent implements CanComponentDeactivate  {
 
    constructor(protected snackbar: ToastService ) {
    }

    canDeactivate(): CanDeactivateType { 
        if (this.hasUnsavedChanges) {
            return window.confirm('Do you want to leave and lose your changes?');
          } else {
            return true;
          }
    }

    public working = 0;
    protected startWorking(): void {
        this.working = 1;
    }
    protected setWorking(value: number): void {
        this.working = value;
    }
    protected endWorking(): void {
        this.working = 0;
    }

    hasUnsavedChanges = false;
    public hasChanges(): boolean { 
        return this.hasUnsavedChanges; 
    }

    protected postExecute(): void { } 

    protected execute<T>(callback: () => T , errorMessage: string): T | undefined {
        let retVal: T | undefined = undefined;
        try {
            this.startWorking();
            retVal = callback();
            this.postExecute(); 
        } catch (error: any) {
            this.snackbar.error({
                title: 'An error has occurred',
                message: errorMessage ?? error?.error ?? error
            });
        } finally {
            this.endWorking();
        }
        return retVal;
    }

    protected async executeAsync<T>(callback: () => Promise<T>, errorMessage: string): Promise<T | undefined> {
        let retVal: T | undefined = undefined;
        try {
            this.startWorking();
            retVal = await callback();
            this.postExecute(); 
        } catch (error: any) {
            this.snackbar.error({
                title: 'An error has occurred',
                message: errorMessage ?? error?.error ?? error
            });
        } finally {
            this.endWorking();
        }
        return retVal;
    }
}
