import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ContactUsService } from 'src/app/shared/services/contactUs.service';
import { Subscription } from 'rxjs';
import { TableOfContents } from 'src/app/shared/models';
import { ToastService } from 'src/app/shared/services/toast.service';
import { GenericComponent } from 'src/app/shared/components/generic-component/generic.component';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent extends GenericComponent implements OnInit {
  public formGroup = new FormGroup({});
  private subscriptions: Subscription[] = [];
  public links: TableOfContents[] = [];
  minMsgLength = 20;

  constructor(
    private contactUsService: ContactUsService,
    private toastService: ToastService,
    ) {
      super(toastService);
     }

  ngOnInit(): void {
    this.formGroup.addControl('email', new FormControl('', [Validators.required, Validators.email]));
    this.formGroup.addControl('name', new FormControl('', [Validators.required]));
    this.formGroup.addControl('message', new FormControl('', [Validators.required, Validators.minLength(this.minMsgLength)]));

    this.links = [
      {
        heading: 'Facebook',
        contents: 'Facebook',
        href: 'https://www.facebook.com/unextcareers',
        external: true,
        scroll: false
      }, {
        heading: 'Snapchat',
        contents: 'Snapchat',
        href: 'https://www.snapchat.com/add/unext.careers',
        external: true,
        scroll: false
      }, {
        heading: 'Instagram',
        contents: 'Instagram',
        href: 'https://www.instagram.com/unextopportunity/?hl=en',
        external: true,
        scroll: false
      }, {
        heading: 'LinkedIn',
        contents: 'LinkedIn',
        href: 'https://www.linkedin.com/company/unext/?viewAsMember=true',
        external: true,
        scroll: false
      },
    ];
  }

  async onSave(): Promise<void> {
    await this.executeAsync(async () => {
      await this.contactUsService.post({
        name: this.name.value,
        email: this.email.value,
        message: this.message.value,
      });
      this.toastService.success({
        message: 'Contact Form Submitted!',
      });

      this.formGroup.reset();
    }, 'There was a problem. Please try again later');
  }

  get email() { return this.formGroup.get('email'); }

  get name() { return this.formGroup.get('name'); }

  get message() { return this.formGroup.get('message'); }
}
