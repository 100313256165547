import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Student, User } from 'src/app/shared/models';
import { StudentService, UserService } from 'src/app/shared/services';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-student-calendar',
  templateUrl: './student-calendar.component.html',
  styleUrls: ['./student-calendar.component.scss']
})
export class StudentCalendarComponent implements OnInit {

  @Input()
  user: User;

  @Input()
  includeBorder = true

  @Input()
  includeTitle = true

  @Input()
  isEdit = true;

  student: Student;

  availability: Availability;
  isWorking = false;

  constructor(
    private users: UserService,
    private students: StudentService,
    private router: Router,
    private snackbar: ToastService,
  ) {
    if (!this.user) {
      this.user = this.users.authenticatedUser;
    }
   }

  ngOnInit(): void {
    this.loadCalendar();
  }

  async loadCalendar() {
    if (this.user.isStudent) {
      try {
        this.isWorking = true;
        this.student = await this.students.getStudent(this.user.student.urlName);
        this.availability = this.student.availability;
        this.isWorking = false;
      } catch (err) {
        console.error(err);
      }
    }
  }

  async toggle(day: string, on: boolean): Promise<void> {
    switch (day) {
      case 'sunday':
        this.availability.sunday = on;
        break;
      case 'monday':
        this.availability.monday = on;
        break;
      case 'tuesday':
        this.availability.tuesday = on;
        break;
      case 'wednesday':
        this.availability.wednesday = on;
        break;
      case 'thursday':
        this.availability.thursday = on;
        break;
      case 'friday':
        this.availability.friday = on;
        break;
      case 'saturday':
        this.availability.saturday = on;
        break;
    }
    await this.updateSchedule();
  }

  // discard(): void {
  //   this.router.navigate(['/my-profile']);
  // }
  async updateSchedule(): Promise<void> {
    await this.students.updateCalendar(this.student.urlName, this.availability);
    this.snackbar.success({
      message: 'Your schedule has been updated'
    });
  }
}

class Availability {
  public sunday: boolean;
  public monday: boolean;
  public tuesday: boolean;
  public wednesday: boolean;
  public thursday: boolean;
  public friday: boolean;
  public saturday: boolean;
}
