import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Employer } from 'src/app/shared/models/employer.model';
import { Opportunity, OpportunityType } from 'src/app/shared/models/opportunity.model';
import { User } from 'src/app/shared/models/user.model';
import { Location } from 'src/app/shared/models/location.model';
import { ModalService, StudentService, UserService } from 'src/app/shared/services';
import { EmployerService } from 'src/app/shared/services/employer.service';
import { OpportunityService } from 'src/app/shared/services/opportunity.service';
import { Student, StudentOpportunity, StudentOpportunityApplication } from 'src/app/shared/models';
import { ToastService } from 'src/app/shared/services/toast.service';
import { CDNService } from 'src/app/shared/services/cdn.service';
import { DatePipe } from '@angular/common';
import { GTMComponentComponent } from 'src/app/shared/components/gtmcomponent/gtmcomponent.component';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { AuthService } from '@auth0/auth0-angular';
import { Title } from '@angular/platform-browser';
import { CalendarService } from 'src/app/shared/services/calendar.service';

@Component({
  selector: 'app-employer-opportunity',
  templateUrl: './employer-opportunity.component.html',
  styleUrls: ['./employer-opportunity.component.scss'],
  providers: [DatePipe]
})
export class EmployerOpportunityComponent extends GTMComponentComponent implements OnInit {

  @Input()
  opportunity: Opportunity;

  @Input()
  nested = false;

  user: User;
  studentOpportunity: StudentOpportunity;
  employer: Employer;
  isMyEmployer = false;
  urlName: string;
  employerUrlName: string;
  loadingApplicants = false;
  isEdit = false;
  bestTimes: any[] = [];

  opportunityTypeClassName = 'other';

  isFavorite = false;
  public daysPosted: number;
  public daysLeft: number;
  public shareBtnColor = 'green';
  public buttonText = 'Share This Opportunity'

  prospects: StudentOpportunity[];

  constructor(
    private route: ActivatedRoute,
    private opportunities: OpportunityService,
    private employers: EmployerService,
    private students: StudentService,
    private users: UserService,
    snackbar: ToastService,
    public cdn: CDNService,
    private router: Router,
    private datepipe: DatePipe,
    gtm: GoogleTagManagerService,
    private auth0: AuthService,
    private titleService: Title,
    private modals: ModalService,
    private calendar: CalendarService,
  ) {
    super(gtm, snackbar);
    this.user = this.users.authenticatedUser;
  }
  async ngOnInit(): Promise<void> {

    await this.executeAsync(async () => {
      this.urlName = this.route.snapshot.params.urlName;
      this.employerUrlName = this.route.snapshot.params.employerUrlName;
      this.isMyEmployer = this.employerUrlName === this.user?.employee?.employerUrlName;
      this.isEdit = this.isMyEmployer && this.route.snapshot.params.edit;

      this.setWorking(10);
      await this.loadOpportunity();
      this.setWorking(20);
      await this.loadEmployer();
      this.setWorking(30);
      console.log(this.opportunity);

      if (this.user?.isStudent) {
        await this.loadStudentOpportunity();
        this.setWorking(40);
        await this.registerImpression();
        this.setWorking(50);
      } else if (this.user?.isEmployer && this.opportunity.id) {
        this.prospects = (await this.opportunities.getOpportunityProspects(this.opportunity.employerUrlName, this.opportunity.id));
        this.setWorking(50);
        this.bestTimes = this.calendar.getBestStudetnOpportunityTimes(this.prospects);

        const availabilityStrings = this.bestTimes.map(a => {
          const capitalizedDay = a.day.charAt(0).toUpperCase() + a.day.slice(1);
          return capitalizedDay + " " + a.times;
        });
        this.opportunity.bestStudentAvailability = availabilityStrings.join(", ");
        this.setWorking(80);
      }

      const now = new Date();
      const start = new Date(this.opportunity.publishStartDate);
      const end = new Date(this.opportunity.publishEndDate);
      this.daysPosted = Math.floor((now.getTime() - start.getTime()) / (1000 * 60 * 60 * 24));
      this.daysLeft = Math.floor((end.getTime() - now.getTime()) / (1000 * 60 * 60 * 24));
      this.titleService.setTitle(`${this.employer.name} - ${this.opportunity.title}`);
    }, 'Error loading oppotunity');
  }

  async loadEmployer() {
    this.employer = await this.employers.getEmployer(this.employerUrlName);
  }

  async loadOpportunity() {
    if (!this.opportunity) {
      this.opportunity = await this.opportunities.getOpportunity(this.urlName);
    }
  }
  async loadStudentOpportunity(): Promise<void> {
    this.studentOpportunity = await this.students.getOpportunity(this.user.student.urlName, this.opportunity.id);

    this.opportunity.applied = this.studentOpportunity.applied;
  }
  async registerImpression(): Promise<void> {
    await this.opportunities.tagOpportunity(this.urlName, this.user.student.urlName);
  }

  addressText(l: Location): string {
    if (l) {
      return `${l.address.city.name} ${l.address.city.state.name}`;
    } else {
      return 'Remote';
    }
  }

  async toggleFavorite(e: Event): Promise<boolean> {
    e.preventDefault();
    return await this.execute(async ()  => {
      this.opportunity.favorite = !this.opportunity.favorite;
      this.opportunity.favorite = await this.students.favorite(this.user.student.urlName, this.opportunity.id);
      return false;  
    }, 'Error toggling favorite');
  }

  open(modalName: string): void {
    this.modals.open(modalName);
  }

  cancel(modalName: string) {
    this.modals.close(modalName);
  }

  async apply(application: StudentOpportunityApplication, modalName: string): Promise<void> {
    await this.executeAsync(async () => {
      this.modals.close(modalName);
      this.setWorking(40);
      this.studentOpportunity.applied = await this.opportunities.apply(this.studentOpportunity.id, application);
      this.setWorking(100);
      this.snackbar.success({
        message: `You have applied for ${this.opportunity.title}.`
      });

    }, 'There was a problem applying to this opportunity, please try again.');
  }

  getPayType(payTypeId) {
    return [{ value: 0, label: 'Once' },
    { value: 1, label: 'Hourly' },
    { value: 2, label: 'Daily' },
    { value: 3, label: 'Weekly' },
    { value: 4, label: 'Monthly' },
    { value: 5, label: 'Commission' },
    { value: 6, label: 'Salary' }].find(x => x.value == payTypeId)
  }

  prospectClicked(prospect: Student) {
    this.router.navigate(['/my-prospects'], { queryParams: { opportunity: this.opportunity.id, schoolName: prospect.schoolName, search: `${prospect.user.firstName} ${prospect.user.lastName}` } })
  }

  chipColor(): string {
    let color: string;

    switch (this.opportunity?.opportunityTypeId) {
      case OpportunityType.Internship:
        color = 'orange';
        break;
      case OpportunityType.Employment:
        color = 'purple';
        break;
      case OpportunityType.Volunteering:
        color = 'green';
        break;
      case OpportunityType.Other:
        color = 'gray';
        break;
      case OpportunityType.CoOp:
        color = 'green-muted';
        break;
      case OpportunityType.JobShadowing:
      default:
        color = 'blue';
        break;
    }

    return color;
  }

  formatStartDate(value: any): any {
    if (value) {
      const now = new Date();
      if (value instanceof Date) {
        const daysLeft = Math.floor((value.getTime() - now.getTime()) / (1000 * 60 * 60 * 24));
        return daysLeft < 1 ? 'As soon as possible' : this.datepipe.transform(value);
      } else if (typeof value === 'string') {
        const date = new Date(value as string);
        const daysLeft = Math.floor((date.getTime() - now.getTime()) / (1000 * 60 * 60 * 24));
        return daysLeft < 1 ? 'As soon as possible' : this.datepipe.transform(date);
      } else {
        return 'TBD';
      }
    } else {
      return 'TBD';
    }
  }

  async login(): Promise<void> {
    this.handle('login', window.location.href, 'click', 'Sign Up');

    await this.auth0.loginWithRedirect({
      appState: { target: window.location.href },
      authorizationParams: {
        screen_hint: 'signUp'
      }
    });
  }

  cancelApplication(modal: string): void {
    this.snackbar.warning({
      message: `You cancelled out of applying for ${this.opportunity.title}`
    });
    this.modals.close(modal);
  }

  openScheduler() {
    this.modals.open('schedule-opportunity');
  }

}
