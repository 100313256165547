import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { DropdownComponent, DropDownOptions } from 'src/app/shared/components/dropdown/dropdown.component';
import { User, Employer, Opportunity } from 'src/app/shared/models';
import { EmployerService, OpportunityService, UserService } from 'src/app/shared/services';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-employer-existing-opportunities',
  templateUrl: 'employer-existing-opportunities.component.html',
  styleUrls: ['employer-existing-opportunities.component.scss']
})
export class EmployerExistingOpportunitiesComponent implements OnInit {
  @ViewChild('ddIndustry', { static: false })
  ddIndustry: DropdownComponent;
  @ViewChild('ddEmployment', { static: false })
  ddEmployment: DropdownComponent;
  @ViewChild('ddLocation', { static: false })
  ddLocation: DropdownComponent;

  formGroup = new FormGroup({
    filterText: new FormControl('')
  });

  @Input()
  urlName: string;

  @Input()
  showInactive = false;
  @Input()
  showUnpublished = false;

  id: number;
  employer: Employer
  user: User;
  isMyEmployer = false;
  loading = false;

  searchResults: Opportunity[] = undefined;

  searchResultsInProgress: Opportunity[] = [];
  searchResultsPending: Opportunity[] = [];
  searchResultsActive: Opportunity[] = [];
  searchResultsExpired: Opportunity[] = [];
  filter = 'none';
  sort = 'none';
  max: number;
  selectedIndustry: string;
  selectedEmployment: number;
  selectedLocation: string;
  searching = false;

  industries: DropDownOptions[] = [];
  employmentTypes: DropDownOptions[] = [];
  locations: DropDownOptions[] = [];

  showExpired = false;
  showInProgress = false;
  showPending = true;
  showActive = true;


  constructor(
    route: ActivatedRoute,
    private opportunities: OpportunityService,
    private employers: EmployerService,
    private users: UserService,
    private snackbar: ToastService,
  ) {
    if (route.snapshot.params.urlName !== null && route.snapshot.params.urlName !== undefined) {
      this.urlName = route.snapshot.params.urlName;
    }

    this.user = this.users.authenticatedUser;
  }

  async ngOnInit() {
    this.isMyEmployer = this.user.isEmployer && this.user.employee?.employerUrlName === this.urlName;
    await this.loadEmployer();
    await this.loadOpportunities();
    await this.loadIndustries();
    await this.loadLocations();
    await this.loadEmploymentTypes();
  }

  async loadEmployer(): Promise<void> {
    try {
      this.loading = true;
      this.employer = await this.employers.getEmployer(this.urlName);
      this.id = this.employer.id;
      this.isMyEmployer = this.user?.isEmployer && this.user?.employee?.employerUrlName === this.urlName;
    } catch (error) {
      this.snackbar.error({
        message: 'There was an error getting your opportunities',
        title: 'An error occurred'
      });
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  async loadOpportunities(): Promise<void> {
    const now = new Date();
    try {
      this.loading = true;
      this.searchResults = [];
      this.searchResults = await this.opportunities.getEmployerOpportunities(
      this.urlName,
      this.showInactive,
      this.showUnpublished,
      'PublishStartDate',
      null,
      this.formGroup.get('filterText').value,
      this.selectedIndustry,
      this.selectedLocation,
      this.selectedEmployment);
      this.searchResultsActive = this.searchResults.filter(sr => sr.active && new Date(sr.publishStartDate) < now && new Date(sr.publishEndDate) > now);
      this.searchResultsInProgress = this.searchResults.filter(sr => !sr.active);
      this.searchResultsPending = this.searchResults.filter(sr => sr.active && new Date(sr.publishStartDate) > now);
      this.searchResultsExpired = this.searchResults.filter(sr => sr.active && new Date(sr.publishStartDate) < now && new Date(sr.publishEndDate) < now);
    } catch (error) {
      this.snackbar.error({
        message: 'There was an error getting your opportunities',
        title: 'An error occurred'
      });
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  opportunityAdded(event: Opportunity): void {
    this.searchResults.push(event);
  }

  clearSearch(): void {
    this.searchResults = null;
    this.selectedIndustry = null;
    this.ddIndustry.value = null;
    this.selectedEmployment = null;
    this.ddEmployment.value = null;
    this.selectedLocation = null;
    this.ddLocation.value = null;
    this.formGroup.get('filterText').setValue('');
    this.searching = false;
  }

  async loadEmploymentTypes() {
    this.employmentTypes = [];
    (await this.opportunities.getEmploymentTypes())
      .map(e => {
        this.employmentTypes.push({
          label: e.value,
          value: e.id
        });
      });
  }

  async loadIndustries() {
    this.industries = [];
    this.employer.industries.forEach(i => {
      if (!this.industries.find(ind => ind.value === i)) {
        this.industries.push({
          label: i,
          value: i
        });
      }
    });
  }
  async loadLocations() {
    this.locations = [];
    this.employer.addresses.forEach(value => {
      this.locations.push({
        label: value,
        value: value
      });
    });
  }

  selectIndustry(val: DropDownOptions): void {
    this.searching = true;
    this.selectedIndustry = val.value;
  }
  selectEmployment(val: DropDownOptions): void {
    this.searching = true;
    this.selectedEmployment = val.value;
  }
  selectLocation(val: DropDownOptions): void {
    this.searching = true;
    this.selectedLocation = val.value;
  }
  updateFilter(): void {
    this.searching = true;
  }

  async search(): Promise<void> {
    this.loading = true;
    try {

      await this.loadOpportunities();
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  active(o: Opportunity): boolean {
    const now = new Date();
    return o && new Date(o.publishStartDate) < now && new Date(o.publishEndDate) > now;
  }
  expiring(o: Opportunity): boolean {
    const expiringSoon = moment(new Date()).add(10, 'day').toDate();
    return o && new Date(o.publishEndDate) < expiringSoon;
  }
}