<header>
  <app-top-nav (logInStateChange)="onLogin($event)" [isExpanded]="isExpanded" (navToggle)="isExpanded = $event" [user]="user"></app-top-nav>
</header>

<main class="main-content">
  <div class="zoom-warning text text-500 text-center flex-columns" *ngIf="highZoom && !hideZoom">
    <span class="ml-auto">Your browser zoom setting is &nbsp;</span><span class="text">({{ zoomLevel | percent
      }})</span>&nbsp;is&nbsp;<span class="text" *ngIf="extremeZoom">very</span>&nbsp;high will affect how this
    site is rendered.
    <a class="ml-auto text text-white" style="text-decoration: none; font-size: 32px;"
      (click)="hideZoom=true">X</a>
  </div>
  <router-outlet></router-outlet>
  <app-modal id="student-profile-progress">
    <h1 class="mb-small text normal">
      You have to add more information to your profile to access this information.
    </h1>
    <app-progress-bar [value]="user?.student?.profileScore" color="green" [showBackground]="false"></app-progress-bar>
    <div class="flex-columns mt-small">
      <a [routerLink]="['/my-profile']" (click)="closeModal('student-profile-progress')"
        class="button orange outline text">
        Edit Profile
      </a>
    </div>
  </app-modal>
  <app-modal id="employer-profile-subscription">
    <h1>
      A subscription is required to view this page.
    </h1>
    <p>You need an active subscription to view this page.</p>

    <div class="text heading-4 mb-small">
      Subscription
    </div>

    <button type="button" class="button dark-green text text-white mb-medium"
      (click)="closeModal('employer-profile-subscription');openModal('subscribe-now')">
      Update Subscription
    </button>

    <div class="flex-columns">
      <a [routerLink]="['/employers', user?.employee?.employer?.urlName, 'edit']" (click)="closeModal('employer-profile-subscription')"
        class="button orange outline text">
        Go to Company Profile
      </a>
    </div>
  </app-modal>
  <app-modal id="employer-verification-progress">
    <h1>
      We're still working on verifying your account. You will get confirmation that you are set up once we are done.
    </h1>
  </app-modal>
  <app-modal id="employer-verification-active">
    <h1>
      Your account is inactive, please contact Unext to get reactivated.
    </h1>
  </app-modal>
  <app-modal id="subscribe-now">
    <app-stripe [employer]="user?.employee?.employer" (subscribed)="subscribeNow(true, 'subscribe-now')"
      (cancelled)="subscribeNow(false, 'subscribe-now')" (closed)="closeModal('subscribe-now')">

    </app-stripe>
  </app-modal>
  <app-modal id="user-is-employer">
    <h1>
      You must be using an employer account to see this content.
    </h1>
  </app-modal>
  <app-modal id="user-is-student">
    <h1>
      You must be using an student account to see this content.
    </h1>
  </app-modal>
  <app-modal id="user-is-staff">
    <h1>
      You must be using an staff account to see this content.
    </h1>
  </app-modal>
  <app-modal id="user-is-admin">
    <h1>
      You must be using an admin account to see this content.
    </h1>
  </app-modal>

</main>
<footer>
  <app-footer [user]="user"></app-footer>
</footer>