import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DropDownOptions } from 'src/app/shared/components/dropdown/dropdown.component';
import { GenericComponent } from 'src/app/shared/components/generic-component/generic.component';
import { AnalyticsModel, Section, SectionType, SectionWord, Student, UNextAward, UNextEducation, UNextExperience, UNextOrganization, UNextResume, UNextResumeSection } from 'src/app/shared/models';
import { ArrayMatchSortPipe } from 'src/app/shared/pipes';
import { ModalService, ResumeService, SkillService } from 'src/app/shared/services';
import { NotableWord, SmartWordAnalyzer, WordType } from 'src/app/shared/services/smartwords.service';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-base-section',
  templateUrl: './base-section.component.html',
  styleUrl: './base-section.component.scss'
})
export abstract class BaseSectionComponent extends GenericComponent implements OnInit, OnDestroy, AfterViewInit {

  SmartWordAnalyzer = SmartWordAnalyzer;

  parent: any;

  student: Student;

  protected _resume: UNextResume;

  get resume(): UNextResume {
    return this._resume;
  }
  set resume(value: UNextResume) {
    if (!this._resume) {
      this._resume = value;
      this.updateForm();
    } else {
      this._resume = value;
    }
  }

  protected _section: Section;
  public get section(): Section {
    return this._section;
  }
  public set section(value: Section) {
    if (this._section?.id !== value?.id) {
      this._section = value;
      if (this.resumeSectionId) {
        this.currentResumeSection = this._section.resumeSections.find(x => x.resumeSectionId === this.resumeSectionId);
      }
      this.validateDescription = this.section?.descriptionRequired;
      // this.words = value?.words ?? [];
      setTimeout(async () => {
        this.words = await this.executeAsync<SectionWord[]>(async () => {
          return this.section?.id ? await this.resumes.getSectionWords(this.section.id) : [];
        }, 'Error loading smart words');
        this.hasUnsavedChanges = false;
      }, 0);
      this.updateForm();
    }
  }

  protected _currentResumeSection: UNextResumeSection;
  public get currentResumeSection(): UNextResumeSection {
    return this._currentResumeSection;
  }
  public set currentResumeSection(value: UNextResumeSection) {
    if (value?.resumeSectionId !== this._currentResumeSection?.resumeSectionId) {
      this._currentResumeSection = value;
      this.updateForm();
    }
  }

  validateStartDate = true;
  validateEndDate = true;
  validateDescription = true;
  minDescriptionLength = 20;
  maxDescriptionLength = 100;
  showSkills = false;
  showLanguages = false;

  resumeSectionId = 0;
  public sectionName = '';
  public sectionForm: FormGroup;

  allSkills: string[];
  allLanguages: string[];
  availableSkills: DropDownOptions[];

  analytics: AnalyticsModel = {
    strongWords: [],
    weakWords: [],
    actionWords: [],
    metricWords: [],
    mixedTense: [],
    repitition: [],
    actionFirst: [],
    required: [],
    skills: [],
    counts: {
      actions: 0, metrics: 0, strongWords: 0, weakWords: 0, mixedTense: 0, repitition: 0, actionFirst: 0, required: 0, skills: 0
    }
  };

  words: SectionWord[] = [];
  subscriptions: Subscription[] = [];
  protected forcePresentTense: boolean | undefined = undefined;
  protected formSetup = false;

  constructor(
    private route: ActivatedRoute,
    protected fb: FormBuilder,
    protected modals: ModalService,
    protected resumes: ResumeService,
    private skillService: SkillService,
    protected snackbar: ToastService,
    protected router: Router
  ) {
    super(snackbar);

    this.subscriptions.push(this.route.params.subscribe((params) => {

      if (params.resumeSectionId) {
        if (!isNaN(params.resumeSectionId)) {
          this.resumeSectionId = parseInt(params.resumeSectionId, 10);
        } else {
          this.resumeSectionId = 0;
        }
      } else {
        this.resumeSectionId = 0;
        this.currentResumeSection = {} as UNextResumeSection;
      }
      this.sectionName = this.route.snapshot.url[0].path;
      this.currentResumeSection = this.section?.resumeSections.find(x => x.resumeSectionId === this.resumeSectionId);
      this.updateForm();
    }));

    // this.words = this.section?.words ?? [];
  }

  async ngOnInit(): Promise<void> {
    if (this.showLanguages) {
      await this.loadLanguages();
    }
    if (this.showSkills) {
      await this.loadSkills();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(
      this.sectionForm.valueChanges.subscribe((element) => {
        console.log(element);
        this.hasUnsavedChanges = this.sectionForm.dirty;
      }));
  }

  protected setupForm(): void {
    this.sectionForm = this.fb.group({
      startDate: new FormControl(this._currentResumeSection?.startDate ?? new Date()),
      endDate: new FormControl(this._currentResumeSection?.endDate),
      description: new FormControl(this._currentResumeSection?.description ?? this._section?.defaultDescription),
      current: new FormControl(this.forcePresentTense === undefined ? !this._currentResumeSection?.endDate : this.forcePresentTense),
      id: new FormControl(undefined)
    });

    if (this.validateStartDate) {
      this.sectionForm.get('startDate').addValidators([Validators.required]);
    }
    if (this.validateEndDate) {
      this.sectionForm.get('endDate').addValidators([Validators.required]);
    }
    if (this.validateDescription) {
      this.sectionForm.get('description').addValidators([Validators.required]);
    }
    if (this.minDescriptionLength) {
      this.sectionForm.get('description').addValidators([Validators.minLength(this.minDescriptionLength)]);
    }
    if (this.maxDescriptionLength) {
      this.sectionForm.get('description').addValidators([Validators.maxLength(this.maxDescriptionLength)]);
    }

    this.sectionForm.updateValueAndValidity();
    this.sectionForm.markAsPristine();
    this.hasUnsavedChanges = false;
  }

  // vc: Subscription;

  protected async updateForm(): Promise<void> {
    if (this.sectionForm) {
      this.sectionForm.get('id').setValue(this._currentResumeSection?.id);
      if (this.sectionForm.get('startDate')) {
        this.sectionForm.get('startDate').setValue(this._currentResumeSection?.startDate);
      }
      if (this.sectionForm.get('endDate')) {
        this.sectionForm.get('endDate').setValue(this._currentResumeSection?.endDate);
      }
      if (this.sectionForm.get('description')) {
        this.sectionForm.get('description')?.setValue(this._currentResumeSection?.description ?? this._section?.defaultDescription);
        if (this.validateDescription) {
          this.sectionForm.get('description').addValidators([Validators.required]);
        } else {
          this.sectionForm.get('description').removeValidators([Validators.required]);
        }
        if (this.minDescriptionLength) {
          this.sectionForm.get('description').addValidators([Validators.minLength(this.minDescriptionLength)]);
        } else {
          this.sectionForm.get('description').removeValidators([Validators.minLength(this.minDescriptionLength)]);
        }
        if (this.maxDescriptionLength) {
          this.sectionForm.get('description').addValidators([Validators.maxLength(this.maxDescriptionLength)]);
        } else {
          this.sectionForm.get('description').removeValidators([Validators.maxLength(this.maxDescriptionLength)]);
        }
      }
      if (this.sectionForm.get('current')) {
        this.sectionForm.get('current')?.setValue(this.forcePresentTense === undefined ? !this._currentResumeSection?.endDate : this.forcePresentTense);
      }
      if (this.sectionForm.get('current')?.value) {
        this.sectionForm.get('endDate')?.removeValidators(Validators.required);
        this.sectionForm.get('endDate')?.disable();
      } else if (this.validateEndDate) {
        this.sectionForm.get('endDate')?.addValidators(Validators.required);
        this.sectionForm.get('endDate')?.enable();
      }

      this.analytics.skills = this._currentResumeSection?.skills ?? [];
      this.parent.addAnalytics(this._section, this._currentResumeSection, this.analytics);
      this.updateWordList(undefined);

      setTimeout(() => {
        this.sectionForm.markAsPristine();
        this.sectionForm.markAsTouched();
        this.sectionForm.updateValueAndValidity();
        this.hasUnsavedChanges = false;
      }, 100);
    }
  }

  get id(): FormControl {
    return this.sectionForm?.get('id') as FormControl;
  }

  get description(): FormControl {
    return this.sectionForm?.get('description') as FormControl;

  }
  get endDate(): FormControl | undefined {
    return this.sectionForm?.get('endDate') as FormControl;
  }

  get current(): boolean {
    return this.sectionForm?.get('current').value;
  }

  toggleCurrent(): void {
    setTimeout(() => {
      if (this.current) {
        this.endDate.setValue(null);
        this.endDate.disable();
        this.endDate.clearValidators();
      } else {
        this.endDate.enable();
        this.endDate.addValidators(Validators.required);
      }
    }, 100);
  }

  descriptionChange($event): void {
    const html = /(<([^>]+)>)/ig;
    const closeP = /<p[^>]*>/g;
    $event = $event?.replace(closeP, '</p>\n').replace(html, '') ?? $event;
    if ($event !== this.description.value) {
      this.description.setValue($event);
      this.description.markAsTouched();
      this.description.markAsDirty();
      this.hasUnsavedChanges = true;  
    }
  }
  markDescriptionPristine(): void {
    this.description?.markAsPristine();
    this.description?.markAsUntouched();
    this.hasUnsavedChanges = false;
  }

  updateWordList(event) {
    this.analytics.strongWords = []
    this.analytics.weakWords = []
    this.analytics.actionWords = [];
    this.analytics.metricWords = [];
    this.analytics.mixedTense = [];
    this.analytics.repitition = [];
    this.analytics.actionFirst = [];
    this.analytics.required = [];
    // this.analytics.skills = []; // skills will just get blanked here, so don't reset on word list update

    event?.forEach((value: NotableWord) => {
      if (value.wordType === WordType.Strong) {
        this.analytics.strongWords.push(value);
      } else if (value.wordType === WordType.Weak) {
        this.analytics.weakWords.push(value);
      } else if (value.wordType === WordType.Action) {
        this.analytics.actionWords.push(value);
      } else if (value.wordType === WordType.Metric) {
        this.analytics.metricWords.push(value);
      } else if (value.wordType === WordType.MixedTense) {
        this.analytics.mixedTense.push(value);
      } else if (value.wordType === WordType.Repetition) {
        this.analytics.repitition.push(value);
      } else if (value.wordType === WordType.ActionNotFirst) {
        this.analytics.actionFirst.push(value);
      } else if (value.wordType === WordType.Required) {
        this.analytics.required.push(value);
      }
    });

    this.analytics.counts = {
      actions: this.analytics.actionWords.length,
      metrics: this.analytics.metricWords.length,
      strongWords: this.analytics.strongWords.length,
      weakWords: this.analytics.weakWords.length,
      mixedTense: this.analytics.mixedTense.length,
      repitition: this.analytics.repitition.length,
      actionFirst: this.analytics.actionFirst.length,
      required: this.analytics.required.length,
      skills: this.analytics.skills.length
    };
    if (this.parent) {
      this.parent.selectedAnalytics = this.analytics;
    }
  }

  protected override postExecute(): void {
    try {
      this.parent.addAnalytics(this.analytics);
      this.hasUnsavedChanges = false;
    } catch (error) {
      console.log(error);
    }
  }

  async saveSection(value: UNextResumeSection): Promise<UNextResumeSection> {
    return await this.executeAsync(async () => {
      value.sectionId = this.section.id;
      value.resumeSectionId = this.resumeSectionId;
      if (value.startDate as any === '') {
        value.startDate = undefined;
      }
      if (value.endDate as any === '') {
        value.endDate = undefined;
      }

      this.setWorking(50);

      switch (this.section.sectionType) {
        case SectionType.General:
          if (value.resumeSectionId) {
            value = await this.resumes.updateResumeSection(this.resume.id, value.resumeSectionId, value);
          } else {
            value = await this.resumes.newResumeSection(this.resume.id, value);
          }
          break;
        case SectionType.Experience:
          if (value.resumeSectionId) {
            value = await this.resumes.updateResumeExperience(this.resume.id, value.resumeSectionId, value as UNextExperience);
          } else {
            value = await this.resumes.newResumeExperience(this.resume.id, value as UNextExperience);
          }
          break;
        case SectionType.Award:
          if (value.resumeSectionId) {
            value = await this.resumes.updateResumeAward(this.resume.id, value.resumeSectionId, value as UNextAward);
          } else {
            value = await this.resumes.newResumeAward(this.resume.id, value as UNextAward);
          }
          break;
        case SectionType.Education:
          if (value.resumeSectionId) {
            value = await this.resumes.updateResumeEducation(this.resume.id, value.resumeSectionId, value as UNextEducation);
          } else {
            value = await this.resumes.newResumeEducation(this.resume.id, value as UNextEducation);
          }
          break;
        case SectionType.Organization:
          if (value.resumeSectionId) {
            value = await this.resumes.updateResumeOrganization(this.resume.id, value.resumeSectionId, value as UNextOrganization);
          } else {
            value = await this.resumes.newResumeOrganization(this.resume.id, value as UNextOrganization);
          }
          break;
        case SectionType.Language:
          alert('not implemented yet');
          break;
        case SectionType.Skills:
          alert('not implemented yet');
          break;
      }
      this.setWorking(75);
      this.hasUnsavedChanges = false;
      this.parent.updateResumeSection(this.section, value);

      setTimeout(() => {
        this.hasUnsavedChanges = false;
      }, 500);
      return value;
    }, 'Error saving section');
  }

  onSearchList(property: string, value: any): void {
    // Create a set from this.allMajors to ensure uniqueness
    const orgSet = new Set(this[property] as string[]);

    // Transform value.items and convert it to a set to ensure uniqueness
    const diffSet = new Set(new ArrayMatchSortPipe().transform(value.items, value.term));

    // Find the intersection of orgSet and diffSet (common elements)
    const intersection = new Set([...orgSet].filter(x => diffSet.has(x)));

    // Remove common elements from orgSet
    orgSet.forEach(item => {
      if (intersection.has(item)) {
        orgSet.delete(item);
      }
    });

    // Combine sets without duplicates
    const combinedSet = new Set([...diffSet, ...orgSet]);

    // Convert the set back to an array
    this[property] = Array.from(combinedSet);
  }


  cancel(): void {
    if (this.canDeactivate()) {
      this.updateForm();
    }
  }

  async delete(): Promise<void> {
    if (this._currentResumeSection?.id) {
      await this.executeAsync(async () => {
        if (confirm(`Are you sure you want to delete this entry?`)) {
          this.setWorking(50);
          await this.resumes.deleteResumeSection(this.resume.id, 'section', this._currentResumeSection.resumeSectionId, this._currentResumeSection.id);
          for (let i = 0; i < this.resume.sections.length; ++i) {
            for (let j = 0; j < this.resume.sections[i].resumeSections.length; ++j) {
              if (this.resume.sections[i].resumeSections[j].resumeSectionId === this._currentResumeSection.resumeSectionId) {
                this.resume.sections[i].resumeSections.splice(j, 1);
              }
            }
          }
          this._currentResumeSection = undefined;
          this.updateForm();

          const urlSplit = this.router.url.split('/');
          this.hasUnsavedChanges = false;
          this.parent.updateCompletedSections();
          this.router.navigate([urlSplit.splice(0, urlSplit.length - 1).join('/')]);
        }
      }, undefined);
    }
  }

  async selectedSkill(val: string): Promise<void> {
    if (val?.length) {
      try {
        // this.working = 50;

        if (!this._currentResumeSection) {
          const rs = await this.resumes.addResumeSkill(this.resume.id, this.section.id, this._currentResumeSection?.resumeSectionId ?? 0, val);
          this._currentResumeSection = rs;
          this.section.resumeSections.push(this._currentResumeSection);
        } else {
          this._currentResumeSection.skills.push(val);
          await this.resumes.addResumeSkill(this.resume.id, this.section.id, this._currentResumeSection?.resumeSectionId ?? 0, val);
        }

        if (!this._currentResumeSection.analytics) {
          this._currentResumeSection.analytics = { actions: 0, metrics: 0, mixedTense: 0, required: 0, repitition: 0, actionFirst: 0, skills: 0, strongWords: 0, weakWords: 0 };
        }
        this._currentResumeSection.analytics.skills = this._currentResumeSection.skills.length;
        this.section.analytics.skills = this._currentResumeSection.skills.length;
        this.analytics.skills = this._currentResumeSection.skills;
        this.updateWordList(undefined);
        this.parent.addAnalytics(this.analytics);

        this.parent.updateResumeSection(this.section, this._currentResumeSection);

      } catch (err) {
        this.snackbar.error({
          message: `There was an error adding ${val}`
        });
      } finally {
        this.working = 0;
      }
    }
  }
  async deselectSkill(e: Event, val: string) {
    e.preventDefault();

    if (val?.length) {
      try {
        // this.working = 50;
        this._currentResumeSection.skills = this._currentResumeSection.skills.filter(rs => rs !== val);
        await this.resumes.removeResumeSkill(this.resume.id, 'skill', this._currentResumeSection.resumeSectionId, val);

        this._currentResumeSection.analytics.skills = this._currentResumeSection.skills.length;

        this.section.analytics.skills = this._currentResumeSection.skills.length;
        this.analytics.skills = this._currentResumeSection.skills;
        this.updateWordList(undefined);
        this.parent.addAnalytics(this.analytics);
        this.parent.updateResumeSection(this.section, this._currentResumeSection);
      } catch (error) {
        console.error(error);
        this.snackbar.error({
          message: `There was a problem removing ${val}`
        });
      } finally {
        this.working = 0;
      }
    }
  }

  async selectedLanguage(val: string): Promise<void> {
    if (val?.length) {
      try {
        // this.working = 50;
        const rs = await this.resumes.addResumeLanguage(this.resume.id, this.section.id, this._currentResumeSection?.resumeSectionId ?? 0, val);
        if (!this._currentResumeSection) {
          this._currentResumeSection = rs;
          this.section.resumeSections.push(this._currentResumeSection);
        } else {
          this._currentResumeSection.languages.push(val);
        }
        this.updateWordList(undefined);
        this.parent.addAnalytics(this.analytics);
        this.parent.updateResumeSection(this.section, this._currentResumeSection);

      } catch (error) {
        this.snackbar.error({
          message: `There was an error adding ${val}`
        });
      } finally {
        this.working = 0;
      }
    }
  }

  async deselectLanguage(val: string): Promise<void> {
    if (val?.length) {
      try {
        // this.working = 50;
        await this.resumes.removeResumeLanguage(this.resume.id, 'language', this._currentResumeSection.resumeSectionId, val);
        this._currentResumeSection.languages = this._currentResumeSection.languages.filter(rs => rs !== val);
        this.updateWordList(undefined);
        this.parent.addAnalytics(this.analytics);
        this.parent.updateResumeSection(this.section, this._currentResumeSection);
      } catch (error) {
        console.error(error);
        this.snackbar.error({
          message: `There was a problem removing ${val}`
        });
      } finally {
        this.working = 0;
      }
    }
  }

  async loadSkills() {
    this.allSkills = (await this.skillService.get()).sort();
  }
  async loadLanguages() {
    this.allLanguages = (await this.resumes.getLanguages());
  }

}

