<div class="confirm">
    <form class="form mb-large" formGroup="formGroup">

        <div class="flex-rows">
            <label for="Greeting" class="form-label">
                Greeting
            </label>
            <textarea name="Greeting" style="height: 100%;"
                class="form-input border border-teal text ng-untouched ng-pristine ng-valid"
                formControlName="Greeting"></textarea>
        </div>
        <div class="flex-columns col grid-halves">
            <div class="flex-rows resumes">
                <label class="form-label text text-black text-label">
                    Select a Unext Resume or Upload
                </label>
                <ng-select formControlName="Resume" class="drop-down drop-down-button-blue"
                    [placeholder]="'Select Resume...'" (change)="selectResume($event)" [items]="resumes">
                </ng-select>

                <app-upload acceptType="resume" [entityType]="entityType.Resume" [entityId]="employer?.id"
                    (filesUploaded)="resumeUploaded($event, 'upload-resume')">
                </app-upload>
                <span *ngIf="resumeUrl?.length" class="chip chip-green">
                    Resume attached...
                </span>
            </div>
            <div class="flex-rows mb-medium">
                <label class="form-label text text-black text-label">Include a Cover Letter?</label>

                <app-upload acceptType="resume" [entityType]="entityType.ApplicationCoverletter"
                    [entityId]="employer?.id" (filesUploaded)="coverletterUploaded($event, 'upload-cover-letter')">
                </app-upload>
                <span *ngIf="coverletter?.length" class="chip chip-green">
                    Coverletter attached...
                </span>
            </div>
        </div>
        <div class="flex-columns mb-medium" *ngIf="hasTranscript">
            <app-toggle-bar class="mr-small" id="includeTranscript" [isOn]="includeTranscript"
                (toggled)="includeTranscript = !includeTranscript" size="medium"></app-toggle-bar>
            <label class="form-label mb-small mt-small" for="includeTranscript">
                Include Transcript Attached to my Profile</label>
        </div>
        <app-progress-bar [value]="loading">
            <div class="flex-columns mt-medium">
                <button type="button" class="button button-slim btn-green mr-small" [disabled]="resumeId === undefined"
                    (click)="apply()">Apply</button>
                <button type="button" class="button button-slim btn-orange" (click)="cancel()">Cancel</button>
            </div>
        </app-progress-bar>
    </form>
</div>

<app-modal id="upload-cover-letter" size="large">
    <h1 class="mt-large mb-small">Select a coverletter for this opportunity.</h1>
    <h1 class="mb-auto">Your coverletter should be specific to the opportunity you are applying to.</h1>
    <app-upload acceptType="resume" [entityType]="entityType.ApplicationCoverletter" [entityId]="studentOpportunity?.id"
        (filesUploaded)="coverletterUploaded($event, 'upload-cover-letter')">
    </app-upload>
</app-modal>