<div *ngIf="studentOpportunity || opportunity"
  class="mb-small profile-card bg-white pl-small pr-small py-small mr-medium" style="min-width: 350px;">
  <div class="text card-title">
    {{ studentOpportunity?.opportunity?.title || opportunity?.title }}
  </div>
  <div class="mt-small flex-columns justify-between">
    <div>
      <div class="flex-columns">

        <div class="tags flex-rows">
          <span class="chip mr-small chip-{{ chipColor(studentOpportunity?.opportunity || opportunity) }}">
            {{ studentOpportunity?.opportunity?.opportunityType || opportunity?.opportunityType }}
          </span>

          <span *ngIf="studentOpportunity?.applied" class="chip chip-yellow mr-small">
            Applied
          </span>
          <span *ngIf="studentOpportunity?.opportunity?.allowRemote || opportunity?.allowRemote"
            class="chip chip-green-muted mr-small">
            Remote
          </span>
        </div>
      </div>
      <div *ngIf="(studentOpportunity?.opportunity?.locations?.length || opportunity?.locations?.length)" class="text">
        <span class="text mr-auto">
          <ng-template ngFor let-l [ngForOf]="studentOpportunity?.opportunity?.locations || opportunity?.locations"
            let-i="index" let-isFirst="first" let-isLast="last">
            {{ i > 0 ? '' : l }}
            {{ i > 0 && isLast ? '& more' : '' }}
          </ng-template>
        </span>
      </div>
      <div class="text" *ngIf="studentOpportunity?.opportunity?.industries || opportunity?.industries">
        <span class="text mr-auto">
          <ng-template ngFor let-l [ngForOf]="studentOpportunity?.opportunity?.industries || opportunity?.industries"
            let-i="index" let-isFirst="first" let-isLast="last">
            {{ i > 0 ? '' : l }}
            {{ i > 0 && isLast ? '& more' : '' }}
          </ng-template>
        </span>
      </div>
      <div class="text" *ngIf="studentOpportunity?.opportunity?.interests || opportunity?.interests">
        <span class="text mr-auto">
          <ng-template ngFor let-l [ngForOf]="studentOpportunity?.opportunity?.interests || opportunity?.interests"
            let-i="index" let-isFirst="first" let-isLast="last">
            {{ i > 0 ? '' : l.name }}
            {{ i > 0 && isLast ? '& more' : '' }}
          </ng-template>
        </span>
      </div>
    </div>
    <img *ngIf="opportunity?.thumbnailUrl ?? opportunity?.employerLogoUrl"
      src="{{ cdn.toCDN(opportunity?.thumbnailUrl ?? opportunity?.employerLogoUrl, true) }}"
      alt="{{opportunity?.title}}" />
  </div>

  <div class="text" *ngIf="studentOpportunity?.appliedDate">
    Applied on {{ studentOpportunity.appliedDate | date }}
  </div>
  <button class="profile btn btn-white mr-small border-teal"
    [routerLink]="['/employers', (studentOpportunity?.opportunity?.employerUrlName || opportunity?.employerUrlName), 'opportunity', (studentOpportunity?.opportunity?.urlName || opportunity?.urlName)]">
    Learn More
  </button>
</div>