<app-progress-bar [splashScreen]="true" [value]="isLoading"></app-progress-bar>
<div #logo *ngIf="employer?.logoUrl" class="banner">
    <div class="banner-inner" [ngStyle]="{ 'background-image': 'url(' + cdn.toCDN(employer?.logoUrl, true) + ')' }">
        <div hidden>
            {{ employer?.name }}
        </div>
    </div>
</div>
<div class="flex-columns no-gap" style="width: -webkit-fill-available;">
    <div class="bg-cream pl-medium pr-medium py-large">
        <div class="mb-medium info">
            <div class="flex-rows ml-large" style="position: relative;">
                <div class="logo-wrapper">
                    <div class="bg-white border-teal mb-small border border-round logo" *ngIf="employer?.smallLogoUrl">
                        <div class="logo-inner"
                            [ngStyle]="{ 'background-image': 'url(' + cdn.toCDN(employer?.smallLogoUrl, true) + ')' }">
                            <span class="sr-only">{{ employer?.name + ' logo' }}</span>
                        </div>
                    </div>
                    <!-- <a class="btn lime-green mb-small" *ngIf="user.isStudent" (click)="openModal('requestOpportunityModal')">Request Opportunity</a> -->
                    <a class="btn lime-green mb-small" *ngIf="user.isStudent" [routerLink]="[ '/opportunity', employer?.urlName, 'request']">Request an Opportunity</a>
                    <a class="btn lime-green mb-small" [routerLink]="[ '/employers', employer?.urlName, 'team']">View
                        Team</a>
                        
                    <div data-intro="Let's update your individual profile." data-title="Edit Profile" data-step="1"
                    *ngIf="user?.isEmployer && user?.employee?.employer?.id === this.employer?.id">
                        <a [routerLink]="[ '/employers', employer?.urlName, 'edit' ]" class="btn white">
                            Edit Profile
                        </a>
                    </div>
                </div>

            </div>
            <div class="flex-rows">
                <div class="text text-title text-right mb-medium">
                    {{ employer?.name}}
                </div>
                <div class="text text-right text-800 mb-small">
                    {{employer?.primaryAddress}}
                </div>
                <div class="text text-right text-800 mb-small">
                    <a href="{{employer?.domain}}">{{employer?.domain}}</a>
                </div>
                <!-- <div class="text text-right text-800 mb-small">
                    <a href="tel:{{employer?.phone}}">{{employer?.phone}}</a>
                </div> -->
                <div class="text text-right text-800 mb-small">
                    <a href="mail:{{employer?.contactEmail}}">{{employer?.contactEmail}}</a>
                </div>
                <!-- <div class="text text-right text-800">
                    {{school?.youtubeUrl}}
                </div> -->
                <div class="text text-right text-800">{{employer?.size}} Employees</div>

            </div>
        </div>

        <div class="mb-medium">
            <div class="text text-title">Description</div>
            <div class="text mb-medium" style="grid-column: 2; grid-row: 1 / span 3;"
                [innerHTML]="employer?.description"></div>

            <div class="text text-title" *ngIf="employer?.benefits?.length">Benefits</div>
            <div class="text mb-medium" style="grid-column: 2; grid-row: 1 / span 3;" *ngIf="employer?.benefits?.length"
                [innerHTML]="employer?.benefits"></div>

            <div class="text text-title" *ngIf="employer?.culture?.length">Culture</div>
            <div class="text" style="grid-column: 2; grid-row: 1 / span 3;" *ngIf="employer?.culture?.length"
                [innerHTML]="employer?.culture"></div>

        </div>
        <div class="spacer spacer-teal"></div>
        <div class="mt-medium">

            <app-filter-menu [vertical]="false" (outputListEvent)="updateOpportunityList($event)"
                [inputList]="opportunityList" [filterDropDownOptions]="opportunityFilterDropDownOptions"
                [displaySearch]="false" [displayHeading]="false">
            </app-filter-menu>

            <div style="max-width: 80vw;">
                <app-list-scroll >
                    
                    <ng-template ngFor let-o [ngForOf]="modifiedOpportunitiesList">
                      
                        <app-student-opportunity-card [opportunity]="o"></app-student-opportunity-card>

                    </ng-template>

                </app-list-scroll>

            </div>
        </div>
    </div>
    <div class="bg-teal pl-medium pr-medium py-large">
        <a class="flex-rows" [routerLink]="[ '/dashboard']">
            <img src="/assets/images/icons/dashboard.svg" style="height: 76px;" class="mx-auto" />
            <div class="text text-white mx-auto text-center mb-medium">
                Dashboard
            </div>
        </a>
        <div class="mb-medium">
            <div class="spacer spacer-white"></div>
        </div>
        <a class="flex-rows" [routerLink]="[ '/employers', employer?.urlName, 'meet']">
            <img src="/assets/images/icons/shake.svg" style="height: 43px;" class="mx-auto" />
            <div class="text text-white mx-auto text-center mb-medium mt-medium">
                Meetings & Feedback
            </div>
        </a>

        <div class="spacer spacer-white"></div>
        <div>
            <div class="text text-white text-900 heading-4 mb-small">
                Locations
            </div>
            <ng-template ngFor let-a [ngForOf]="employer?.addresses">
                <div class="mb-medium ">
                    <div class="text text-white">
                        {{ a}}
                    </div>
                </div>
            </ng-template>
        </div>

    </div>
</div>