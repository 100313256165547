import { Component, OnInit } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { GTMComponentComponent } from '../shared/components/gtmcomponent/gtmcomponent.component';
import { SchoolService, UserService } from '../shared/services';
import { Router } from '@angular/router';
import { ToastService } from '../shared/services/toast.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent extends GTMComponentComponent implements OnInit {

  schools: { name: string, imageUrl: string }[] = [];

  constructor (
    gtm: GoogleTagManagerService,
    private users: UserService,
    private schoolService: SchoolService,
    private router: Router,
    snackbar: ToastService
  ) {
    super(gtm, snackbar);
   }

   async ngOnInit(): Promise<void> {
     if (this.users.authenticatedUser) {
      this.router.navigate(['/dashboard']);
     }

     try {
      this.schools = (await this.schoolService.getSchools()).map((x) => {
        return {
          imageUrl: x.logoUrl,
          name: x.name
        };
       });
     } catch (err) {
      console.error(err);
     }

   }
}
