import { Injectable } from "@angular/core";
import { UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { UserService, ModalService } from "../services";

@Injectable()
export class EmployerGuard  {
    constructor(
        private users: UserService,
        private modals: ModalService) {
    }

    canActivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        if (!this.users.authenticatedUser.isEmployer && ! this.users.authenticatedUser.isAdmin) {
            this.modals.open('user-is-employer');
            return false;
        } else if (this.users.authenticatedUser?.employee && !this.users.authenticatedUser.employee.employer.verified) {
            this.modals.open('employer-verification-progress');
            return false;
        } else if (this.users.authenticatedUser?.employee && !this.users.authenticatedUser.employee.employer.subscribed) {
            this.modals.open('employer-profile-subscription');
            return false;
        } else {
            return true;
        }
    }
}

@Injectable()
export class StudentGuard  {
    constructor(
        private users: UserService,
        private modals: ModalService) {
    }

    canActivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        if (!this.users.authenticatedUser.isStudent && ! this.users.authenticatedUser.isAdmin) {
            this.modals.open('user-is-student');
            return false;
        } else {
            return true;
        }
    }
}

@Injectable()
export class StaffGuard  {
    constructor(
        private users: UserService,
        private modals: ModalService) {
    }

    canActivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        if (!this.users.authenticatedUser.isStaff && ! this.users.authenticatedUser.isAdmin) {
            this.modals.open('user-is-staff');
            return false;
        } else {
            return true;
        }
    }
}

@Injectable()
export class AdminGuard  {
    constructor(
        private users: UserService,
        private modals: ModalService) {
    }

    canActivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        if (!this.users.authenticatedUser.isAdmin) {
            this.modals.open('user-is-admin');
            return false;
        } else {
            return true;
        }
    }
}