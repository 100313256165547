<app-progress-bar [value]="working" [splashScreen]="true"></app-progress-bar>

<div class="analytics-wrapper" *ngIf="section">
    <form [formGroup]="sectionForm">
        <!-- Experience Section -->
        <div class="form edit-section" *ngIf="section?.name === 'Experience'"
        data-intro=" This is the most important section of your resume. See how our system provides live feedback and understands the content and layout."
        data-title="Experience"
        data-step="1">
            <div class="four-col row"
            data-intro="Enter details outside of the description, such as employer, job title, start and end dates, and other relevant filters. This will help employers understand your background better."
            data-title="Experience Fields"
            data-step="2">
                <label for="employer" class="form-label text text-black">
                    Employer Name
                </label>
                <input placeholder="e.g. ACME Packing Company" name="employer" type="text" formControlName="employerName"
                    class="form-input border border-teal text form-input-100" />
            </div>

            <div class="two-col row">
                <label for="location" class="form-label text text-black">
                    Location
                </label>
                <input placeholder="e.g. Green Bay, WI" name="location" type="text" formControlName="location"
                    class="form-input border border-teal text form-input-100" />
            </div>

            <div class="three-col row">
                <label for="title" class="form-label text text-black">
                    Title/Role
                </label>
                <input placeholder="e.g. CEO" name="title" type="text" formControlName="title"
                    class="form-input border border-teal text form-input-100" />
            </div>
            <div class="one-col row">
            </div>
            <div class="two-col row">
                <label for="remote" class="form-label text text-black">
                    Is Remote
                </label>
                <input id="remote" type="checkbox" name="remote" formControlName="remote"
                    class="form-input border border-teal text" />
            </div>

            <div class="two-col row">
                <label for="startDate" class="form-label text text-black">
                    Start Date
                </label>
                <input placeholder="e.g. 2020-01-01" name="startDate" type="date" formControlName="startDate"
                    (change)="endDate.updateValueAndValidity()"
                    class="form-input border border-teal text form-input-100" />
            </div>

            <div class="two-col row">
                <label for="endDate" class="form-label text text-black">
                    End Date
                </label>
                <input placeholder="e.g. 2020-01-01" name="endDate" type="date" formControlName="endDate"
                    class="form-input border border-teal text form-input-100" />
            </div>

            <div class="two-col row">
                <label for="current" class="form-label text text-black">
                    Currently Here
                </label>
                <input id="current" (click)="toggleCurrent()" type="checkbox" name="current" formControlName="current"
                    class="form-input border border-teal text" />
            </div>

        </div>

        <!-- Volunteering -->
        <div *ngIf="section?.name === 'Volunteering'" class="form edit-section">
            <div class="four-col row">
                <label for="employer" class="form-label text text-black">
                    Organization / Employer
                </label>
                <input placeholder="e.g. ACME Packing Company" name="employer" type="text" formControlName="employerName"
                    class="form-input border border-teal text form-input-100" />
            </div>

            <div class="two-col row">
                <label for="location" class="form-label text text-black">
                    Location
                </label>

                <input placeholder="e.g. Green Bay, WI" name="location" type="text" formControlName="location"
                    class="form-input border border-teal text form-input-100" />
            </div>

            <div class="three-col row">
                <label for="title" class="form-label text text-black">
                    Role
                </label>
                <input name="title" type="text" formControlName="title"
                    class="form-input border border-teal text form-input-100" />
            </div>
            <div class="three-col row">
            </div>
            <div class="two-col row">
                <label for="startDate" class="form-label text text-black">
                    Start Date
                </label>
                <input placeholder="e.g. 2020-01-01" name="startDate" type="date" formControlName="startDate"
                    class="form-input border border-teal text form-input-100" />
            </div>
            <div class="two-col row">
                <div class="flex-columns">
                    <label for="endDate" class="form-label text text-black">
                        End Date
                    </label>
                </div>
                <input placeholder="e.g. 2020-01-01" name="endDate" type="date" formControlName="endDate"
                    class="form-input border border-teal text form-input-100 hide-disabled" />
            </div>
            <div class="one-col row">
                <label for="current" class="form-label text text-black ml-auto">
                    Currently Volunteering
                </label>
                <input id="current" (click)="toggleCurrent()" type="checkbox" name="current"
                style="height: auto;" formControlName="current"
                class="form-input text mr-small" />
            </div>
        </div>

        <div data-intro="Enter the details of your experience here. Be deliberate with your word choice, format, and use of action verbs and metrics. We’ll guide you to ensure you provide the best information possible."
                data-title="Experience Description"
                data-step="3"
                class="form edit-section mt-small">
            <div class="six-col">
                <label class="form-label text text-black">
                    Description
                </label>
                <div style="height: 350px;">
                    <app-custom-quill #myCustomQuill *ngIf="!!description"
                        [initialValue]="currentResumeSection?.description ?? section?.defaultDescription ?? ''"
                        placeholder="Enter description here..." (changed)="descriptionChange($event)"
                        (filled)="markDescriptionPristine()"
                        [checkWeakWords]="smartwords.checkAnalytic(section.analyze, SmartWordAnalyzer.weakWords)"
                        [checkStrongWords]="smartwords.checkAnalytic(section.analyze, SmartWordAnalyzer.strongWords)"
                        [checkActionWords]="smartwords.checkAnalytic(section.analyze, SmartWordAnalyzer.actionWords)"
                        [checkMetrics]="smartwords.checkAnalytic(section.analyze, SmartWordAnalyzer.metrics)"
                        [checkTense]="smartwords.checkAnalytic(section.analyze, SmartWordAnalyzer.tense)"
                        [presentTense]="current" (wordListEmitter)="updateWordList($event)" [words]="words">
                    </app-custom-quill>
                </div>
            </div>
        </div>

        <div class="form edit-section mt-large">
            <div class="three-col">
                <div class="mr-auto mb-small" *ngIf="id?.value">
                    <button type="button" (click)="delete()">
                        Delete
                    </button>
                </div>
                <label class="form-warning" *ngIf="sectionForm.touched && sectionForm.controls['employerName']?.errors?.required">
                    Add an Employer
                </label>
                <label class="form-warning" *ngIf="sectionForm.touched && sectionForm.controls['title']?.errors?.required">
                    Add a Title
                </label>
                <label class="form-error" *ngIf="sectionForm.touched && sectionForm.get('startDate')?.hasError('required')">
                    Add a Start Date
                </label>
                <label class="form-error" *ngIf="sectionForm.touched && sectionForm.get('startDate')?.hasError('invalidDate')">
                    Start Date is invalid
                </label>
                <label class="form-warning" *ngIf="sectionForm.touched && sectionForm.controls['endDate']?.errors?.required">
                    Add a End Date
                </label>
                <label class="form-warning" *ngIf="sectionForm.touched && sectionForm.controls['endDate']?.errors?.invalidDate">
                    End Date is invalid
                </label>
                <label class="form-error" *ngIf="sectionForm.touched && sectionForm.controls['endDate']?.errors?.afterDate">
                    Start Date must be before End Date
                </label>
                <label class="form-warning" *ngIf="sectionForm.touched && sectionForm.controls['employerName']?.errors?.required">
                    Add an Organization/Employer
                </label>
                <label class="form-warning" *ngIf="sectionForm.touched && sectionForm.controls['title']?.errors?.required">
                    Add a Role
                </label>
                <label class="form-warning" *ngIf="sectionForm.touched && sectionForm.controls['location']?.errors?.minlength">
                    Location is too short
                </label>
                <label class="form-warning" *ngIf="sectionForm.touched && description?.errors?.required ?? false">
                    Add a Description
                </label>
                <label class="form-error" *ngIf="sectionForm.touched && description?.errors?.minlength ?? false">
                    Description is not long enough ({{ description?.errors?.minlength?.actualLength }} of {{
                    description?.errors?.minlength?.requiredLength }})
                </label>
                <label class="form-error" *ngIf="sectionForm.touched && description?.errors?.maxlength ?? false">
                    Description is too long ({{ description?.errors?.maxlength?.actualLength }} of {{
                    description?.errors?.maxlength?.requiredLength }})
                </label>

            </div>
            <div class="three-col row">
                <div class="ml-auto">
                    <button type="submit" (click)="cancel()" [disabled]="!hasUnsavedChanges">
                        Cancel
                    </button>    
                </div>
                <div>
                    <button type="submit" class="button lime-green" (click)="save()" [disabled]="!sectionForm?.valid"
                    data-intro="Remember to save the section you completed. If you forget, our system will remind you."
                    data-title="Save Section"
                    data-step="10"
                    >
                        Save Section
                    </button>    
                </div>
            </div>
        </div>
    </form>
    <app-analytics [section]="section" [analytics]="analytics"></app-analytics>
</div>