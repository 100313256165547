import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ResumeService } from '../../services';
import { UNextResume, User } from '../../models';
import { Router } from '@angular/router';
import { ActionItem, ActionMenu } from '../../models/actionmenu.model';

@Component({
  selector: 'app-action-menu',
  templateUrl: './action-menu.component.html',
  styleUrl: './action-menu.component.scss'
})
export class ActionMenuComponent implements OnInit {

  @Input()
  id: string;

//  private _format: 'basic' | 'detail' | 'job description' | 'section' | 'basic-resume' | 'default-resume' | 'basic-resume-download' | 'default-resume-download' | 'resume-download' | undefined = undefined;
  private _format: 'basic' | 'detail' | 'job description' | 'section' | 'resume-download' | undefined = undefined;
  @Input()
//  get format(): 'basic' | 'detail' | 'job description' | 'section' | 'basic-resume' | 'default-resume' | 'basic-resume-download' | 'default-resume-download' | 'resume-download' | undefined {
  get format(): 'basic' | 'detail' | 'job description' | 'section' | 'resume-download' | undefined {
    return this._format;
  }
//  set format(value: 'basic' | 'detail' | 'job description' | 'section' | 'basic-resume' | 'default-resume' | 'basic-resume-download' | 'default-resume-download' | 'resume-download' | undefined) {
  set format(value: 'basic' | 'detail' | 'job description' | 'section' | 'resume-download' | undefined) {
    this._format = value;
    this.setAction();
  }

  @Input()
  label: string = 'More';

  @Input()
  resume: UNextResume;

  @Input()
  user: User;

  @Input()
  displayStyle: 'dropdown' | 'dots' = 'dropdown';

  @Output()
  emit: EventEmitter<string> = new EventEmitter<string>();

  private element: any;
  display: boolean = false;

  @Input()
  actions: ActionItem[] = [];
  
  @ViewChild('menuPanel', { static: true }) menuPanel!: ElementRef;
  

  constructor(
    private el: ElementRef,
    private resumeService: ResumeService,
    private router: Router,
  ){
    
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    this.setAction();
  }

  setAction() {
    if(this.format === 'basic') {
      this.actions = ActionMenu.ResumeMenu;
    } else if (this.format === 'detail') {
      this.actions = ActionMenu.ResumeDetailMenu;
    } else if (this.format === 'job description') {
      this.actions = ActionMenu.ResumeJobDescription;
    } else if (this.format === 'section') {
      this.actions = ActionMenu.Sections;
    // } else if (this.format === 'basic-resume') {
    //   this.actions = ActionMenu.ResumeTypeBasicMenu;
    // } else if (this.format === 'default-resume') {
    //   this.actions = ActionMenu.ResumeTypeDefaultMenu;
    // } else if (this.format === 'basic-resume-download') {
    //   this.actions = ActionMenu.ResumeTypeBasicDownloadMenu;
    // } else if (this.format === 'default-resume-download') {
    //   this.actions = ActionMenu.ResumeTypeDefaultDownloadMenu;
    }else if (this.format === 'resume-download') {
      this.actions = ActionMenu.ResumeDownloadMenu;
    }
  }
  expand(event: any) {
    // Close all other open panels
    document.querySelectorAll('.menu-panel').forEach(panel => {
      (panel as HTMLElement).style.display = 'none';
    });

    this.display = !this.display;
    if (this.display) {
      (this.menuPanel.nativeElement as HTMLElement).style.display = 'block';
    } else {
      (this.menuPanel.nativeElement as HTMLElement).style.display = 'none';
    }
    event.stopPropagation(); // Prevent click event from bubbling up
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (this.menuPanel && !this.menuPanel.nativeElement.contains(event.target)) {
      this.display = false;
    }
  }

  handleAction(actionName: string, event: Event){
    event.preventDefault();
    event.stopPropagation();
    this.display = false;
    this.emit.emit(actionName);
  }

}


