export enum ActionType {
    EditResume = 1000,
    ArchiveResume = 1001,
    // SetBasicTemplate = 2,
    // SetDefaultTemplate = 3,
    CopyResume = 1004,
    UploadJobDescription = 1005,
    Preview = 1006,
    ViewJobDescription = 1007,
    ViewJobSkills = 1008,
    DeleteSection = 1009,
    DownloadResume = 1010,
//    DownloadResumePDF = 11,
    DownloadResumeDOCX = 1012,
    // SetUWGBTemplate = 99
}

export class ActionItem {
    label: string;
    icon: string;
    action: ActionType;

    constructor(label: string, icon: string, action: ActionType) {
        this.label = label;
        this.icon = icon;
        this.action = action;
    }
}

export class ActionMenu {
    public static ResumeMenu: ActionItem[] = [
        new ActionItem('Edit Resume', '/assets/images/icons/edit.svg', ActionType.EditResume),
        new ActionItem('Archive', '/assets/images/icons/archive.svg', ActionType.ArchiveResume),
        // new ActionItem('Set to default template', '/assets/images/icons/duplicate.svg', ActionType.SetDefaultTemplate),
        // new ActionItem('Set to basic template', '/assets/images/icons/duplicate.svg', ActionType.SetBasicTemplate),
        new ActionItem('Copy Resume', '/assets/images/icons/duplicate.svg', ActionType.CopyResume)
    ];

    // public static ResumeTypeDefaultMenu: ActionItem[] = [
    //     new ActionItem('Set to basic template', '/assets/images/icons/duplicate.svg', ActionType.SetBasicTemplate),
    // ];

    // public static ResumeTypeBasicMenu: ActionItem[] = [
    //     new ActionItem('Set to default template', '/assets/images/icons/duplicate.svg', ActionType.SetDefaultTemplate),
    // ];

    // public static ResumeTypeDefaultDownloadMenu: ActionItem[] = [
    //     new ActionItem('Set to basic template', '/assets/images/icons/duplicate.svg', ActionType.SetBasicTemplate),
    // ];

    // public static ResumeTypeBasicDownloadMenu: ActionItem[] = [
    //     new ActionItem('Set to default template', '/assets/images/icons/duplicate.svg', ActionType.SetDefaultTemplate),
    // ];

    public static ResumeDetailMenu: ActionItem[] = [
        new ActionItem('Archive', '/assets/images/icons/archive.svg', ActionType.ArchiveResume),
        // new ActionItem('Set to default template', '/assets/images/icons/duplicate.svg', ActionType.SetDefaultTemplate),
        // new ActionItem('Set to basic template', '/assets/images/icons/duplicate.svg', ActionType.SetBasicTemplate),
        new ActionItem('Copy Resume', '/assets/images/icons/duplicate.svg', ActionType.CopyResume),
        new ActionItem('View Job Description', '/assets/images/icons/share.svg', ActionType.UploadJobDescription),
        new ActionItem('Preview Resume', '/assets/images/icons/duplicate.svg', ActionType.Preview),
    ];
    public static ResumeJobDescription :ActionItem[] = [
        new ActionItem('View Description', '/assets/images/icons/share.svg', ActionType.ViewJobDescription),
        new ActionItem('View Skills', '/assets/images/icons/duplicate.svg', ActionType.ViewJobSkills),
    ];

    public static Sections: ActionItem[] = [
        new ActionItem('Delete', '/assets/images/icons/archive.svg', ActionType.DeleteSection),
    ];

    public static ResumeDownloadMenu: ActionItem[] = [
        new ActionItem('Preview Resume', '/assets/images/icons/duplicate.svg', ActionType.Preview),
//        new ActionItem('Download PDF', '/assets/images/icons/archive.svg', ActionType.DownloadResumePDF),
        new ActionItem('Download DOCX', '/assets/images/icons/archive.svg', ActionType.DownloadResumeDOCX),
    ];
}