<div class="list-view">
    <div class="bg-teal pl-medium pr-medium">
        <div class="bg-lime pl-medium pr-medium py-large">
            <app-filter-menu [vertical]="true" (outputListEvent)="updateStudentList($event)" [inputList]="students"
                [filterDropDownOptions]="studentFilterDropDownOptions" [searchOptions]="studentSearchOptions">
            </app-filter-menu>
        </div>
    </div>

    <div *ngIf="modifiedStudentList" class="bg-cream pl-medium pr-medium py-large">
        <div class="flex-columns mb-medium">
            <div class="text text-title mb-small mr-small">
                Manage Students
            </div>
            <div class="text text-900">
                {{modifiedStudentList.length}} results
            </div>
        </div>
        <div *ngIf="modifiedStudentList.length" class="flex-columns">
            <ng-template ngFor let-s
                [ngForOf]="modifiedStudentList | sort: 'lastName':'asc' | paginate: {id: 'student-pager', itemsPerPage: 12, currentPage: studentPageNumber } ">
                <div class="mb-small profile-card bg-white pl-small pr-small py-small">
                    <div class="text card-title">
                        <a href [routerLink]="['/students', s.urlName]" title="{{ s.firstName }} {{ s.lastName }} profile link" class="card-title-link">
                            {{ s.firstName }} {{ s.lastName }}
                        </a>
                    </div>
                    <div class="student-attributes">
                        <div>
                            <img alt="school icon" src="{{cdn.toCDN('images/icons/school.svg')}}" />
                            {{s.schoolName}}</div>
                        <div>
                            <img alt="majors icon" src="{{cdn.toCDN('images/icons/hat.svg')}}" />
                            <span *ngIf="!s.majors.length">Not Available</span>
                            @for (major of s.majors; track major) {
                                <span>{{ major }}</span>
                                @if ($count !== $index + 1) { - }
                            }
                        </div>
                    </div>
                    <div class="flex-columns">
                        <div class="flex-rows">
                            <button *ngIf="showSwitch" class="btn lime-green" (click)="switchUserType(s)">
                                Set to Staff
                            </button>
                        </div>
                        <div class="avatar">
                            <img [src]="s?.user.avatar" alt="profile pic" />
                        </div>
                    </div>
                    <button class="profile btn btn-white border-teal"
                        [routerLink]="['/students', s.urlName]">View Profile</button>
                </div>
            </ng-template>
        </div>
        <div *ngIf="modifiedStudentList.length" class="mt-small flex-columns justify-around">
            <pagination-controls id="student-pager" (pageChange)="studentPageNumber = $event"></pagination-controls>
        </div>
    </div>
</div>