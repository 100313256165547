import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ResumeService } from 'src/app/shared/services';
import { UNextResume } from 'src/app/shared/models';
import { ToastService } from 'src/app/shared/services/toast.service';
import { GenericComponent } from 'src/app/shared/components/generic-component/generic.component';

@Component({
  selector: 'app-student-resume-preview',
  templateUrl: './student-resume-preview.component.html',
  styleUrls: ['./student-resume-preview.component.scss']
})
export class StudentResumePreviewComponent extends GenericComponent implements OnInit {
  @Input()
  selectedResume: UNextResume;

  selectedTemplate: string;
  urlName: string | undefined;
  @Input()
  chrome = true;
  @Input()
  padding = true;
  @Input()
  showProgress = true;

  constructor(
    private resumes: ResumeService,
    private route: ActivatedRoute,
    private router: Router,
    snackbar: ToastService
  ){
    super(snackbar);

    this.selectedTemplate = this.route.snapshot.params.template;
    this.urlName = this.route.snapshot.params.studentUrl;
    if (this.chrome) {
      this.chrome = this.route.snapshot.params.nochrome !== 'true';
    }
    
    route.queryParams.subscribe(params => {
      if (params.nochrome === 'true') {
        this.chrome = false;
      }
    });

  }

  async ngOnInit() {
      await this.loadResume();  
  }

  private async loadResume() {
    await this.executeAsync(async () => {
        if (this.urlName?.length) {
          this.setWorking(50);
          if (parseInt(this.route.snapshot.params.resumeName, 10)) {
            this.selectedResume = await this.resumes.getResumeById(parseInt(this.route.snapshot.params.resumeName, 10));
          } else {
            this.selectedResume = await this.resumes.getResumeByName(this.route.snapshot.params.resumeName);
          }
        }  
    }, 'There was an error loading your resume preview');    
  }

  backToEdit(){
    this.router.navigate(['/students', this.urlName, 'resume-builder', this.selectedResume.name]);
  }

}
