import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DropDownOptions } from 'src/app/shared/components/dropdown/dropdown.component';
import { Employer, FilterOption, User } from 'src/app/shared/models';
import { Opportunity, OpportunityRequest } from 'src/app/shared/models/opportunity.model';
import { Student } from 'src/app/shared/models/student.model';
import { ArraySortPipe } from 'src/app/shared/pipes';
import { EmployerService, OpportunityService } from 'src/app/shared/services';
import { CDNService } from 'src/app/shared/services/cdn.service';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-employer-opportunity-list',
  templateUrl: './employer-opportunity-list.component.html',
  styleUrls: ['./employer-opportunity-list.component.scss']
})
export class EmployerOpportunityListComponent implements OnInit {

  user: User;

  student: Student;
  isStudent: boolean;

  @Input()
  get employer(): Employer {
    return this.eEmployer;
  }
  set employer(value: Employer) {
    this.eEmployer = value;
    if (value && (!this.urlName || value.urlName !== this.urlName)) {
      this.urlName = value.urlName;
      this.loadOpportunities();
    }
  }
  private eEmployer: Employer;

  @Input()
  includeInactive = false;
  @Input()
  includeUnPublished = false;
  @Input()
  orderBy: string;
  @Input()
  maxCount: number;

  allopportunities: Opportunity[] = [];
  urlName = '';


  opportunities: Opportunity[] = [];
  industries: DropDownOptions[] = [];
  employmentTypes: DropDownOptions[] = [];

  loading = 0;

  opportunityFilterDropDownOptions: FilterOption[] = [];
  opportunitySearchOptions: any[] = [];
  opportunityList: any[] = [];
  modifiedOpportunitiesList: Opportunity[] = [];
  requestedOpportunitiesList: OpportunityRequest[] = [];
  filterActive: boolean = false;
  opportunityPageNumber: number = 1;


  constructor(
    private route: ActivatedRoute,
    private opportunityService: OpportunityService,
    private employerService: EmployerService,
    private snackbar: ToastService,
    public cdn: CDNService,
  ) {
    this.urlName = this.route.snapshot.params.urlName;
    if (!this.urlName && this.user && this.user.employee) {
      this.urlName = this.user.employee.employerUrlName;
      this.loadOpportunities();
    }
  }

  async ngOnInit() {
    await this.loadOpportunities();
    this.modifiedOpportunitiesList = this.opportunities;

    this.createDropDownOptions();
    this.createOpportunityFilterObjects();
  }

  async loadOpportunities() {
    this.loading = 50;
    try {
      this.allopportunities = await this.opportunityService.getEmployerOpportunities(this.urlName,
        false, true, 'name', null, null, null, null, null);
      this.opportunities = this.allopportunities;
      this.modifiedOpportunitiesList = this.allopportunities;

      this.requestedOpportunitiesList = await this.employerService.getEmployerOpportunityRequests(this.urlName);
    } catch (err) {
      console.error(err);
      this.snackbar.error({
        message: 'There was an error getting opportunities',
        title: 'Error'
      });
    } finally {
      this.loading = 0;
    }
  }

  createDropDownOptions() {

    const locations = this.opportunities
      .filter(s => s?.employerLocation)
      .map(s => s.employerLocation)
      .filter((value, index, self) => self.indexOf(value) === index)
      .sort();


    const interests = this.opportunities
      .flatMap(s => s?.interests?.map(i => i.name) ?? [])
      .filter(name => name)
      .filter((value, index, self) => self.indexOf(value) === index)
      .sort();

    const types = this.opportunityService.getOpportunityTypes().map(a => a.name);

    if (locations.length) {
      this.opportunityFilterDropDownOptions.push({ id: 1, label: 'Location', active: false, name: 'location', options: locations });
    }

    if (interests.length) {
      this.opportunityFilterDropDownOptions.push({ id: 3, label: 'Field Offered', active: false, name: 'interest', options: interests });
    }

    this.opportunityFilterDropDownOptions.push({ id: 5, label: 'Opportunity Type', active: false, name: 'type', options: types });
  }

  createOpportunityFilterObjects() {
    this.opportunities.forEach(o => {
      const opp = {
        id: o.id,
        location: o.locations,
        employer: o.employerName,
        interest: [...new Set(o.interests.flatMap(s => s.name))].sort(),
        type: o.opportunityType
      }

      this.opportunityList.push(opp);
    });

  }

  updateOpportunityList(data: { list: any[], sort: { value: string, asc: boolean }, search: string, reset: boolean }) {

    this.filterActive = !data.reset ? true : false;
    this.modifiedOpportunitiesList = [];
    if (data.list.length) {
      this.modifiedOpportunitiesList = new ArraySortPipe().transform(
        this.opportunities.filter(a => data.list.some(i => i.id === a.id)),
        data.sort.value,
        data.sort.asc
      );
    }
  }

}
