<app-progress-bar [splashScreen]="true" color="green" [value]="loading">
    <section class="dashboard view">
        <!-- left column -->
        <div class="profile bg-cream">
            <div *ngIf="!user" class="text text-title text-teal" style="min-height: 90vh;">
                Welcome to Unext
            </div>
            <div *ngIf="user?.isStudent" class="student">
                <!--Student Profile Information-->
                <div class="flex-rows">
                    <div class="flex-columns mb-large" [ngClass]="{ 'append-image': !!user?.avatar?.length }">
                        <div class="flex-rows">
                            <div class="flex-columns mb-small">
                                <div class="text text-title text-teal mt-auto">
                                    {{ user?.firstName }} {{ user?.lastName }}
                                </div>
                                <!-- <a class="text text-subtitle text-800 text-teal no-link mt-auto ml-auto"
                                        [routerLink]="['/my-profile']">
                                        Edit Profile
                                    </a> -->
                            </div>
                            <div class="text text-subtitle mb-medium">
                                {{ student?.user?.location?.address?.city?.name }} {{
                                student?.user?.location?.address?.city?.state?.name }}
                            </div>

                            <div class="mb-small contact-info">
                                <label class="text text-subtitle mr-small">Email</label>
                                <span class="text text-subtitle">
                                    {{ user?.email }}
                                </span>

                                <label class="text text-subtitle mr-small">Phone</label>
                                <span class="text text-subtitle">
                                    {{ user?.phone }}
                                </span>
                            </div>
                        </div>
                        <div class="avatar ml-auto">
                            <img [src]="user?.avatar | cdn" alt="profile pic" />
                        </div>
                    </div>

                    <div class="separator mb-large"></div>

                    <div class="flex-columns mb-large">
                        <div class="flex-rows">
                            <div class="text text-subtitle mb-small">
                                {{ student?.schoolName }}
                            </div>
                            <div class="text text-subtitle">
                                <ng-template ngFor let-e [ngForOf]="student?.fieldsOfInterest | sort:'':'asc'"
                                    let-isLast="last">
                                    {{ (e.name ?? e ?? '') }}{{ (isLast ? '' : ', ') }}
                                </ng-template>
                            </div>
                        </div>
                    </div>

                    <div class="separator mb-large"></div>

                    <div class="flex-columns justify-between">
                        <!--Profile Buttons-->
                        <a class="btn border border-medium border-dark-green" [routerLink]="['/my-profile']"
                        data-intro="Edit your profile to get matched with the right employers."
                        data-title="Edit Profile"
                        data-step="1"
                        >Edit Profile</a>
                        <a class="btn border border-medium border-dark-green" [routerLink]="['/students', user?.student?.urlName ]">View Profile</a>
                        <a class="btn border border-medium border-dark-green" (click)="shareProfile()">Share Profile</a>
                    </div>
                </div>
            </div>
            <div *ngIf="user?.isStaff" class="university">
                <!--Staff Profile Information-->
                <div class="flex-rows">
                    <div class="flex-columns mb-large" [ngClass]="{ 'append-image': !!user?.avatar?.length }">
                        <div class="flex-rows">
                            <div class="flex-columns mb-small">
                                <div class="text text-title text-teal mt-auto">
                                    {{ user?.firstName }} {{ user?.lastName }}
                                </div>
                                <a class="text text-subtitle text-teal no-link mt-auto ml-auto"
                                    [routerLink]="['/my-profile']"
                                    data-intro="Set settings for students, update your university profile, invite your team, and adjust department details."
                                    data-title="Edit Profile"
                                    data-step="1">
                                    Edit Profile
                                </a>
                            </div>
                            <div class="text text-subtitle mb-small" *ngIf="staff?.school?.locations?.length">
                                {{ staff?.school?.locations[0] }}
                            </div>
                            <div class="text text-subtitle mb-medium" *ngIf="staff?.positionName?.length && staff?.departmentName?.length">
                                {{ staff?.positionName }} for {{ staff?.departmentName}}
                            </div>

                            <div class="mb-small contact-info">
                                <label class="text text-subtitle mr-small">Email</label>
                                <a class="text text-subtitle" href="email:{{staff?.email}}">
                                    {{ staff?.email }}
                                </a>

                                <label class="text text-subtitle mr-small">Phone</label>
                                <a class="text text-subtitle no-link" href="tel:{{staff?.phone | phone}}">
                                    {{ staff?.phone | phone }}
                                </a>
                            </div>
                        </div>
                        <div class="avatar ml-auto">
                            <img [src]="user?.avatar | cdn" alt="profile pic" />
                        </div>
                    </div>

                    <div class="separator mb-large"></div>

                    <div class="flex-columns mb-large">
                        <div class="flex-rows">
                            
                            <div class="text text-subtitle mb-small">
                                {{ staff?.schoolName }}
                            </div>
                            <div class="text text-subtitle">
                                <a class="no-link" href="{{ staff?.school?.domain }}" target="_blank">
                                    {{ staff?.school?.domain | remove:'https://' }}
                                </a>
                            </div>
                            <div class="flex-columns">
                                <div class="mr-small my-auto" style="min-width: 120px;">
                                    <img [src]="staff?.schoolLogoUrl | cdn" />
                                </div>
                                <p class="text school-description" [innerHTML]="staff?.school?.description">

                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="flex-columns justify-between mb-medium">
                        <!--Profile Buttons-->
                        <a class="btn border border-medium border-dark-green" [routerLink]="['/school', staff?.schoolUrlName, 'edit']">Edit
                            Profile</a>
                        <a class="btn border border-medium border-dark-green" (click)="shareProfile()">Share Profile</a>
                        <a class="btn border border-medium border-white lime-green"
                            [routerLink]="['/school', staff?.schoolUrlName, 'staff']">View Team</a>
                        <a class="btn border border-medium border-white lime-green"
                            [routerLink]="['/school', staff?.schoolUrlName, 'students']">View Students</a>
                    </div>

                    <div class="separator mb-large"></div>
                </div>
            </div>
            <div class="journey" *ngIf="user?.isStaff">
                <div class="student-journey flex-rows">
                    <div class="no-link text-lime text text-title text-no-wrap mb-small">
                        Student Journey
                    </div>
                    <a class="no-link text-white text text-center text-no-wrap chevron mb-medium">
                        View Data ->
                    </a>
                </div>
            </div>
            <div *ngIf="user?.isEmployer" class="employer">
                <!--Staff Profile Information-->
                <div class="flex-rows">
                    <div class="flex-columns mb-large" [ngClass]="{ 'append-image': !!user?.avatar?.length }">
                        <div class="flex-rows">
                            <div class="flex-columns mb-small">
                                <div class="text text-title text-teal mt-auto">
                                    {{ user?.firstName }} {{ user?.lastName }}
                                </div>
                                <a class="text text-subtitle text-800 text-teal no-link mt-auto ml-auto"
                                    [routerLink]="['/my-profile']"
                                    data-intro="Edit your profile."
                                    data-title="Edit Profile"
                                    data-step="1">
                                    Edit Profile
                                </a>
                            </div>
                            <div class="text text-subtitle text-800 mb-medium">
                                {{ employee?.title }}
                            </div>

                            <div class="mb-small contact-info">
                                <label class="text text-subtitle text-800 mr-small">Email</label>
                                <a class="text text-subtitle" href="email:{{staff?.email}}">
                                    {{ user?.email }}
                                </a>
                            </div>
                        </div>
                        <div class="avatar ml-auto">
                            <img [src]="user?.avatar | cdn" alt="profile pic" />
                        </div>
                    </div>

                    <div class="separator mb-large"></div>

                    <div class="flex-columns mb-large">
                        <div class="mr-small">
                            <img [src]="employer?.smallLogoUrl" />
                        </div>
                        <div class="flex-rows">
                            <div class="text text-subtitle text-800 mb-small">
                                {{ employer?.name }}
                            </div>
                            <div class="text text-subtitle text-800 mb-small">
                                {{ employer?.primaryAddress }}
                            </div>
                            <div class="text text-subtitle text-800 mb-small">
                                <a class="no-link" href="{{ employer?.domain }}" target="_blank">
                                    {{ employer?.domain | remove:'https://' }}
                                </a>
                            </div>
                            <div class="text text-subtitle text-800 mb-small">
                                <ng-template ngFor let-e [ngForOf]="employer?.industries | sort:'':'asc'"
                                    let-isLast="last">
                                    {{ (e.name ?? e ?? '') }}{{ (isLast ? '' : ', ') }}
                                </ng-template>
                            </div>
                            <div class="text text-subtitle text-800 mb-small">
                                {{ employer?.size }} employees
                            </div>

                            <!-- <p class="text school-description" [innerHTML]="employer?.description">
    
                                </p> -->
                        </div>
                    </div>

                    <div class="flex-columns justify-between mb-medium">
                        <!--Profile Buttons-->
                        <div >

                            <a class="btn border border-medium border-dark-green mb-small"
                            [routerLink]="['/employers', employer?.urlName, 'edit']"
                            data-intro="Edit your profile to get matched with the right employers."
                            data-title="Edit Profile"
                            data-step="1">
                            Edit Profile</a>
                            <a class="btn border border-medium border-dark-green" (click)="shareProfile()">Share Profile</a>
                        </div>
                        <div>

                            
                            <a class="btn border border-medium border-white lime-green mb-small"
                            [routerLink]="['/employers', employer?.urlName, 'team']">View Team</a>
                            <a class="btn border border-medium border-white lime-green"
                            [routerLink]="['/employers', employer?.urlName, 'create-opportunity']">Create New Opportunity</a>
                        </div>
                    </div>

                    <div class="separator mb-large"></div>
                </div>
            </div>
            <div *ngIf="user?.isAdmin" class="admin">
                <!--Admin Profile Information-->
                <div class="flex-rows">
                    <div class="flex-columns mb-large" [ngClass]="{ 'append-image': !!user?.avatar?.length }">
                        <div class="flex-rows">
                            <div class="flex-columns mb-small">
                                <div class="text text-title text-teal mt-auto">
                                    {{ user?.firstName }} {{ user?.lastName }}
                                </div>
                            </div>
                        </div>
                        <div class="avatar ml-auto flex-columns">
                            <img [src]="user?.avatar | cdn" class="mt-auto mb-auto ml-auto mr-auto" />
                        </div>
                    </div>

                    <div class="separator mb-large"></div>
                </div>

            </div>

            <app-no-organization [user]="user" *ngIf="user"></app-no-organization>
        </div>

        <div class="options bg-teal">
            <div *ngIf="!user" class="text text-title text-lime" style="min-height: 90vh;">
                Hold on while we gather your information
            </div>
            <div *ngIf="user?.isStudent || user?.isStaff" class="student">
                <div class="flex-rows mb-large">

                    <div class="text text-lime text-center">
                        Unext service are here to help you jump start your career path.
                    </div>
                    <div class="text text-lime text-center">
                        Click Below to get started!
                    </div>
                </div>

                <div class="services mb-large">
                    <a class="card chevron chevron no-link" [routerLink]="['/my-profile/resume']">
                        <div class="text text-title mb-small">
                            Resume
                        </div>
                        <p class="text">
                            Create a strong resume that stands out. Powered by AI, we make it wasy to match it with your
                            career of interest.
                        </p>
                        <div>
                            <div class="go"></div>
                        </div>
                    </a>
                    <a class="card chevron chevron no-link" [routerLink]="['/my-profile/resume']" disabled-flag>
                        <div class="text text-title mb-small">
                            Cover Letter
                        </div>
                        <p class="text">
                            Create a strong Cover Letter that stands out. Powered By AI, We make it easy to match it
                            with your career of interest.
                        </p>
                        <div>
                            <div class="go"></div>
                        </div>
                    </a>
                    <a class="card no-link analytics chevron" *ngIf="user?.isStaff" disabled-flag>
                        <div class="text text-title mb-medium">
                            My Data
                        </div>
                        <div class="grid">
                            <div class="text text-title text-teal">
                                175
                            </div>
                            <div class="text text-upper">
                                New Resume Created
                            </div>
                            <div class="separator"></div>
                            <div class="text text-title text-teal">
                                241
                            </div>
                            <div class="text text-upper">
                                Job Shadow Connections
                            </div>
                            <div class="separator"></div>
                            <div class="text text-title text-teal">
                                143
                            </div>
                            <div class="text text-upper">
                                Profiles Created
                            </div>
                            <div class="separator"></div>
                            <div class="text text-title text-teal">
                                3111
                            </div>
                            <div class="text text-upper">
                                New Data Points
                            </div>

                        </div>
                    </a>
                    <a class="card chevron no-link" [routerLink]="['/library']">
                        <div class="text text-title mb-small">
                            Resources
                        </div>
                        <p class="text" *ngIf="user?.isStudent">
                            Access different resources such as strong words, career preparation, how to prepare for
                            work, and much more.
                        </p>
                        <p class="text" *ngIf="user?.isStaff">
                            Stay up to date with feedback from you career services and advisors. They are here to
                            support you along your journey.
                        </p>
                        <div>
                            <div class="go"></div>
                        </div>
                    </a>
                    <a class="card chevron no-link" [routerLink]="['/school', student?.schoolUrlName]"
                        *ngIf="user?.isStudent">
                        <div class="text text-title mb-small">
                            Career Services
                        </div>
                        <p class="text">
                            Stay up to date with feedback from your career services and advisors. They are here to
                            support you along your journey.
                        </p>
                        <div>
                            <div class="go"></div>
                        </div>
                    </a>
                    <a class="card chevron no-link" [routerLink]="['/employers']" *ngIf="user?.isStaff">
                        <div class="text text-title mb-small">
                            Employers
                        </div>
                        <p class="text">
                            View all employers on the platform and engaging with your students. Stay up to date on the
                            latest.
                        </p>
                        <div>
                            <div class="go"></div>
                        </div>
                    </a>
                </div>

                <div class="opportunities" *ngIf="user?.isStudent">
                    <div class="my-opportunities">
                        <!-- <a class="no-link bg-lime text text-title text-no-wrap text-teal"
                            [routerLink]="['/my-opportunities']">
                            My Opportunities
                        </a> -->
                        <a class="no-link bg-lime text text-title text-no-wrap text-teal"
                        [routerLink]="['/opportunities',user.student.urlName,]">
                        My Opportunities
                    </a>
                    </div>
                </div>

            </div>
            <div *ngIf="user?.isEmployer" class="employer">
                <div class="flex-rows mb-large">

                    <div class="text text-800 text-lime text-center">
                        Recruit more efficiently and stay in touch with great talent. Become the brand to work for on
                        campus!
                    </div>
                </div>

                <div class="services mb-large" >
                    <a class="card chevron chevron no-link" disabled-flag
                        [routerLink]="['/employers', employer?.urlName, 'team', 'edit']" >
                        <div class="text text-title mb-small">
                            Manage Team
                        </div>
                        <div>
                            <div class="go"></div>
                        </div>
                    </a>
                    <a class="card chevron chevron no-link" disabled-flag
                        [routerLink]="['/employers', employer?.urlName, 'prospects']">
                        <div class="text text-title mb-small">
                            My Students
                        </div>
                        <div>
                            <div class="go"></div>
                        </div>
                    </a>
                    <a class="card no-link analytics chevron" [routerLink]="['/analytics']" disabled-flag>
                        <div class="text text-title mb-medium">
                            My Data
                        </div>
                        <div class="grid">
                            <div class="text text-title text-teal">
                                175
                            </div>
                            <div class="text text-upper">
                                New Tour Requests
                            </div>
                            <div class="separator"></div>
                            <div class="text text-title text-teal">
                                241
                            </div>
                            <div class="text text-upper">
                                Job Shadow Connections
                            </div>
                            <div class="separator"></div>
                            <div class="text text-title text-teal">
                                143
                            </div>
                            <div class="text text-upper">
                                Profile Views
                            </div>
                            <div class="separator"></div>
                            <div class="text text-title text-teal">
                                3111
                            </div>
                            <div class="text text-upper">
                                Potentital Applications
                            </div>

                        </div>
                    </a>
                    <a class="card chevron no-link" [routerLink]="['/employers', employer?.urlName, 'audiences']"  disabled-flag>
                        <div class="text text-title mb-small">
                            Audiences
                        </div>
                        <div>
                            <div class="go"></div>
                        </div>
                    </a>
                </div>

                <div class="opportunities" *ngIf="user?.isStudent">
                    <div class="my-opportunities">
                        <a class="no-link bg-lime text text-title text-no-wrap text-teal"
                            [routerLink]="['/my-opportunities']">
                            My Opportunities
                        </a>
                    </div>
                </div>

                <div class="opportunities" *ngIf="user?.isEmployer">
                    <!-- <div class="my-opportunities">
                        <a class="no-link bg-lime text text-title text-no-wrap text-teal"
                            [routerLink]="['/my-opportunities']">
                            My Opportunities
                        </a>
                    </div> -->

                    <div class="my-opportunities">
                        <a class="no-link bg-lime text text-title text-no-wrap text-teal"
                            [routerLink]="['/employers', user?.employee?.employerUrlName, 'opportunities']">
                            My Opportunities
                        </a>
                    </div>
                </div>

            </div>
            <div *ngIf="user?.isAdmin" class="admin">
                <div class="flex-rows mb-large">

                    <div class="text text-800 text-lime text-center">
                        Admin text
                    </div>
                </div>

                <div class="services mb-large">
                    <a class="card chevron chevron no-link"
                        [routerLink]="['/administration/employer']">
                        <div class="text text-title mb-small">
                            Manage Employers
                        </div>
                        <div>
                            <div class="go"></div>
                        </div>
                    </a>
                    <a class="card chevron chevron no-link"
                        [routerLink]="['/administration/school']">
                        <div class="text text-title mb-small">
                            Manage Schools
                        </div>
                        <div>
                            <div class="go"></div>
                        </div>
                    </a>
                </div>

                <div class="opportunities" *ngIf="user?.isStudent">
                    <div class="my-opportunities">
                        <a class="no-link bg-lime text text-title text-no-wrap text-teal"
                            [routerLink]="['/my-opportunities']">
                            My Opportunities
                        </a>
                    </div>
                </div>

                <div class="opportunities" *ngIf="user?.isEmployer">
                    <div class="my-opportunities">
                        <a class="no-link bg-lime text text-title text-no-wrap text-teal"
                            [routerLink]="['/my-opportunities']">
                            My Opportunities
                        </a>
                    </div>
                </div>

            </div>
            <!-- <ng-template [ngIf]="user">

                <div class="rounded-corners">
                    <h2 class="text xlarge">Resumes & Cover Letters</h2>
                    <div class="flex-columns justify-between grid-halves">
                        <div class="rounded-corners no-box">
                            <h3 class="text large">Resumes</h3>
                            <p class="text">
                                Create unique resumes for different opportunities, and save them for future use.
                            </p>
                            <a class="edit-profile button orange outline mt-small mb-small"
                                [routerLink]="['/my-profile/resume']">Build Resumes</a>
                        </div>
                        <div class="rounded-corners no-box" disabled-flag>
                            <h3 class="text large">Cover Letters</h3>
                            <p class="text">Make a great first impression with cover letters.</p>
                            <a class="edit-profile button orange outline alt mt-small mb-small"
                                [routerLink]="['/my-profile/resume']">Start Writing</a>
                        </div>
                    </div>
                </div>
                <div id="user-actions" *ngIf="user?.isStudent" class="flex-columns justify-between grid-thirds">
                    <div class="rounded-corners">
                        <h3 class="text xlarge">Career Services</h3>
                        <p class="text">Get help with finding your next opportunity.</p>
                        <a class="edit-profile button dark-green outline mt-small mb-small"
                            [routerLink]="['/student/employers']">Get
                            Help</a>
                    </div>

                    <div class="rounded-corners">
                        <h3 class="text xlarge">Libraries</h3>
                        <p class="text">Explain what this is for.</p>
                        <a class="edit-profile button dark-green outline mt-small mb-small" [routerLink]="['/library']">Add to
                            my
                            Profile</a>
                    </div>

                    <div disabled-flag class="rounded-corners">
                        <h3 class="text xlarge">AI Generation</h3>
                        <p class="text">Generate job interests.</p>
                        <a class="edit-profile button dark-green outline mt-small mb-small"
                            [routerLink]="['/my-profile/resume']">Update
                            My Preferences</a>
                    </div>
                </div>

                <div id="user-actions" *ngIf="user?.isStaff" class="flex-columns justify-between grid-thirds mb-small">
                    <div disabled-flag class="rounded-corners">
                        <h3 class="text xlarge">Career Services</h3>
                        <p class="text">Get help with finding your next opportunity.</p>
                        <a class="edit-profile button dark-green outline mt-small mb-small">Get Help</a>
                    </div>

                    <div class="rounded-corners">
                        <h3 class="text xlarge">Libraries</h3>
                        <p class="text">Explain what this is for.</p>
                        <a class="edit-profile button dark-green outline mt-small mb-small" [routerLink]="['/library']">Add to
                            my
                            Profile</a>
                    </div>

                    <div disabled-flag class="rounded-corners">
                        <h3 class="text xlarge">Data Analytics</h3>
                        <p class="text">Generate job interests.</p>
                        <a class="edit-profile button dark-green outline mt-small mb-small">Update My Preferences</a>
                    </div>
                </div>

                <div id="user-actions" *ngIf="user?.isStaff" class="flex-columns justify-between grid-thirds">
                    <div class="rounded-corners">
                        <h3 class="text xlarge">Institution Profile</h3>
                        <p class="text">Explain what this is for.</p>
                        <a class="edit-profile button dark-green outline mt-small mb-small"
                            [routerLink]="['/school', user.staff.schoolUrlName, 'edit']">Go to Profile</a>
                    </div>

                    <div class="rounded-corners">
                        <h3 class="text xlarge">Institution Settings</h3>
                        <p class="text">Explain what this is for.</p>
                        <a class="edit-profile button dark-green outline mt-small mb-small"
                            [routerLink]="['/school',user.staff.schoolUrlName, 'settings']">Go to Settings</a>
                    </div>

                </div>

                <div id="user-actions" *ngIf="user?.isEmployer" class="flex-columns justify-between grid-thirds">
                    <div class="rounded-corners">
                        <h3 class="text xlarge">Employer Profile</h3>
                        <p class="text">Explain what this is for.</p>
                        <a class="edit-profile button dark-green outline mt-small mb-small"
                            [routerLink]="['/employers', user.employee.employerUrlName, 'edit']">Go to Profile</a>
                    </div>


                    <div *ngIf="user?.employee?.employer?.subscribed" class="rounded-corners">
                        <h3 class="text xlarge">Opportunities</h3>
                        <p class="text">Explain what this is for.</p>
                        <a class="edit-profile button dark-green outline mt-small mb-small"
                            [routerLink]="['/employers', user.employee.employerUrlName, 'opportunity']">Go to
                            Opportunities</a>
                    </div>
                    <div subscribe-flag *ngIf="!user?.employee?.employer?.subscribed" class="rounded-corners">
                        <h3 class="text xlarge">Opportunities</h3>
                        <p class="text">Explain what this is for.</p>
                        <a class="edit-profile button dark-green outline mt-small mb-small"
                            [routerLink]="['/employers', user.employee.employerUrlName, 'opportunity']">Go to
                            Opportunities</a>
                    </div>

                </div>

                <div id="user-actions" *ngIf="user?.isAdmin" class="flex-columns justify-between grid-thirds">

                    <div class="rounded-corners">
                        <h3 class="text xlarge">Employers</h3>
                        <p class="text">Manage employers.</p>
                        <a class="edit-profile button dark-green outline mt-small mb-small"
                            [routerLink]="['/administration', 'employer']">Go to employers</a>
                    </div>

                    <div class="rounded-corners">
                        <h3 class="text xlarge">Students</h3>
                        <p class="text">Manage students.</p>
                        <a class="edit-profile button dark-green outline mt-small mb-small"
                            [routerLink]="['/administration', 'user', 'student']">Go to students</a>
                    </div>

                    <div class="rounded-corners">
                        <h3 class="text xlarge">Schools</h3>
                        <p class="text">Manage schools.</p>
                        <a class="edit-profile button dark-green outline mt-small mb-small"
                            [routerLink]="['/administration', 'school']">Go to schools</a>
                    </div>

                </div>

            </ng-template> -->
        </div>

    </section>
</app-progress-bar>