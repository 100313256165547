import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { DropDownOptions } from 'src/app/shared/components/dropdown/dropdown.component';
import { Employer, EntityType, Opportunity, ResumeType, SectionWord, Student, StudentOpportunity, StudentOpportunityApplication, User } from 'src/app/shared/models';
import { CustomQuillComponent } from 'src/app/shared/components/custom-quill/custom-quill.component';
import { ModalService, StudentService } from 'src/app/shared/services';
import { ToastService } from 'src/app/shared/services/toast.service';
import { GenericComponent } from 'src/app/shared/components/generic-component/generic.component';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-student-apply-documents',
  templateUrl: './student-apply-documents.component.html',
  styleUrls: ['./student-apply-documents.component.scss']
})
export class StudentApplyDocumentsComponent extends GenericComponent implements OnChanges {

  @ViewChild(CustomQuillComponent) quillDescription!: CustomQuillComponent;

  @Input()
  opportunity: Opportunity;
  @Input()
  user: User;

  private _student: Student;
  @Input()
  get student(): Student {
    return this._student;
  }
  set student(value: Student) {
    this._student = value;
  }

  resumeUrl: string;

  @Input()
  studentOpportunity: StudentOpportunity;
  @Input()
  employer: Employer;

  @Output()
  applied: EventEmitter<StudentOpportunityApplication> = new EventEmitter<StudentOpportunityApplication>();
  @Output()
  cancelled: EventEmitter<boolean> = new EventEmitter<boolean>();

  loading = 0;
  words: SectionWord[] = [];

  resumes: DropDownOptions[] = [];

  resumeId: number | undefined;
  hasTranscript = false;
  includeTranscript = false;
  coverletter: string | undefined = undefined;

  entityType = EntityType;

  formGroup: FormGroup;

  constructor(
    private students: StudentService,
    public modals: ModalService,
    snackbar: ToastService,
    private fb: FormBuilder,
  ) {
    super(snackbar);
  }



  ngOnChanges() {
    this.createForm();
    this.loadResumes();
  }

  async loadResumes(): Promise<void> {
    if (this.student) {
      try {
        this.loading = 75;
        if (this.student.resumes.find(r => r.resumeType === ResumeType.Transcript)) {
          this.hasTranscript = true;
        }
        this.resumes = [];
        this.student = await this.students.getStudentResume(this.user.student.urlName);

        this.resumes = this.user.unextResumes.map(x => { return { value: x.id, label: x.name } });
        this.user.unextResumes.map(value => {
          this.resumes.push({ value: value.id, label: `Unext Resume:  ${value.name}` });
        });
        if (this.student.resumes.find(r => r.resumeType === ResumeType.Resume)) {
          this.resumes.push({ value: 0, label: 'Resume attached to profile' });
        }
        this.loading = 90;
      } catch {
        this.snackbar.error({
          message: 'Error loading resumes'
        });
      } finally {
        this.loading = 0;
      }
    }
  }

  createForm(){
    this.formGroup = this.fb.group({
      Greeting: new FormControl(),
    });
  }

  getFormControl(name: string): FormControl {
    return this.formGroup?.get(name) as FormControl;
  }
  get greeting(): FormControl {
    return this.getFormControl('Greeting');
  }

  selectResume(e: DropDownOptions) {
    this.resumeId = e?.value;
  }
  coverletterUploaded(e: string[], modal: string): void {
    this.coverletter = e?.length ? e[0] : undefined;
    this.modals.close(modal);
  }

  resumeUploaded(e: string[], modal: string): void {
    this.resumeUrl = e?.length ? e[0] : undefined;
    this.modals.close(modal);
  }


  apply(): void {
      const resume = this.user.unextResumes.find(r => r.id === this.resumeId);
      this.applied.emit({
        id: this.studentOpportunity.id,
        coverLetter: this.coverletter ?? '',
        unextResume: !!resume,
        resume: resume ? `${this.getOrigin()}/${this.student.urlName}/resume-preview/${encodeURIComponent(resume.id)}/base` : '',
        transcript: this.includeTranscript,
        resumeId: this.resumeId,
        opportunityInterests: [],
        greeting: this.greeting?.value,
      });
  }

  getOrigin(): string {
    return window.location.origin.toLowerCase().indexOf('localhost') > 0 ? 'https://dev.unext.online' : window.location.origin;
  }

  cancel(): void {
    this.cancelled.emit(true);
  }
}
