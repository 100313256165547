import { Component, Input } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { GTMComponentComponent } from 'src/app/shared/components/gtmcomponent/gtmcomponent.component';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-text-marquee',
  templateUrl: './text-marquee.component.html',
  styleUrl: './text-marquee.component.scss'
})
export class TextMarqueeComponent extends GTMComponentComponent {
  @Input()
  heading: string = null;

  _itemArray: { name: string, imageUrl: string }[] = [];
  @Input()
  set textArray(value: { name: string, imageUrl: string }[]) {
    this._itemArray = value;
    this.marqueeElements = [];
    this._itemArray.forEach((item) => {
      this.marqueeElements.push(
        {
          'text': item.name,
          'imageUrl': item.imageUrl, 
          'color': this.getRandomColor()
        }
      )
    });

  }
  get itemArray(): { name: string, imageUrl: string }[] {
    return this._itemArray;
  }
  @Input()
  direction: string = "";
  text: string = "";
  marqueeElements = [];

  constructor(
    gtm: GoogleTagManagerService,
    snackbar: ToastService) {
    super(gtm, snackbar);
  }

  public getRandomColor() {

    const colorClasses = [
      'dark-green',
      'light-green',
      'red',
      'orange',
      'blue',
      'light-blue',
      'light-orange',
      'dark-orange'
    ]

    const colorClass = colorClasses[Math.floor(Math.random() * colorClasses.length)];

    return colorClass;
  }
}
