<app-progress-bar *ngIf="showProgress" [value]="working" [splashScreen]="true"></app-progress-bar>
<!-- <div class="flex-columns">
    <button *ngIf="chrome" class="ml-medium button dark-green button-slim text text-white mt-small" (click)="downloadResume('download-message')">
        Download
    </button>
    <button *ngIf="chrome" class="ml-auto mr-medium button orange outline button-slim text text-white mt-small" (click)="backToEdit()">Back to Edit</button>
</div> -->

<div [ngClass]="{ 'standard-padding': padding, 'no-chrome': !chrome }">
    <!-- TODO: reimplement default temple after testing -->
    
    <app-default-resume-template *ngIf="selectedResume && selectedResume.resumeTemplateTypeId === 1" [resume]="selectedResume"></app-default-resume-template>
    <app-resume-template *ngIf="selectedResume && selectedResume.resumeTemplateTypeId === 2" [resume]="selectedResume"></app-resume-template>
    <app-uwgb-resume-template *ngIf="selectedResume && selectedResume.resumeTemplateTypeId === 3" [resume]="selectedResume"></app-uwgb-resume-template>
    <app-nwtc-resume-template *ngIf="selectedResume && selectedResume.resumeTemplateTypeId === 4" [resume]="selectedResume"></app-nwtc-resume-template>
</div>
<!-- 
<app-modal id="download-message" heading="Please Enable Pop-Ups">
    <p class="text text-500">
        Your browser is currently blocking pop-ups.  Please enable pop-ups for this site and download again.
    </p>
</app-modal> -->