import { Injectable } from "@angular/core";
import { AnalyticsModel, SectionWord } from "../models";

export enum WordType {
    Weak = 0,
    MixedTense = 1,
    Strong = 2,
    Action = 3,
    Metric = 4,
    RequiredSkill = 5,
    Repetition = 6,
    ActionNotFirst = 7,
    Required = 99
}

export enum SmartWordAnalyzer {
    none = 0,
    weakWords = 1,
    strongWords = 2,
    actionWords = 4,
    tense = 8,
    metrics = 16,
    skills = 32,
    required = 64,
    requiredSkills = 128,
    notable = strongWords | actionWords | metrics | skills,
    revise = weakWords | tense,
    all = weakWords | strongWords | actionWords | metrics | tense | skills | required | requiredSkills
}

export class SmartWordAnalyzerConfiguration {
    public weakWords = false;
    public strongWords = false;
    public actionWords = false;
    public tense = false;
    public metrics = false;
    public requiredSkills = false;
}

@Injectable()
export class SmartWordService {

    public static nonAlphaNumber = /[^a-zA-Z0-9-]/g;
    public static metrics = [
        /\$?\d+(?:,\d+)*(?:\.\d+)?\s*[%+]/g,
        /\$\d+(?:,\d{3})*(?:\.\d{2})?[km]{0,2}\+?/g,
        /\d+(?:,\d{3})*(?:\.\d{2})%/g,
        /\d+[km]\+/gi
    ];
    public static ly = /\b\w*ly\b/g;

    private html = /(<([^>]+)>)/ig;
    private closeP = /<p[^>]*>/g;
    private _value = '';

    get value(): string {
        return this._value;
    }
    set value(val: string) {
        if (!val) {
            this._value = '';
        } else {
            this._value = val.toLowerCase().replace(this.closeP, '</p>\n').replace(this.html, '');
        }
    }
    private _words: SectionWord[] = [];
    get words(): SectionWord[] {
        return this._words;
    }
    set words(value: SectionWord[]) {
        this._words = value ?? [];
    }

    public GetNotableWords(presentTense: boolean, settings: SmartWordAnalyzerConfiguration): NotableWord[] {
        const notes = new Set<NotableWord>();

        if (settings.strongWords || settings.weakWords || settings.actionWords) {
            this.words.forEach(word => {

                const strongWeak = this.getWords(word);

                // if (settings.weakWords) {
                //     const lywords = this.getlyWords();
                //     lywords.forEach(w => {
                //         notes.add(w);
                //     });
                // }
                strongWeak.forEach(s => {
                    notes.add(s);
                });

                if (strongWeak.length && word.strong && settings.actionWords) {
                    // only strong words can be action words
                    const action = this.getActionWords(word);
                    action.forEach(a => {
                        notes.add(a);
                    });
                }

                const reps = this.getRepetitionWords();
                reps.forEach(r => {
                    notes.add(r);
                });
            });
        }

        if (settings.actionWords) {
            const lines = this.value.split('\n');
            const actions = this.flatten(notes).filter(x => x.wordType === WordType.Action);
            lines.forEach((element: string) => {
                if (element.trim().length) {

                    const words = element.trim().toLocaleLowerCase().split(/[^a-zA-Z0-9-]/);
                    let ws = element.trim().split(/[^a-zA-Z0-9-]/)[0];
                    while (ws.length && SmartWordService.nonAlphaNumber.test(ws.substring(ws.length - 1))) {
                        ws = ws.substring(0, ws.length - 1);
                    }

                    const phrases = this.getIndicesOf(ws, element);
                    if (!actions.filter(x => x.word === ws).length) {
                        notes.add({
                            endIndex: phrases[0] + ws.length,
                            pastTense: !presentTense,
                            startIndex: phrases[0],
                            wordIndex: words.indexOf(ws.toLocaleLowerCase()),
                            word: ws,
                            wordType: WordType.ActionNotFirst
                        });
                    }
                }
            });
        }

        if (settings.metrics) {
            const metrics = this.getMetrics();
            metrics.forEach(m => notes.add(m));
        }

        if (settings.tense) {
            const arr = Array.from(notes);
            if (presentTense) {
                const past = arr.filter(n => n.pastTense === true && n.wordIndex < 3);
                past.forEach(word => {
                    notes.add({
                        endIndex: word.endIndex,
                        pastTense: word.pastTense,
                        startIndex: word.startIndex,
                        wordIndex: word.wordIndex,
                        word: word.word,
                        wordType: WordType.MixedTense
                    });
                });
            } else {
                const present = arr.filter(n => n.pastTense === false && n.wordIndex < 3);
                present.forEach(word => {
                    notes.add({
                        endIndex: word.endIndex,
                        pastTense: word.pastTense,
                        startIndex: word.startIndex,
                        wordIndex: word.wordIndex,
                        word: word.word,
                        wordType: WordType.MixedTense
                    });
                });
            }
        }

        return this.flatten(notes);
    }

    private getActionWords(word: SectionWord): NotableWord[] {
        const matches = new Set<NotableWord>();
        const instances: string[] = [];

        const lines = this.value.split('\n');
        lines.forEach((element: string) => {
            element = element.trim();

            const words = element.toLocaleLowerCase().split(/[^a-zA-Z0-9-]/);
            while (element.length && SmartWordService.nonAlphaNumber.test(element.substring(0, 1))) {
                element = element.substring(1);
            }

            const phrases = this.getIndicesOf(word.word, element);
            phrases.forEach(phrase => {
                if (!phrase) {
                    instances.push(phrase);
                    // if (instances.filter(x => x === phrase).length > 1)
                    // {
                    //     matches.add({
                    //         word: word.word,
                    //         startIndex: phrase,
                    //         endIndex: phrase + word.word.length,
                    //         wordIndex: words.indexOf(word.word.toLocaleLowerCase()),
                    //         wordType: WordType.Repetition,
                    //         pastTense: word.presentTense === undefined ? undefined : !word.presentTense
                    //     });    
                    // }
                    matches.add({
                        word: word.word,
                        startIndex: phrase,
                        endIndex: phrase + word.word.length,
                        wordIndex: words.indexOf(word.word.toLocaleLowerCase()),
                        wordType: WordType.Action,
                        pastTense: word.presentTense === undefined ? undefined : !word.presentTense
                    });
                }
            });
        });



        return this.flatten(matches);
    }

    private getRepetitionWords(): NotableWord[] {
        const matches = new Set<NotableWord>();
        const instances: string[] = [];

        const lines = this.value.split('\n');
        lines.forEach((element: string) => {
            element = element.trim();

            const words = element.toLocaleLowerCase().split(/[^a-zA-Z0-9-]/);
            while (element.length && SmartWordService.nonAlphaNumber.test(element.substring(0, 1))) {
                element = element.substring(1);
            }

            const word = element.split(/[^a-zA-Z0-9-]/)[0];
            const phrases = this.getIndicesOf(word, element);
            if (phrases.length) {

                instances.push(word);
                if (instances.filter(x => x === word).length > 1) {
                    matches.add({
                        word: word,
                        startIndex: phrases[0],
                        endIndex: phrases[0] + word.length,
                        wordIndex: words.indexOf(word.toLocaleLowerCase()),
                        wordType: WordType.Repetition,
                        pastTense: undefined
                    });
                }
            }
        });

        return this.flatten(matches);
    }

    private getWords(word: SectionWord): NotableWord[] {
        const matches = new Set<NotableWord>();

        const indices = this.getIndicesOf(word.word, this.value);
        const words = this.value.toLocaleLowerCase().split(/[^a-zA-Z0-9-]/);
        indices.forEach(i => {
            matches.add({
                word: word.word,
                startIndex: i,
                endIndex: i + word.word.length,
                wordIndex: words.indexOf(word.word.toLocaleLowerCase()),
                wordType: word.strong ? WordType.Strong : WordType.Weak,
                pastTense: (word.presentTense === undefined || word.presentTense === null) ? undefined : !word.presentTense
            });

        });
        return this.flatten(matches);
    }

    private getlyWords(): NotableWord[] {
        const matches = new Set<NotableWord>();

        let check;
        const m = SmartWordService.ly;
        const words = this.value.toLocaleLowerCase().split(/[^a-zA-Z0-9-]/);
        while ((check = m.exec(this.value))) {
            matches.add({
                word: check[0],
                startIndex: check.index,
                endIndex: check.index + check[0].length,
                wordIndex: words.indexOf(check[0].toLocaleLowerCase()),
                wordType: WordType.Weak,
                pastTense: undefined
            });
        }

        return this.flatten(matches);
    }

    private getMetrics(): NotableWord[] {
        const matches = new Set<NotableWord>();

        let check;
        for (let i = 0; i < SmartWordService.metrics.length; ++i) {
            const m = SmartWordService.metrics[i];
            const words = this.value.toLocaleLowerCase().split(' ');
            while ((check = m.exec(this.value))) {
                matches.add({
                    word: check[0],
                    startIndex: check.index,
                    endIndex: check.index + check[0].length,
                    wordIndex: words.indexOf(check[0].toLocaleLowerCase()),
                    wordType: WordType.Metric,
                    pastTense: undefined
                });
            }
        }

        return this.flatten(matches);
    }

    private getIndicesOf(word: string, searchIn: string) {
        if (word.length === 0) {
            return [];
        }
        let startIndex = 0;
        let index = 0;
        const indices = [];

        while ((index = searchIn.indexOf(word, startIndex)) > -1) {
            const previousCharacter = (index === 0) ? undefined : searchIn[index - 1];
            const nextCharacter = ((index + word.length) < searchIn.length) ? searchIn[index + word.length] : '';
            if ((previousCharacter === undefined || previousCharacter === ' ' || previousCharacter === '.' || previousCharacter.trim() === '') &&
                (nextCharacter === ' ' || nextCharacter === '.' || nextCharacter.trim() === '' || nextCharacter === ',')) {
                indices.push(index);
            }
            startIndex = index + word.length;
        }
        return indices;
    }

    private flatten(set: Set<NotableWord>): NotableWord[] {
        const arr: NotableWord[] = [];
        Array.from(set).forEach(s => {
            if (!arr.find(x => x.word === s.word && x.wordType === s.wordType)) {
                arr.push(s);
            }
        });
        return arr;
    }


    checkAnalytic(value: SmartWordAnalyzer, analyzer: SmartWordAnalyzer): boolean {
        return ((value ?? SmartWordAnalyzer.none) & analyzer) === analyzer;
    }

    checkWarnings(value: SmartWordAnalyzer, analyzer: SmartWordAnalyzer): boolean {
        return (analyzer === SmartWordAnalyzer.strongWords && this.useAnalyzer(value, analyzer)) ||
            (analyzer === SmartWordAnalyzer.weakWords && this.useAnalyzer(value, analyzer)) ||
            (analyzer === SmartWordAnalyzer.tense && this.useAnalyzer(value, analyzer)) ||
            (analyzer === SmartWordAnalyzer.actionWords && this.useAnalyzer(value, analyzer)) ||
            (analyzer === SmartWordAnalyzer.metrics && this.useAnalyzer(value, analyzer))
    }

    useAnalyzer(analyzer: SmartWordAnalyzer, check: SmartWordAnalyzer): boolean {
        return (analyzer & check) === check;
    }

    warningCount(value: SmartWordAnalyzer, analysis: AnalyticsModel): number {
        return (this.useAnalyzer(value, SmartWordAnalyzer.actionWords) ? ((analysis.counts.actions ?? 0) === 0 ? 1 : 0) : 0) +
            (this.useAnalyzer(value, SmartWordAnalyzer.metrics) ? ((analysis.counts.metrics ?? 0) === 0 ? 1 : 0) : 0) +
            (this.useAnalyzer(value, SmartWordAnalyzer.strongWords) ? ((analysis.counts.strongWords ?? 0) === 0 ? 1 : 0) : 0) +
            (this.useAnalyzer(value, SmartWordAnalyzer.tense) ? (analysis.counts.mixedTense ?? 0) : 0) +
            (this.useAnalyzer(value, SmartWordAnalyzer.weakWords) ? (analysis.counts.weakWords ?? 0) : 0);
    }

    analyzerCount(value: SmartWordAnalyzer, analyzer: SmartWordAnalyzer, analysis: AnalyticsModel): number | undefined {
        let count = undefined;
        if (this.useAnalyzer(value, analyzer)) {
            switch (analyzer) {
                case SmartWordAnalyzer.actionWords:
                    count = (count ?? 0) + (analysis.counts.actions ?? 0);
                    break;
                case SmartWordAnalyzer.metrics:
                    count = (count ?? 0) + (analysis.counts.metrics ?? 0);
                    break;
                case SmartWordAnalyzer.required:
                    count = (count ?? 0) + (analysis.counts.required ?? 0);
                    break;
                case SmartWordAnalyzer.skills:
                    count = (count ?? 0) + (analysis.counts.skills ?? 0);
                    break;
                case SmartWordAnalyzer.strongWords:
                    count = (count ?? 0) + (analysis.counts.strongWords ?? 0);
                    break;
                case SmartWordAnalyzer.tense:
                    count = (count ?? 0) + (analysis.counts.mixedTense ?? 0);
                    break;
                case SmartWordAnalyzer.weakWords:
                    count = (count ?? 0) + (analysis.counts.weakWords ?? 0);
                    break;
                default:
                    break;
            }
        }
        return count;
    }

    errorCount(value: SmartWordAnalyzer, analysis: AnalyticsModel): number | undefined {
        let count = undefined;
        const weakWords = this.analyzerCount(value, SmartWordAnalyzer.weakWords, analysis);
        const tense = this.analyzerCount(value, SmartWordAnalyzer.tense, analysis);
        const metrics = this.analyzerCount(value, SmartWordAnalyzer.metrics, analysis);
        const skills = this.analyzerCount(value, SmartWordAnalyzer.skills, analysis);
        const strongWords = this.analyzerCount(value, SmartWordAnalyzer.strongWords, analysis);
        const actionWords = this.analyzerCount(value, SmartWordAnalyzer.actionWords, analysis);
        const required = this.analyzerCount(value, SmartWordAnalyzer.required, analysis);

        if (weakWords !== undefined) {
            count = (count ?? 0) + weakWords;
        }
        if (tense !== undefined) {
            count = (count ?? 0) + tense;
        }
        if (metrics !== undefined) {
            count = (count ?? 0) + (metrics ? 0 : 1);
        }
        if (skills !== undefined) {
            count = (count ?? 0) + (skills < 5 ? 1 : 0) + (skills > 10 ? 1 : 0);
        }
        if (strongWords !== undefined) {
            count = (count ?? 0) + (strongWords ? 0 : 1);
        }
        if (actionWords !== undefined) {
            count = (count ?? 0) + (actionWords ? 0 : 1);
        }
        if (required !== undefined) {
            count = (count ?? 0) + required;
        }
        return count;
    }

}

export class NotableWord {
    public word!: string;
    public startIndex!: number;
    public endIndex!: number;
    public wordIndex!: number;
    public wordType!: WordType;
    public pastTense!: boolean | undefined;
}