import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Employer, OpportunityRequest, OpportunityType } from 'src/app/shared/models';
import { ModalService } from 'src/app/shared/services';
import { CDNService } from 'src/app/shared/services/cdn.service';

@Component({
  selector: 'app-opportunity-request-card',
  templateUrl: './opportunity-request-card.component.html',
  styleUrl: './opportunity-request-card.component.scss'
})
export class OpportunityRequestCardComponent {


  @Input()
  opportunityRequest: OpportunityRequest

  @Input()
  employer: Employer

  @Input()
  employerUrl: string

  constructor(
    private modals: ModalService,
    public cdn: CDNService,
    private router: Router,
  ) {

  }

  openModal(value: string) {
    this.modals.open(value);
  }

  createOpportunity(){
    this.router.navigate([ '/employers', this.employerUrl, 'create-request', this.opportunityRequest?.id ]);
  }

  chipColor(): string {
    let color: string;

    switch (this.opportunityRequest.opportunityTypeId) {
      case OpportunityType.Internship:
        color = 'orange';
        break;
      case OpportunityType.Employment:
        color = 'purple';
        break;
      case OpportunityType.Volunteering:
        color = 'green';
        break;
      case OpportunityType.Other:
        color = 'gray';
        break;
      case OpportunityType.CoOp:
        color = 'green-muted';
        break;
      case OpportunityType.JobShadowing:
      default:
        color = 'blue';
        break;
    }

    return color;
  }

}
