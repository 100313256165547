<h1>NWTC</h1>
<div class="resume-container flex-rows">
    <div class="text text-center heading-1">
        {{ resume?.fullName }}
    </div>
    <div class="text text-center heading-4">
        {{ subheading.join(' • ') }}
    </div>
    <div class="separator thick">
        &nbsp;
    </div>

    <ng-template ngFor let-s [ngForOf]="resume.sections | sort:'displayOrder':true">
        <ng-container *ngIf="s.sectionType === sectionType.Language; else skills">
            <div class="text heading-1" *ngIf="s.resumeSections?.length && s.resumeSections[0]?.languages?.length">
                {{ s?.overrideSectionName ?? s.name }}
            </div>
            <div class="separator" *ngIf="s.resumeSections?.length && s.resumeSections[0]?.languages?.length">
                &nbsp;
            </div>
        </ng-container>
        <ng-template #skills>
            <ng-container *ngIf="s.sectionType === sectionType.Skills; else generic">
                <div class="text heading-2" *ngIf="s.resumeSections?.length && s.resumeSections[0]?.skills?.length">
                    {{ s?.overrideSectionName ?? s.name }}
                </div>
                <div class="separator" *ngIf="s.resumeSections?.length && s.resumeSections[0]?.skills?.length">
                    &nbsp;
                </div>
            </ng-container>
            <ng-template #generic>
                <div class="text heading-2"
                    *ngIf="s.resumeSections?.length && !(summary === '' && s.name === 'Summary')">
                    {{ s?.overrideSectionName ?? s.name }}
                </div>
                <div class="separator" *ngIf="s.resumeSections.length && !(summary === '' && s.name === 'Summary')">
                    &nbsp;
                </div>
            </ng-template>
            <!-- TODO ADD EMAIL, LOCATION, REMOVE DUPLICATE NAME, ADD LINKEDIN PROFILE   -->
        </ng-template>

        <div *ngIf="s.sectionType === sectionType.General" class="text mb-small" [innerHTML]="summary">
        </div>

        <div *ngIf="s.sectionType === sectionType.Education" class="mb-small">
            <ng-template ngFor let-edu [ngForOf]="s.resumeSections | doublesort:'startDate':false:'resumeSectionId':false">
                <div class="text">
                    <div class="heading-4">
                        <span class="text-800">{{ edu.schoolName }}</span>, {{ edu.location }}
                    </div>
                    <div class="mb-small flex-columns">
                        <div class="mr-auto">
                            <i>{{ degreeName(edu) }}</i>
                        </div>
                        <div *ngIf="isFuture(edu.endDate)"><i>Expected Graduation</i> - {{ edu.endDate | date:'MMM y'}}</div>
                        <div *ngIf="!isFuture(edu.endDate)"> Graduated - {{ edu.endDate | date:'MMM y' }}</div>
                    </div>
                    <div class="mb-small">
                        <div *ngIf="edu.major?.length" class="mr-small flex-columns text">
                            <div class="mr-small">Major{{ edu.major.length > 1 ? '(s)' : '' }}:</div>
                            <div class="flex-columns">
                                <ng-template ngFor let-m [ngForOf]="edu.major" let-last="last">
                                    <div class="mr-small">{{ m }}{{ last ? '' : ',' }} </div>
                                </ng-template>
                            </div>
                        </div>
                        <div *ngIf="edu.minor?.length" class="mr-small flex-columns text">
                            <div *ngIf="edu.minor !== 'None'" class="mr-small">Minor{{ edu.minor?.length > 1 ?'(s)' :'' }}:</div>
                            <div class="flex-columns">
                                <div *ngFor="let m of edu.minor" class="mr-small">{{ m }}</div>
                            </div>
                        </div>
                        <div class="mr-small flex-rows text">
                            <div>
                                Related Coursework:
                            </div>
                            <div class="text text-400 mb-small" [innerHTML]="edu.description"></div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>

        <div *ngIf="s.sectionType === sectionType.Experience" class="mb-small">
            <ng-template ngFor let-ex
                [ngForOf]="s.resumeSections | doublesort:'startDate':false:'resumeSectionId':false" let-isLast="last">
                <div class="text heading-4 flex-columns text text-upper">
                    <span class="mr-auto text text-800">{{ ex.title }}</span>
                    <span *ngIf="ex.startDate && ex.endDate">{{ ex.startDate | date:'MMM y' }}&nbsp;-&nbsp;{{ ex.endDate
                        | date:'MMM y' }}</span>
                    <span *ngIf="ex.startDate && !ex.endDate">{{ ex.startDate | date:'MMM y'
                        }}&nbsp;-&nbsp;Present</span>
                    <span *ngIf="!ex.startDate && ex.endDate">{{ ex.endDate | date:'MMM y' }}</span>
                </div>
                <div class="text heading-4">
                    <i>{{ ex.employerName }},</i> {{ ex.location }}
                </div>

                <div class="text text-400" [innerHTML]="ex.description" [ngClass]="{ 'mb-small': !isLast }"></div>
            </ng-template>
        </div>

        <div *ngIf="s.sectionType === sectionType.Skills" class="mb-small flex-columns flex-wrap">
            <div *ngFor="let sk of s.resumeSections" class="text text-500 text-upper">
                <ng-template ngFor let-skill [ngForOf]="sk.skills" let-isFirst="first" let-isLast="last">
                    {{ skill }}{{ isLast ? '' : ',' }}
                </ng-template>
            </div>
        </div>

        <div *ngIf="s.sectionType === sectionType.Award" class="mb-small">
            <ul>
                <ng-template ngFor let-aw
                    [ngForOf]="s.resumeSections | doublesort:'startDate':false:'resumeSectionId':false">
                    <li>
                        <div class="flex-columns text">
                            <span class="mr-auto" *ngIf="aw.awardName && aw.awarder">
                                {{ [aw.awardName, aw.awarder].join(' - ') }}
                            </span>
                            <span class="mr-auto" *ngIf="aw.awardName && !aw.awarder">
                                {{ aw.awardName }}
                            </span>
                            <span class="mr-auto" *ngIf="!aw.awardName && aw.awarder">
                                {{ aw.awarder }}
                            </span>
                            <span class="mr-auto" *ngIf="!aw.awardName && !aw.awarder" [innerHTML]="aw.description">
                                {{ [aw.awardName, aw.awarder].join(' - ') }}
                            </span>

                            <span *ngIf="aw.startDate && aw.endDate">{{ aw.startDate | date:'MMM y' }}&nbsp;-&nbsp;{{
                                aw.endDate | date:'MMM y' }}</span>
                            <span *ngIf="aw.startDate && !aw.endDate">{{ aw.startDate | date:'MMM y' }}</span>
                            <span *ngIf="!aw.startDate && aw.endDate">{{ aw.endDate | date:'MMM y' }}</span>
                        </div>
                    </li>
                </ng-template>
            </ul>
        </div>

        <div *ngIf="s.sectionType === sectionType.Organization" class="mb-small">
            <ul>
                <ng-template ngFor let-org
                    [ngForOf]="s.resumeSections | doublesort:'startDate':false:'resumeSectionId':false"
                    let-isLast="last">
                    <li>
                        <div class="flex-columns text">
                            <span class="mr-auto">{{ [org.organizationName, org.schoolName].join(' - ') }}</span>
                            <span *ngIf="org.startDate && org.endDate">
                                {{org.startDate | date:'MMM y' }}-{{ org.endDate | date:'MMM y' }}
                            </span>
                            <span *ngIf="org.startDate && !org.endDate">
                                {{org.startDate | date:'MMM y' }}-Present
                            </span>
                            <span *ngIf="!org.startDate && org.endDate">
                                {{ org.endDate | date:'MMM y' }}
                            </span>
                            <span *ngIf="!org.startDate && !org.endDate" [innerHTML]="org.description">
                            </span>
                        </div>
                    </li>
                </ng-template>
            </ul>
        </div>

        <div *ngIf="s.sectionType === sectionType.Language" class="mb-small">
            <ng-template ngFor let-sk [ngForOf]="s.resumeSections | sort:'id':true" let-isFirst="first">
                <ul *ngIf="isFirst" class="languages">
                    <li class="text text-500" *ngFor="let lang of sk.languages">
                        {{lang}}
                    </li>
                </ul>
            </ng-template>
        </div>

    </ng-template>
</div>