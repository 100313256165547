<div #logo *ngIf="employer?.logoUrl" class="banner">
  <div class="banner-inner" [ngStyle]="{ 'background-image': 'url(' + cdn.toCDN(employer?.logoUrl, true) + ')' }">
    <div hidden>
      {{ employer?.name }}
    </div>
  </div>
</div>
<div class="flex-columns no-gap" style="width: -webkit-fill-available;">
  <div class="bg-cream pl-medium pr-medium py-large">
    <div class="mb-medium info">
      <div class="flex-rows ml-large" style="position: relative;">
          <div class="logo-wrapper">
              <div class="bg-white border-teal mb-small border border-round logo" *ngIf="employer?.smallLogoUrl">
                  <div class="logo-inner"
                      [ngStyle]="{ 'background-image': 'url(' + cdn.toCDN(employer?.smallLogoUrl, true) + ')' }">
                      <span class="sr-only">{{ employer?.name + ' logo' }}</span>
                  </div>
              </div>
          </div>

      </div>
    </div>
    <div class="flex-columns justify-between mb-medium">
      <div class="flex-columns">
        <div class="text text-title mb-small mr-small">
          Team
        </div>
        <div class="text text-900" *ngIf="user?.isEmployer && user?.employee?.employer?.id === employer?.id">
          {{employees.length}} results
        </div>
      </div>
      <button class="btn lime-green mr-small" [routerLink]="['/employers', this.employer?.urlName, 'team', 'new']"
        *ngIf="user?.isEmployer && user?.employee?.employer?.id === employer?.id">
        New
      </button>
    </div>

    <div class="flex-columns">

      <ng-template ngFor let-s [ngForOf]="employees">
        <div class="mb-medium profile-card bg-white pl-small pr-small py-small flex-rows">
          <div class="text card-title">
            {{ s.firstName }} {{ s.lastName }}
          </div>
          <div class="flex-columns mt-auto">
            <div class="flex-rows">
              <div class="text text-rounded-mplus text-400" *ngIf="s.positionName?.length">
                {{ s.positionName }}
              </div>
              <div class="text text-rounded-mplus text-400" *ngIf="s.departmentName?.length">
                {{ s.departmentName }}
              </div>
            </div>
            <div class="avatar mt-auto">
              <img [src]="cdn.toCDN(s.avatar, true)" alt="profile pic" />
            </div>
          </div>

          <!-- need to create an employee profile page
          <a class="profile btn btn-white mr-small border-teal" [routerLink]="['/employers', s.employee?.employerUrlName, 'team', s.urlName]">
            View Profile
          </a> -->
        </div>
      </ng-template>
    </div>
  </div>
  <div class="bg-teal pl-medium pr-medium py-large">
    <a class="flex-rows" [routerLink]="[ '/dashboard']">
      <img src="/assets/images/icons/dashboard.svg" style="height: 76px;" class="mx-auto" />
      <div class="text text-white mx-auto text-center mb-medium">
        Dashboard
      </div>
    </a>
    <div class="mb-medium">
      <div class="spacer spacer-white"></div>
    </div>
    <a class="flex-rows" [routerLink]="[ '/employers', employer?.urlName, 'meet']">
      <img src="/assets/images/icons/shake.svg" style="height: 43px;" class="mx-auto" />
      <div class="text text-white mx-auto text-center mb-medium mt-medium">
        Meetings & Feedback
      </div>
    </a>

    <div class="spacer spacer-white"></div>
    <div>
      <div class="text text-white text-900 heading-4 mb-small">
        Locations
      </div>
      <ng-template ngFor let-a [ngForOf]="employer?.addresses">
        <div class="mb-medium ">
          <div class="text text-white">
            {{ a}}
          </div>
        </div>
      </ng-template>
    </div>

  </div>
</div>
<!-- <div class="employer text mb-small heading-4">
  Members
</div>
<app-progress-bar class="mb-small" *ngIf="loadProgress" [value]="loadProgress" color="green"></app-progress-bar>
<div *ngIf="!loadProgress" class="text text-400 mb-small flex-columns flex-wrap">
  <div class="text mr-small mb-small" *ngFor="let user of employees">
      {{ user.firstName }} {{ user.lastName }}
  </div>
</div> -->