import { Component, Input } from '@angular/core';
import { User } from 'src/app/shared/models';
import packageJson from 'package.json';
import { AuthService } from '@auth0/auth0-angular';
import { GTMComponentComponent } from 'src/app/shared/components/gtmcomponent/gtmcomponent.component';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { UserService } from 'src/app/shared/services/user.service';
import { Router } from '@angular/router';
import { ToastService } from 'src/app/shared/services/toast.service';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends GTMComponentComponent {

  @Input()
  set user(value: User) {
    this._user = value;
  }
  get user(): User { 
    return this._user; 
  }

  constructor(
    public auth0: AuthService,
    public users: UserService,
    private router: Router,
    gtm: GoogleTagManagerService,
    snackbar: ToastService
  ) { 
    super(gtm, snackbar);

    this.users.loggedInObservable$.subscribe(() => {
      this._user = this.users.authenticatedUser;
    });
  }

  private _user: User;

  year() {
    return new Date().getFullYear();
  }

  async login() {
    this.users.clearProfile();
    this.handle('login', 'home', 'click', 'Log In');

    await this.auth0.loginWithRedirect({
      appState: { 
        target: '/dashboard' 
      },      
      authorizationParams: {
        screen_hint: 'login'
      }
    });
  }

  public version: string = packageJson.version;
}
