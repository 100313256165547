<ng-container>
    <div class="mb-small headers" *ngIf="section.analyze">
        <a class="col text serif text-white bg-green no-link ml-auto mr-auto" [ngClass]="{'active': active === 'Description' }" 
            [attr.data-content]="'✓'" (click)="active = 'Description'" *ngIf="section.default?.length"
            data-intro="Here, the suggestions are tailored to the education section, providing information relevant specifically to that area."
            data-title="Examples Section"
            data-step="3">
            Examples
        </a>
        <a class="col text serif text-white bg-teal no-link ml-auto mr-auto"
            [ngClass]="{'active': active === 'Notable' }" [attr.data-content]="countNotableWords()"
            (click)="active = 'Notable'"
            data-intro="Each section has different types of notable suggestions. Click on 'Notable' to learn more."
            data-title="Did Well Section"
            data-step="1">
            Did Well
        </a>
        <a class="col text serif text-white bg-orange no-link ml-auto mr-auto" [ngClass]="{'active': active === 'Revise' }"
            [attr.data-content]="countRevisions()" (click)="active = 'Revise'" *ngIf="section.analyze"
            data-intro="We provide feedback on what you can improve in this section based on the contrasts."
            data-title="Need Change Section"
            data-step="2">
            Need Change
        </a>
    </div>
    <div class="results notable p-2" *ngIf="active === 'Notable'"
        data-intro="Here, you can see what you did well in your experience section. This breakdown highlights action verbs, strong words, metrics, and formatting. Each resume section has its own set of notable suggestions."
        data-title="Did Well Sections"
        data-step="4">
        <div class="bg-white border border-teal border-round p-2" *ngIf="smartWords.useAnalyzer(section.analyze, actionWords) && analytics.actionWords.length">
            <div class="text text-upper text-800 mb-small">
                Action Words
            </div>
            <div class="text mb-small">
                Nice, you're starting lines with action words!
            </div>
            <div class="text mb-small">
                Action words are also strong words, doubling up on these is great!
            </div>
            <div class="word-list">
                <div class="border border-teal p-2" *ngFor="let word of analytics.actionWords;" (click)="selectWord(word.word)">
                    {{ word.word }}
                </div>
            </div>
        </div>
        <div class="bg-white border border-teal border-round p-2" *ngIf="smartWords.useAnalyzer(section.analyze, strongWords) && analytics.strongWords.length">
            <div class="text text-upper text-800 mb-small">
                Strong Words
            </div>
            <div class="text mb-small">
                These words showcase your accomplishments and skills effectively.
            </div>
            <div class="word-list">
                <div class="border border-green p-2" *ngFor="let word of analytics.strongWords;" (click)="selectWord(word.word)">
                    {{ word.word }}
                </div>
            </div>
        </div>
        <div class="bg-white border border-blue border-round p-2" *ngIf="smartWords.useAnalyzer(section.analyze, metrics) && analytics.metricWords.length">
            <div class="text text-upper text-800 mb-small">
                Metrics
            </div>
            <div class="text mb-small">
                Metrics demonstrate the tangible impact of your work.
            </div>
            <div class="word-list">
                <div class="border border-blue p-2" *ngFor="let word of analytics.metricWords;" (click)="selectWord(word.word)">
                    {{ word.word }}
                </div>
            </div>
        </div>
        <div class="bg-white border border-teal border-round p-2" *ngIf="smartWords.useAnalyzer(section.analyze, skills) && (analytics.skills.length > 4 && analytics.skills.length < 11)">
            <div class="text text-upper text-800 mb-small">
                Skills
            </div>
            <div class="text">
                You have a good number of skills.
            </div>
        </div>
        <div class="bg-white border border-teal border-round p-2" *ngIf="smartWords.useAnalyzer(section.analyze, tense) && !analytics.mixedTense.length">
            <div class="text text-upper text-800 mb-small">
                Tense
            </div>
            <div class="text">
                You are using the right tense in your resume.
            </div>
        </div>
    </div>
    <div class="results notable p-2" *ngIf="active === 'Revise'"
        data-intro="Here, you can see what to change in the experience section of your resume. This breakdown highlights words in a negative color in your description. Revision suggestions include weak words, incorrect tense, repetition, lack of metrics, and absence of strong words. Each resume section has its own format for revision suggestions."
        data-title="Need Change Sections"
        data-step="5">
        <div class="bg-white border border-teal border-round p-2 mb-small" *ngIf="smartWords.useAnalyzer(section.analyze, actionWords) && analytics.repitition.length">
            <div class="text text-upper text-800 mb-small">
                Repitition
            </div>
            <div class="text mb-small">
                Avoid repeating action words.
            </div>
            <div class="text mb-small">
                These words help you stand out, but try to use variations.
            </div>
            <div class="word-list">
                <div class="border border-orange p-2" *ngFor="let word of analytics.repitition;" (click)="selectWord(word.word)">
                    {{ word.word }}
                </div>
            </div>
        </div>

        <div class="bg-white border border-teal border-round p-2 mb-small" *ngIf="smartWords.useAnalyzer(section.analyze, actionWords) && analytics.actionFirst.length">
            <div class="text text-upper text-800 mb-small">
                Start lines with action words
            </div>
            <div class="text mb-small">
                Every line should begin with an action word.
            </div>
            <div class="text mb-small">
                These words help you stand out, but try to use variations.
            </div>
            <div class="word-list">
                <div class="border border-orange p-2" *ngFor="let word of analytics.actionFirst;" (click)="selectWord(word.word)">
                    {{ word.word }}
                </div>
            </div>
        </div>

        <div class="bg-white border border-teal border-round p-2 mb-small" *ngIf="smartWords.useAnalyzer(section.analyze, weakWords) && analytics.weakWords.length">
            <div class="text text-upper text-800 mb-small">
                Suggest Change
            </div>
            <div class="text mb-small">
                Avoid overused, passive, cliches words on your resume. Use specific, action oriented verbs to make your
                skills and experience stand out.
            </div>
            <div class="text mb-small">
                These words won't help you stand out, and resume parsers may flag them negatively.
            </div>
            <div class="word-list">
                <div class="border border-orange p-2" *ngFor="let word of analytics.weakWords;" (click)="selectWord(word.word)">
                    {{ word.word }}
                </div>
            </div>
        </div>
        <div class="bg-white border border-teal border-round p-2 mb-small" *ngIf="smartWords.useAnalyzer(section.analyze, tense) && analytics.mixedTense.length">
            <div class="text text-upper text-800 mb-small">
                Tense
            </div>
            <div class="texst mb-small">
                Pay attention to tense. If you're currently in a role or enrolled in school, use present tense. 
                If it's a past experience, use past tense. 
            </div>
            <div class="text mb-small">
                **Please note that some words may appear present even though they refer to past experiences, so suggestions may not be 100% accurate.
            </div>
            <div class="word-list">
                <div class="border border-red p-2" *ngFor="let word of analytics.mixedTense;" (click)="selectWord(word.word)">
                    {{ word.word }}
                </div>
            </div>
        </div>
        <div class="bg-white border border-teal border-round text p-2 mb-small" *ngIf="smartWords.useAnalyzer(section.analyze, metrics) && !analytics.metricWords.length">
            Consider adding metrics (Number: $, %, whole) to this section to demonstrate the tangible impact of your
            work.
        </div>

        <div class="bg-white border border-teal border-round text p-2 mb-small" *ngIf="smartWords.useAnalyzer(section.analyze, skills) && analytics.skills.length < 5">
            Too few skills. There should be at least 5 skills selected.
        </div>
        <div class="bg-white border border-teal border-round text p-2 mb-small" *ngIf="smartWords.useAnalyzer(section.analyze, skills) && analytics.skills.length > 10">
            Too many skills. There should be no more than 10 skills selected.
            Too many skills loses focus on what is important for this particular resume.
        </div>

    </div>
    <div class="results description" 
        data-intro="This section of our resume builder focuses on AI. Here, you can upload a job description, and our system will extract all relevant soft and hard skills. The other AI feature uses your major and fields of interest to provide examples for your description, helping you generate ideas."
        data-title="Examples Section"
        data-step="6"
        *ngIf="active === 'Description'">

        <div *ngIf="!jobDescription?.skills?.length" class="text p-2">
            Attach a job description to this resume to see how the skills compare.
        </div>
        
        <div class="text text-upper text-800 mb-small p-2" *ngIf="jobDescription?.skills?.length">
            Job Skills
        </div>

        <div class="word-list mb-small p-2" *ngIf="jobDescription?.skills?.length">
            <div class="text border border-teal mr-small mb-small" *ngFor="let skill of jobDescription?.skills" (click)="selectWord(skill)">
                {{ skill }}
            </div>
        </div>

        <div *ngIf="section?.default?.length" class="text p-2">
            <div class="text text-500">Description Examples</div>
            <div [innerHTML]="section.default"></div>
        </div>
    </div>
</ng-container>

<!-- <div *ngIf="section.analyze">
    <app-toggle-bar [ngClass]="{'negative' : suggestions}" [onText]="'Notable'" [offText]="'Revise'"
        [isOn]="!suggestions" (toggled)="toggleViewPositiveNegativeSuggestions()" size="medium"></app-toggle-bar>
</div> -->
<!-- <ng-container *ngIf="smartWords.useAnalyzer(section.analyze, actionWords)">
    <div class="rounded-corners mb-small suggestions" [ngClass]="{ 'expanded': actionsExpanded }"
        (click)="actionsExpanded = !actionsExpanded"
        [hidden]="(!suggestions && !smartWords.analyzerCount(section.analyze, actionWords, analytics)) || (suggestions && smartWords.analyzerCount(section.analyze, actionWords, analytics))">
        <div class="text tile-header"
            [ngClass]="{ 'text-warning': !smartWords.analyzerCount(section.analyze, actionWords, analytics), 'mb-small': !actionsExpanded }">
            Action Words <span class="super-script">({{ smartWords.analyzerCount(section.analyze, actionWords, analytics) }})</span>
        </div>
        <div *ngIf="!smartWords.analyzerCount(section.analyze, actionWords, analytics)"
            class="text tile-header mb-small text-warning">
            Start each bullet point with a strong action verb for maximum impact, making your achievements stand
            out to employers.
        </div>
        <div *ngIf="smartWords.analyzerCount(section.analyze, actionWords, analytics)" class="flex-columns flex-wrap">
            <div *ngFor="let word of analytics.actionWords;" style="color: hsl(144, 55%, 30%);"
                class="text mb-small mr-small mb-small">
                {{ word.word }}
            </div>
            <div class="text">
                Nice, you're starting lines with action words!
            </div>
        </div>
    </div>
</ng-container> -->

<!-- <ng-container *ngIf="smartWords.useAnalyzer(section.analyze, metrics)">
    <div [hidden]="(!suggestions && !smartWords.analyzerCount(section.analyze, metrics, analytics)) || (suggestions && smartWords.analyzerCount(section.analyze, metrics, analytics))"
        class="rounded-corners mb-small suggestions" [ngClass]="{ 'expanded': metricsExpanded }"
        (click)="metricsExpanded = !metricsExpanded">
        <div class="text tile-header"
            [ngClass]="{ 'text-warning': !smartWords.analyzerCount(section.analyze, metrics, analytics), 'mb-small': !metricsExpanded }">
            Metrics <span class="super-script">({{ smartWords.analyzerCount(section.analyze, metrics, analytics) }})</span>
        </div>
        <div *ngIf="smartWords.analyzerCount(section.analyze, metrics, analytics)" class="flex-columns flex-wrap">
            <div *ngFor="let x of analytics.metricWords" class="text text-blue mb-small mr-small">
                {{ x.word }}
            </div>
            <p class="text">
                Metrics demonstrate the tangible impact of your work.
            </p>
        </div>

        <div class="text tile-header mb-small text-warning"
            *ngIf="!smartWords.analyzerCount(section.analyze, metrics, analytics)">
            Consider adding metrics (Number: $, %, whole) to this section to demonstrate the tangible impact of
            your work.
        </div>
    </div>
</ng-container> -->

<!-- <ng-container *ngIf="smartWords.useAnalyzer(section.analyze, strongWords)">
    <div [hidden]="(!suggestions && !smartWords.analyzerCount(section.analyze, strongWords, analytics)) || (suggestions && smartWords.analyzerCount(section.analyze, strongWords,analytics))"
        class="rounded-corners mb-small suggestions" [ngClass]="{ 'expanded': strongExpanded }"
        (click)="strongExpanded = !strongExpanded">
        <div class="text tile-header"
            [ngClass]="{'text-warning': !smartWords.analyzerCount(section.analyze, strongWords, analytics), 'mb-small': !strongExpanded }">
            Strong Words <span class="super-script">({{ smartWords.analyzerCount(section.analyze, strongWords, analytics) }})</span>
        </div>
        <div *ngIf="!smartWords.analyzerCount(section.analyze, strongWords, analytics)"
            class="text tile-header mb-small text-warning">
            Consider incorporating strong action verbs in your resume to showcase your accomplishments and
            skills effectively.
        </div>
        <div class="flex-columns flex-wrap">
            <div *ngFor="let word of analytics.strongWords" style="color: hsl(115, 44%, 58%);"
                class="text mb-small mr-small">
                {{ word.word }}
            </div>
        </div>
        <div *ngIf="smartWords.analyzerCount(section.analyze, actionWords, analytics)"
            class="text tile-header mb-small">
            Action words are also strong words, doubling up on these is great!
        </div>
        <div class="text tile-footer" *ngIf="!suggestions">
            These words showcase your accomplishments and skills effectively.
        </div>
    </div>
</ng-container> -->

<!-- <ng-container *ngIf="smartWords.useAnalyzer(section.analyze, tense)">
    <div [hidden]="(!suggestions && smartWords.analyzerCount(section.analyze, tense, analytics)) || (suggestions && !smartWords.analyzerCount(section.analyze, tense, analytics))"
        class="rounded-corners mb-small suggestions" [ngClass]="{ 'expanded': tenseExpanded }"
        (click)="tenseExpanded = !tenseExpanded">
        <div class="text tile-header"
            [ngClass]="{'text-warning': smartWords.analyzerCount(section.analyze, tense, analytics), 'mb-small': !tenseExpanded }">
            Mixed Tense <span class="super-script">({{ smartWords.analyzerCount(section.analyze, tense, analytics) }})</span>
        </div>
        <div class="flex-columns flex-wrap">
            <div *ngFor="let word of analytics.mixedTense" style="color: hsl(0, 69%, 56%);"
                class="text  mb-small mr-small">
                {{ word.word }}
            </div>
        </div>

        <p class="text"
            *ngIf="smartWords.analyzerCount(section.analyze, tense, analytics) && isCurrent">
            You are still in active with this organization, so do not use past tense (Verbs ending in “ed”)
        </p>
        <p class="text"
            *ngIf="smartWords.analyzerCount(section.analyze, tense, analytics) && !isCurrent">
            You are no longer active with this organization, so use past tense (Verbs ending in “ed”)
        </p>
        <p class="text" *ngIf="!smartWords.analyzerCount(section.analyze, tense, analytics)">
            You are using the right tense in your resume.
        </p>
    </div>
</ng-container> -->

<!-- <ng-container *ngIf="smartWords.useAnalyzer(section.analyze, weakWords)">
    <div [hidden]="(!suggestions && smartWords.analyzerCount(section.analyze, weakWords, analytics)) || (suggestions && !smartWords.analyzerCount(section.analyze, weakWords, analytics))"
        class="rounded-corners mb-small suggestions" [ngClass]="{ 'expanded': weakExpanded }"
        (click)="weakExpanded = !weakExpanded">
        <div class="text tile-header"
            [ngClass]="{'text-warning': smartWords.analyzerCount(section.analyze, weakWords, analytics), 'mb-small': !actionsExpanded }">
            Avoided Words <span class="super-script">({{ smartWords.analyzerCount(section.analyze, weakWords, analytics) }})</span>
        </div>
        <div class="flex-rows flex-wrap">
            <div class="text text-500 mb-small">
                Avoid overused, passive, cliches words on your resume. Use specific, action oriented verbs to
                make your skills and experience stand out. <br />
                Remove or replace the following:
            </div>
            <div class="flex-columns flex-wrap">
                <span *ngFor="let w of analytics.weakWords" style="color: hsl(0, 100%, 29%);" class="text mb-small mr-small">
                   <span (click)="selectWord(w.word)">
                    {{ w.word }}
                   </span> 
                </span>
            </div>
            <div class="text">
                These words won't help you stand out, and resume parsers may flag them negatively.
            </div>
        </div>
    </div>
</ng-container> -->

<!-- <ng-container *ngIf="smartWords.useAnalyzer(section.analyze, skills)">
    <div [hidden]="(suggestions && (smartWords.analyzerCount(section.analyze, skills, analytics) < 4 || smartWords.analyzerCount(section.analyze, skills, analytics) < 11) ) || (!suggestions && (smartWords.analyzerCount(section.analyze, skills, analytics) < 5 || smartWords.analyzerCount(section.analyze, skills, analytics) > 10))"
        class="rounded-corners mb-small suggestions" [ngClass]="{ 'expanded': skillsExpanded }"
        (click)="skillsExpanded = !skillsExpanded">
        <div class="text tile-header"
            [ngClass]="{'text-warning': smartWords.analyzerCount(section.analyze, skills, analytics) < 5 || smartWords.analyzerCount(section.analyze, skills, analytics) > 10, 'mb-small': !skillsExpanded }">
            Skills <span class="super-script">({{ smartWords.analyzerCount(section.analyze, skills, analytics)
                }})</span>
        </div>
        <div *ngIf="smartWords.analyzerCount(section.analyze, skills, analytics) < 5"
            class="text tile-header mb-small text-warning">
            Too few skills. There should be at least 5 skills selected.
        </div>
        <div *ngIf="smartWords.analyzerCount(section.analyze, skills, analytics) > 10"
            class="text tile-header mb-small text-warning">
            Too many skills. There should be no more than 10 skills selected.
        </div>
        <div *ngIf="smartWords.analyzerCount(section.analyze, skills, analytics) > 10"
            class="text tile-header mb-small">
            Too many skills loses focus on what is important for this particular resume.
        </div>
        <div class="text tile-footer">
            <p>
                There should be no more than 10 skills selected.
            </p>
        </div>
    </div>
</ng-container> -->