import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { StudentApiConfig } from '../api/student.api.config';
import { AttributeValue, Availability, IdName, StudentProfileSummary } from '../models';
import { Experience } from '../models/experience.model';
import { Student } from '../models/student.model';
import { StudentOpportunity, StudentOpportunityApplication } from '../models/studentOpportunity.model';

@Injectable()
export class StudentService {

    private rtypes: IdName[] = [ {
        id: 0,
        name: 'Resume'
    }, {
        id: 1,
        name: 'Cover Letter',
    }, {
        id: 2,
        name: 'Video Resume',
    }, {
        id: 3,
        name: 'Transcript'
    } ];
    public get resumeTypes(): IdName[] {
        return this.rtypes;
    }

    constructor(private http: HttpClient) { }

    public getAttributes(): Promise<AttributeValue[]> {
        return lastValueFrom(this.http.get<AttributeValue[]>(StudentApiConfig.getFullUrl('attributes')));
    }

    public getCandidates(): Promise<any[]> {
        return lastValueFrom(this.http.get<any[]>(StudentApiConfig.getFullUrl('candidates?maxSize=10')));
    }

    public get(): Promise<Student[]> {
        return lastValueFrom(this.http.get<Student[]>(StudentApiConfig.getFullUrl('')));
    }

    public getStudent(urlName: string): Promise<Student> {
        return lastValueFrom(this.http.get<Student>(StudentApiConfig.getFullUrl(urlName)));
    }

    public getStudentProfileSummary(urlName: string): Promise<StudentProfileSummary> {
        return lastValueFrom(this.http.get<StudentProfileSummary>(
            StudentApiConfig.getFullUrl(`${urlName}/profile/summary`)));
    }

    public getOpportunity(urlName: string, id: number): Promise<StudentOpportunity> {
        return lastValueFrom(this.http.get<StudentOpportunity>(StudentApiConfig.getFullUrl(`${urlName}/opportunity/${id}`)));
    }

    public getStudentResume(urlName: string): Promise<Student> {
        return lastValueFrom(this.http.get<Student>(StudentApiConfig.getFullUrl(`${urlName}/profile/resume`)));
    }

    public getOpportunities(urlName: string, employerUrlName: string, 
        max: number, filter: string, filterText: string, sort: string): Promise<StudentOpportunity[]> {
        let url = StudentApiConfig.getFullUrl(`${urlName}/opportunity`);
        const params = [];
        if (employerUrlName) {
            params.push(`employer=${employerUrlName}`);
        }
        if (max) {
            params.push(`max=${max}`);
        }
        if (filter) {
            params.push(`hFilter=${filter}`);
        }
        if (filterText) {
            params.push(`filterText=${filterText}`);
        }
        if (sort) {
            params.push(`sort=${sort}`);
        }
        if (params.length) {
            url += `?${params[0]}`;
            for (let i = 1; i < params.length; ++i) {
                url += `&${params[i]}`;
            }
        }
        return lastValueFrom(this.http.get<StudentOpportunity[]>(url));
    }

    public filterOpportunities(urlName: string, employerUrlName: string | undefined = undefined,
        max: number | undefined = undefined, 
        filter: string | undefined = undefined, filterText: string | undefined = undefined, 
        sort: string | undefined = undefined, industry: string | undefined = undefined, location: string | undefined = undefined, 
        type: number | undefined = undefined, featured: boolean | undefined = undefined): Promise<StudentOpportunity[]> {
        let url = StudentApiConfig.getFullUrl(`${urlName}/opportunity`);
        const params = [];
        if (employerUrlName) {
            params.push(`employer=${employerUrlName}`);
        }
        if (max) {
            params.push(`max=${max}`);
        }
        if (filter) {
            params.push(`hFilter=${filter}`);
        }
        if (filterText) {
            params.push(`filterText=${filterText}`);
        }
        if (sort) {
            params.push(`sort=${sort}`);
        }
        if (industry && industry.length) {
            params.push(`industryFilter=${industry}`);
        }
        if (location && location.length) {
            params.push(`locationFilter=${location}`);
        }
        if (type) {
            params.push(`opportunityType=${type}`);
        }
        if (featured) {
            params.push('featured=true')
        }
        if (params.length) {
            url += `?${params[0]}`;
            for (let i = 1; i < params.length; ++i) {
                url += `&${params[i]}`;
            }
        }
        return lastValueFrom(this.http.get<StudentOpportunity[]>(url));
    }

    public getFavorites(
        urlName: string,
        max: number,
        sort: string): Promise<StudentOpportunity[]> {
        let url = StudentApiConfig.getFullUrl(`${urlName}/opportunity`);
        const params = [];
        params.push('onlyInterested=true');
        if (max) {
            params.push(`max=${max}`);
        }
        if (sort) {
            params.push(`sort=${sort}`);
        }
        if (params.length) {
            url += `?${params[0]}`;
            for (let i = 1; i < params.length; ++i) {
                url += `&${params[i]}`;
            }
        }
        return lastValueFrom(this.http.get<StudentOpportunity[]>(url));
    }

    public makePrivate(urlName: string): Promise<Student> {
        return lastValueFrom(this.http.post<Student>(
            StudentApiConfig.getFullUrl(`${urlName}/privacy?privacy=false`), {}));
    }
    public makePublic(urlName: string): Promise<Student> {
        return lastValueFrom(this.http.post<Student>(
            StudentApiConfig.getFullUrl(`${urlName}/privacy?privacy=true`), {}));
    }

    public updateAttribute(urlName: string, name: string, value: string): Promise<boolean> {
        return lastValueFrom(this.http.post<boolean>(
            StudentApiConfig.getFullUrl(`${urlName}/attributes`), {
            name,
            value
        }));
    }

    public deleteResume(urlName: string, resumeId: number): Promise<boolean> {
        return lastValueFrom(this.http.delete<boolean>(
            StudentApiConfig.getFullUrl(`${urlName}/resume/${resumeId}`)));
    }

    public removeVideo(urlName: string): Promise<boolean> {
        return lastValueFrom(this.http.delete<boolean>(
            StudentApiConfig.getFullUrl(`${urlName}/video`)));
    }

    public removeExperience(urlName: string, experienceId: number): Promise<boolean> {
        return lastValueFrom(this.http.delete<boolean>(
            StudentApiConfig.getFullUrl(`${urlName}/experience/${experienceId}`)));
    }

    public favorite(urlName: string, id: number): Promise<boolean> {
        return lastValueFrom(this.http.get<boolean>(StudentApiConfig.getFullUrl(`${urlName}/opportunity/${id}/favorite`)))
    }
    public applyStudent(urlName: string, id: number, opportunity: StudentOpportunityApplication): Promise<StudentOpportunityApplication> {
        return lastValueFrom( this.http.post<StudentOpportunityApplication>(StudentApiConfig.getFullUrl(`${urlName}/opportunity/${id}/apply`), opportunity));
    }

    public updateStudentOpportunity(urlName: string, opportunity: StudentOpportunity): Promise<StudentOpportunity>{
        return lastValueFrom(this.http.post<StudentOpportunity>(StudentApiConfig.getFullUrl(`${urlName}/student-opportunity`), opportunity));
    }
    public acceptStudent(urlName: string, id: number): Promise<boolean> {
        return lastValueFrom(this.http.get<boolean>(StudentApiConfig.getFullUrl(`${urlName}/opportunity/${id}/accept`)));
    }
    public rejectStudent(urlName: string, id: number): Promise<boolean> {
        return lastValueFrom(this.http.get<boolean>(StudentApiConfig.getFullUrl(`${urlName}/opportunity/${id}/reject`)));
    }
    public withdrawStudent(urlName: string, id: number): Promise<boolean> {
        return lastValueFrom(this.http.get<boolean>(StudentApiConfig.getFullUrl(`${urlName}/opportunity/${id}/withdraw`)));
    }
    public completeOpportunity(urlName: string, id: number): Promise<boolean> {
        return lastValueFrom(this.http.get<boolean>(StudentApiConfig.getFullUrl(`${urlName}/opportunity/${id}/complete`)));
    }
    public addExperience(urlName: string, value: Experience): Promise<Experience> {
        return lastValueFrom(this.http.post<Experience>(StudentApiConfig.getFullUrl(`${urlName}/experience`), value));
    }
    public updateExperience(urlName: string, value: Experience): Promise<Experience> {
        return lastValueFrom(this.http.post<Experience>(StudentApiConfig.getFullUrl(`${urlName}/experience/${value.id}`), value));
    }
    public updateCalendar(urlName: string, availability: Availability): Promise<boolean> {
        return lastValueFrom(this.http.post<boolean>(StudentApiConfig.getFullUrl(`${urlName}/calendar`), availability));
    }
    public getCultures(urlName: string): Promise<string[]> {
        return lastValueFrom(this.http.get<string[]>(StudentApiConfig.getFullUrl(`${urlName}/cultures`)));
    }
    public getExperience(urlName: string): Promise<Experience[]> {
        return lastValueFrom(this.http.get<Experience[]>(StudentApiConfig.getFullUrl(`${urlName}/experience`)));
    }
    public getCalendar(urlName: string): Promise<Availability> {
        return lastValueFrom(this.http.get<Availability>(StudentApiConfig.getFullUrl(`${urlName}/calendar`)));
    }
    public getStudies(options: StudyOptions | undefined): Promise<string[]> {
        let params = new HttpParams();
        if (options) {
            if (options.degree) {
                params = params.append('degree', options.degree);
            }
            if (options.majors) {
                params = params.append('includeMajors', true);
            }
            if (options.minors) {
                params = params.append('includeMinors', true);
            }
        }
        return lastValueFrom(this.http.get<string[]>(StudentApiConfig.getFullUrl('study/names'), { params }));
    }

    public addProfile(urlName: string, type: string, val: string): Promise<boolean> {
        return lastValueFrom(this.http.put<boolean>(
            StudentApiConfig.getFullUrl(`${urlName}/profile/${encodeURIComponent(type)}`), {
            value: val
        }));
    }
    public removeProfile(urlName: string, type: string, val: string): Promise<boolean> {
        return lastValueFrom(this.http.delete<boolean>(
            StudentApiConfig.getFullUrl(`${urlName}/profile/${encodeURIComponent(type)}/${encodeURIComponent(val)}`)));
    }
    public tag(urlName: string): Promise<boolean> {
        return lastValueFrom(this.http.get<boolean>(
            StudentApiConfig.getFullUrl(`${urlName}/tag`)));
    }

    public updateProfileScore(urlName: string, score: number): Promise<Student> {
        return lastValueFrom(this.http.patch<Student>(StudentApiConfig.getFullUrl(`${urlName}/profile/score`), { id: 88, value: score.toString() }));
    }
}

export class StudyOptions {
    degree?: number | undefined;
    majors?: boolean | undefined;
    minors?: boolean | undefined;
}