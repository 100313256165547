<div class="prospect-card rounded-corners">
  <div class="opportunity mb-medium" *ngIf="prospect.opportunity">
    <div class="flex-columns">
      <a class="text heading-5"
        [routerLink]="['/employers', prospect.opportunity.employerUrlName, 'opportunity', prospect.opportunity.urlName]">
        {{ prospect.opportunity.title }}
      </a>
      <div class="ml-auto mr-small text text-400">
        {{ prospect.appliedDate | date }}
      </div>
      <div class="mr-medium text text-300 text-green-shadow">
        ({{ prospect.score | number }})
      </div>
    </div>
  </div>
  <div class="prospect mb-medium">
    <div class="avatar mt-auto mb-auto mr-small">
      <app-avatar-image [size]="'md'"
        [image]="cdn.toCDN(prospect?.student?.avatarUrl ?? prospect?.student?.schoolLogoUrl, true)"></app-avatar-image>
    </div>
    <div class="mt-auto mb-auto mr-small">
      <div class="text heading-5">
        {{prospect?.student?.firstName}} {{prospect?.student?.lastName}}
      </div>
      <div class="text mb-small">
        {{prospect?.student?.schoolName}}
      </div>
      <div class="text">
        {{prospect?.student?.majors.join(',')}}
      </div>
      <div class="text">
        <a (click)="stopProp($event)" [href]="'mailto:' + prospect?.student?.email">{{prospect?.student?.email}}</a>
      </div>
    </div>
    <div class="more ml-auto" *ngIf="!prospect.accepted && !prospect.rejected">
      <div div class="flex-rows">
        <a [routerLink]="['/employers', prospect?.opportunity?.employerUrlName, 'opportunity', prospect?.opportunity?.urlName, prospect?.student?.schoolUrlName, prospect?.student.urlName]" class="button dark-green mb-small">View Profile</a>

        <button class="button btn-teal mb-small" type="button" (click)="openModal(acceptId, $event)">
          Accept for Position
        </button>

        <button class="button orange outline mt-auto" type="button" (click)="openModal(rejectId, $event)">
          Decline for Position
        </button>
      </div>
    </div>
    <div class="more ml-auto" *ngIf="prospect.accepted || prospect.rejected">
      <div div class="flex-rows">
        <div class="text mb-small ml-auto mr-auto"
          [ngClass]="{ 'text-green': prospect.accepted, 'text-green-shadow': prospect.rejected }">
          {{ prospect.accepted ? 'Accepted' : 'Rejected' }}
        </div>
        <a [routerLink]="['/students', prospect.student.urlName]" class="button dark-green mb-small">View Profile</a>
      </div>
    </div>
  </div>
  <div class="documents flex-columns justify-even">
    <a class="button dark-green" [href]="cdn.toCDN(prospect.resumeUrl, true)" (click)="openDocument(prospect.resumeUrl, $event)" *ngIf="prospect.resumeUrl?.length">
      Resume
    </a>
    <a class="button button btn-blue" [href]="prospect.coverletterUrl" (click)="openDocument(prospect.coverletterUrl, $event)" target="_blank" *ngIf="prospect.coverletterUrl?.length">
      Coverletter
    </a>
    <a class="button orange outline" [href]="prospect.transcriptUrl" (click)="openDocument(prospect.transcriptUrl, $event)" target="_blank" *ngIf="prospect.transcriptUrl?.length">
      Transcript
    </a>
  </div>
</div>

<app-progress-bar *ngIf="working" [value]="60"></app-progress-bar>

<app-modal id="{{acceptId}}">
  <h1>
    You are marking this prospect as accepted for opportunity {{ prospect.opportunity.title }}.
  </h1>
  <div class="flex-rows">
    <button type="button" (click)="acceptProspect(acceptId, $event)"
      class="button dark-green text mb-small">
      Yes
    </button>
    <button type="button" (click)="closeModal(acceptId, $event)" class="button btn-gray text">
      No
    </button>
  </div>
</app-modal>
<app-modal id="{{rejectId}}">
  <h1>
    You are marking this prospect as declined for {{ prospect.opportunity.title }}.
  </h1>
  <div class="flex-rows">
    <button type="button" (click)="rejectProspect(rejectId, $event)"
      class="button orange outline text mb-small">
      Yes
    </button>
    <button type="button" (click)="closeModal(rejectId, $event)" class="button btn-gray text">
      No
    </button>
  </div>
</app-modal>